/* @skip-file-for-translation */
import { t } from '@lingui/core/macro';
import { GQWatershedPlan, GQWatershedPlanLegacy } from '../generated/graphql';
import assertNever from './assertNever';

export const WATERSHED_PLAN_ORDER: ReadonlyArray<GQWatershedPlan> = [
  GQWatershedPlan.Premium,
  GQWatershedPlan.Enterprise,
  GQWatershedPlan.Standard,
  GQWatershedPlan.Essential,
];

export const WATERSHED_PLAN_LEGACY_ORDER: ReadonlyArray<GQWatershedPlanLegacy> =
  [
    GQWatershedPlanLegacy.NetZero,
    GQWatershedPlanLegacy.Pro,
    GQWatershedPlanLegacy.Standard,
    GQWatershedPlanLegacy.Lite,
    GQWatershedPlanLegacy.NoPlan,
  ];

export function tryToMapNewPlanToLegacyPlan(
  watershedPlan: GQWatershedPlan
): GQWatershedPlanLegacy | null {
  switch (watershedPlan) {
    case GQWatershedPlan.Essential:
      return GQWatershedPlanLegacy.Lite;
    case GQWatershedPlan.Standard:
      return GQWatershedPlanLegacy.Standard;
    case GQWatershedPlan.Enterprise:
      return GQWatershedPlanLegacy.Pro;
    case GQWatershedPlan.Premium:
      return GQWatershedPlanLegacy.NetZero;
    default:
      return null;
  }
}

export function isWatershedPlanAtLeastWithFallback({
  watershedPlan,
  watershedPlanAtLeast,
  watershedPlanLegacy,
  watershedPlanLegacyAtLeast,
}: {
  watershedPlan: GQWatershedPlan;
  watershedPlanAtLeast: GQWatershedPlan;
  /**
   * In the case of `Custom` and `Other` Watershed Plan, optional fallback to a
   * legacy plan for gating.
   */
  watershedPlanLegacy: GQWatershedPlanLegacy;
  watershedPlanLegacyAtLeast?: GQWatershedPlanLegacy;
}): { hasAtLeast: boolean; usedFallback: boolean } {
  if (
    watershedPlanLegacyAtLeast &&
    (
      [
        GQWatershedPlan.Custom,
        GQWatershedPlan.Other,
        GQWatershedPlan.None,
        GQWatershedPlan.Finance,
      ] as ReadonlyArray<GQWatershedPlan>
    ).includes(watershedPlan)
  ) {
    if (
      isWatershedPlanAtLeast(
        watershedPlanLegacy,
        watershedPlanLegacyAtLeast,
        WATERSHED_PLAN_LEGACY_ORDER
      )
    ) {
      return { hasAtLeast: true, usedFallback: true };
    }
    return { hasAtLeast: false, usedFallback: true };
  }
  if (
    isWatershedPlanAtLeast(
      watershedPlan,
      watershedPlanAtLeast,
      WATERSHED_PLAN_ORDER
    )
  ) {
    return { hasAtLeast: true, usedFallback: false };
  }
  return { hasAtLeast: false, usedFallback: false };
}

function isWatershedPlanAtLeast<
  T extends GQWatershedPlan | GQWatershedPlanLegacy,
>(orgPlan: T, atLeast: T, order: ReadonlyArray<T>): boolean {
  const orgPlanIndex = order.indexOf(orgPlan);
  const atLeastIndex = order.indexOf(atLeast);
  if (orgPlanIndex === -1 || orgPlanIndex > atLeastIndex) {
    return false;
  }
  return true;
}

export function getWatershedPlanLegacyName(
  plan: GQWatershedPlanLegacy
): string {
  switch (plan) {
    case GQWatershedPlanLegacy.Lite:
      return t`Lite`;
    case GQWatershedPlanLegacy.Pro:
      return t`Pro`;
    case GQWatershedPlanLegacy.Standard:
      return t`Standard`;
    case GQWatershedPlanLegacy.NetZero:
      return t`Enterprise`;
    case GQWatershedPlanLegacy.NoPlan:
      return t`No Plan`;
    default:
      assertNever(plan);
  }
}

/**
 * "Super legacy" means N - 2 notion of what "Watershed Plan" means.
 * E.g. "Lite" and "Pro" are the original or "super legacy" plans.
 * And anything else in `GQWatershedPlanLegacy` is "legacy".
 */
export function isSuperLegacyWatershedPlan(
  plan: GQWatershedPlanLegacy
): boolean {
  switch (plan) {
    case GQWatershedPlanLegacy.Lite:
    case GQWatershedPlanLegacy.Pro:
      return true;
    case GQWatershedPlanLegacy.Standard:
    case GQWatershedPlanLegacy.NetZero:
    case GQWatershedPlanLegacy.NoPlan:
      return false;
    default:
      assertNever(plan);
  }
}

export function watershedPlanIncludesReductions({
  watershedPlan,
  watershedPlanLegacy,
  overrideFlag,
}: {
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
  // The PricingFy25ReductionsModuleTemp flag overrides the WS plan to enable
  // reductions. This is a temporary flag until we can get feature overrides
  // from Salesforce.
  overrideFlag: boolean | undefined;
}): boolean {
  if (overrideFlag) {
    return true;
  }
  const { hasAtLeast } = isWatershedPlanAtLeastWithFallback({
    watershedPlan,
    watershedPlanAtLeast: GQWatershedPlan.Enterprise,
    watershedPlanLegacy,
    // Don't check reductions access against the legacy plan
  });
  return hasAtLeast;
}

export function watershedPlanIncludesMeasurement({
  watershedPlan,
  watershedPlanLegacy,
}: {
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
}): boolean {
  const { hasAtLeast } = isWatershedPlanAtLeastWithFallback({
    watershedPlan,
    watershedPlanAtLeast: GQWatershedPlan.Essential,
    watershedPlanLegacy,
    watershedPlanLegacyAtLeast: GQWatershedPlanLegacy.Lite,
  });
  return hasAtLeast;
}

export function watershedPlanIncludesReporting({
  watershedPlan,
  watershedPlanLegacy,
}: {
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
}): boolean {
  const { hasAtLeast } = isWatershedPlanAtLeastWithFallback({
    watershedPlan,
    watershedPlanAtLeast: GQWatershedPlan.Essential,
    watershedPlanLegacy,
    watershedPlanLegacyAtLeast: GQWatershedPlanLegacy.Lite,
  });
  return (
    hasAtLeast ||
    // We add extra plans here to support finance cases
    // https://watershedclimate.slack.com/archives/C030D9KLPLG/p1723650086556769
    watershedPlan === GQWatershedPlan.Custom ||
    watershedPlan === GQWatershedPlan.PortcoPackage ||
    watershedPlan === GQWatershedPlan.Finance
  );
}

export function watershedPlanIncludesTcfdQualitative({
  watershedPlan,
  watershedPlanLegacy,
}: {
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
}): boolean {
  const { hasAtLeast } = isWatershedPlanAtLeastWithFallback({
    watershedPlan,
    watershedPlanAtLeast: GQWatershedPlan.Enterprise,
    watershedPlanLegacy,
    watershedPlanLegacyAtLeast: GQWatershedPlanLegacy.Pro,
  });
  return hasAtLeast;
}
