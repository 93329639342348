import {
  useFinanceSnapshotForStatusQuery,
  useFinanceSnapshotsQuery,
} from '@watershed/shared-frontend/generated/urql';
import { gql } from 'graphql-tag';
import { useContext, createContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { getGqlResultData } from '@watershed/shared-frontend/utils/errorUtils';
import {
  omitQueryParams,
  parseQueryParam,
} from '@watershed/shared-universal/utils/queryParamUtils';
import {
  GQFinanceSnapshotExecutionStatus,
  GQFinanceSnapshotForContextFragment,
} from '@watershed/shared-universal/generated/graphql';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';

const POLLING_INTERVAL_LIVE_MS = 2500; // 2.5s
const POLLING_INTERVAL_QUIET_MS = 1000 * 60 * 5; // 5 minutes

/**
 * SnapshotState reflects the relative state in the browser of the currently
 * selected snapshot, whether current or not.
 *
 * In cases where it may or may not be current, we want to be careful to inform
 * the user of this and allow selection of the current snapshot.
 */
export enum SnapshotState {
  // No snapshot is selected, either due to a loading state or no available snapshot
  None,

  // You are viewing a snapshot that errored. It may or may not be current.
  Error,

  // You are viewing a snapshot that is running. It may or may not be current.
  Processing,

  // You are viewing a complete snapshot without error, but it is not current.
  OldSnapshot,

  // You are viewing the current snapshot, but it has been marked stale.
  Stale,

  // You are viewing the current snapshot, and it is complete without errors
  Stable,
}

// This function enforces the ordering of the above statuses
function getSnapshotState({
  currentSnapshotId,
  financeSnapshot,
}: {
  financeSnapshot?: GQFinanceSnapshotForContextFragment | null;
  currentSnapshotId?: string | null;
}) {
  if (!financeSnapshot) {
    return SnapshotState.None;
  }

  if (
    financeSnapshot.executionStatus === GQFinanceSnapshotExecutionStatus.Error
  ) {
    return SnapshotState.Error;
  }

  if (
    financeSnapshot.executionStatus ===
    GQFinanceSnapshotExecutionStatus.Processing
  ) {
    return SnapshotState.Processing;
  }

  if (financeSnapshot.id !== currentSnapshotId) {
    return SnapshotState.OldSnapshot;
  }

  if (financeSnapshot.isStale) {
    return SnapshotState.Stale;
  }

  return SnapshotState.Stable;
}

export type FinanceSnapshots = {
  snapshotLoading: boolean;
  snapshotErrorMessage?: string | null;
  financeSnapshotId: string | null;
  currentSnapshotId: string | null;
  financeSnapshot?: GQFinanceSnapshotForContextFragment;
  financeSnapshots?: Array<GQFinanceSnapshotForContextFragment>;
  setFinanceSnapshotId: (financeSnapshotId: string) => void;
  clearFinanceSnapshotId: () => void;
  currentSnapshotStatus?: GQFinanceSnapshotForContextFragment | null;
  refreshSnapshots: () => void;
  snapshotState: SnapshotState;
  setPollAggressively: (pollAggressively: boolean) => void;
};

export const FinanceSnapshotsContext = createContext<FinanceSnapshots>({
  snapshotLoading: true,
  financeSnapshotId: null,
  currentSnapshotId: null,
  setFinanceSnapshotId: () => {},
  clearFinanceSnapshotId: () => {},
  refreshSnapshots: () => {},
  snapshotState: SnapshotState.None,
  setPollAggressively: () => {},
});

gql`
  fragment FinanceSnapshotIsStale on FinanceSnapshot {
    id
    isStale
  }

  fragment FinanceSnapshotForContext on FinanceSnapshot {
    id
    snapshotName
    note
    config 
    createdAt
    createdBy {
      id
      name
      displayName
    }
    executionStatus
    executionStatusMessage
    completedAt
    isStale
    flagsPopulated
    financedEmissionsTonnesCurrentYear
    footprintSnapshotId
  }

  query FinanceSnapshots {
    financeSnapshots {
      id
      ...FinanceSnapshotForContext
    }
  }

  query FinanceSnapshotForStatus($id: ID!) {
    financeSnapshot(id: $id) {
      id
      ...FinanceSnapshotForContext
    }
  }
`;

/**
 * useFinanceSnapshots
 *
 * Having first tried to maintain all Finance data models in the browser,
 * we've settled on the idea that all heavy data processing must be done in
 * Temporal, and we're going to be much more meticulous about what gets brought
 * into the browser.
 *
 * The Finance snapshots are really the only truly global thing that remains,
 * where we want to know if the user has selected a historical snapshot or
 * wants to view the current snapshot.
 *
 * In addition, as of November 2023, we are waiting until the user requests a
 * snapshot to run all relevant estimations and populate the snapshot.
 *
 * As a result, the list of snapshots to choose from becomes the last tricky
 * bit we need to manage in the cache.
 */
export function FinanceSnapshotsContextProvider({
  children,
}: React.PropsWithChildren) {
  const router = useRouter();

  /**
   * SNAPSHOT LIST
   *
   * This is the query that retrieves the current list of available snapshots.
   *
   *   * "Current" snapshot is the most recently created snapshot
   *   * "Selected" snapshot is the snapshot id in the url
   *   * The "final" snapshot id to use in context is the selected snapshot
   *     or the current snapshot if available and complete
   *
   * This distinction is relevant because we want to track the status of a
   * currently-refreshing snapshot even if the user has selected a previous
   * snapshot to view in the UI
   */
  const [snapshotsState, refreshSnapshots] = useFinanceSnapshotsQuery();
  const { financeSnapshots } = getGqlResultData(snapshotsState) || {};
  const snapshotLoading = isFetchingOrStale(snapshotsState);
  const currentSnapshot = financeSnapshots?.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
  )?.[0];
  const currentSnapshotId = currentSnapshot?.id ?? null;
  const selectedSnapshotId =
    parseQueryParam(router.query.financeSnapshotId) ?? null;
  const financeSnapshotId = selectedSnapshotId ?? currentSnapshotId ?? null;
  const financeSnapshot = financeSnapshots?.find(
    ({ id }) => id === financeSnapshotId
  );

  /**
   * SNAPSHOT STATUS
   *
   * This is the query that checks the status fields for the current snapshot.
   *
   * The work for calculating this status is done on the server so we only have
   * to poll for status in a single place.
   *
   * We have this separated from the list query because we don't want to have to
   * query for all those fields on all snapshots every time we poll for status.
   */
  const [statusState, refreshStatusFn] = useFinanceSnapshotForStatusQuery({
    variables: { id: financeSnapshot?.id ?? '' },
    pause: !financeSnapshot,
  });

  /**
   * We don't use getGqlResultData here because the query is on a long-running polling
   * sort of situation, so it's not uncommon for various internet/network/operational
   * errors to take place, and we were periodically seeing "Something went wrong"
   * messages on idle sessions.
   */
  const currentSnapshotStatus = statusState?.data?.financeSnapshot;
  const snapshotState = getSnapshotState({
    currentSnapshotId,
    financeSnapshot: currentSnapshotStatus,
  });

  /**
   * Updates the snapshot you are currently viewing. If you want to clear the value,
   * use clearFinanceSnapshotId instead.
   */
  const setFinanceSnapshotId = (financeSnapshotId: string) => {
    void router.push({
      pathname: router.pathname,
      query: { ...router.query, financeSnapshotId },
    });
  };

  /**
   * Refreshes the snapshot list, clears the currently selected snapshot. Handles
   * things like deleted snapshots gracefully.
   *
   * TODO(drhurd): this approach does not clear currentSnapshotStatus, which leads to problems in
   * cases like deleting the only snapshot for an org. This is still a strict improvement over the
   * old bug, which crashed whenever you deleted a selected snapshot. We should eventually switch
   * to useRef.
   */
  const clearFinanceSnapshotId = () => {
    void router.push({
      pathname: router.pathname,
      query: omitQueryParams(router.query, ['financeSnapshotId']),
    });
    refreshSnapshots();
  };

  /**
   * These state/effects attempt to keep an eye out for updates, and if we know
   * an update is happening, poll more frequently. The mechanism is a bit
   * meticulous, but it works.
   */
  const [pollAggressively, setPollAggressively] = useState(false);
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (
      snapshotState === SnapshotState.Processing ||
      !currentSnapshotStatus?.flagsPopulated
    ) {
      if (!pollAggressively) {
        setPollAggressively(true);
      }
    } else {
      if (pollAggressively) {
        setPollAggressively(false);
      }
    }
  }, [snapshotState, pollAggressively, currentSnapshotStatus]);
  useEffect(() => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
    }
    pollingInterval.current = setInterval(
      () => refreshStatusFn({ requestPolicy: 'cache-and-network' }),
      pollAggressively ? POLLING_INTERVAL_LIVE_MS : POLLING_INTERVAL_QUIET_MS
    );

    // Stop polling on unmount
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, [pollAggressively, refreshStatusFn]);

  const value: FinanceSnapshots = {
    snapshotLoading,
    financeSnapshotId,
    currentSnapshotId,
    financeSnapshot,
    financeSnapshots,
    setFinanceSnapshotId,
    clearFinanceSnapshotId,
    currentSnapshotStatus,
    refreshSnapshots,
    snapshotState,
    setPollAggressively,
  };

  return (
    <FinanceSnapshotsContext.Provider value={value}>
      {children}
    </FinanceSnapshotsContext.Provider>
  );
}

export const useFinanceSnapshotsContext = () => {
  return useContext(FinanceSnapshotsContext);
};
