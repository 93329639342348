import {
  GQCompanyEmissionsInterfaceV2,
  GQCompanyEmissionsUnits,
  GQEmissionsYearScenario,
  GQEmissionsYearSource,
  GQPrivateDisclosureType,
} from '../generated/graphql';
import { optionalNativeCurrencyToUsd } from '../fund/conversion';
import assertNever from '../utils/assertNever';
import { getEmissionsSourceDescriptor } from '../finance/presenters/descriptionUtils';
import isNotNullish from '../utils/isNotNullish';
import {
  EmissionsYearFieldsTemp,
  FootprintEstimateOutputFieldsTemp,
  PrivateDisclosureFieldsTemp,
  PublicDisclosureFieldsTemp,
} from '../finance/types/FinanceFragments';
import mapStrToEnum from '../utils/mapStrToEnum';
import { CompanyEstimateJobInput } from '../types';
import { PartialPick } from '../utils/utilTypes';

// Please don't use these. Use ScopeKeysType. They're better.
// And if you use them, you'll be better. Your users will be better.
// The environment will be better. Christian wants you to use ScopeKeysType.
export const LEGACY_SCOPE_KEYS = [
  'scope1',
  'scope2',
  'scope3',
  'scope301',
  'scope302',
  'scope303',
  'scope304',
  'scope305',
  'scope306',
  'scope307',
  'scope308',
  'scope309',
  'scope310',
  'scope311',
  'scope312',
  'scope313',
  'scope314',
  'scope315',
  'scope316',
  'scope317',
] as const;
export type LegacyScopeKeysType = (typeof LEGACY_SCOPE_KEYS)[number];

export type ScopeKeysType = Omit<
  GQCompanyEmissionsInterfaceV2,
  'units' | 'revenueCurrency'
>;

export type EmissionsInterfaceNoUnits = Omit<
  GQCompanyEmissionsInterfaceV2,
  'units'
>;

export type EmissionsInterfaceScope2Only = Pick<
  EmissionsInterfaceNoUnits,
  'scope2Location' | 'scope2Market'
> &
  PartialPick<
    EmissionsInterfaceNoUnits,
    'scope2PurchasedCoolingWater' | 'scope2PurchasedHeatSteam' | 'scope2Unknown'
  >;

export type EmissionsInterfaceScope3Only = Omit<
  EmissionsInterfaceNoUnits,
  | 'scope1'
  | 'scope2Location'
  | 'scope2Market'
  | 'scope2PurchasedCoolingWater'
  | 'scope2PurchasedHeatSteam'
  | 'scope2Unknown'
>;

export const ESTIMATED_SOURCES: Array<GQEmissionsYearSource> = [
  GQEmissionsYearSource.CompanyEstimatationEngine,
  GQEmissionsYearSource.BuildingEstimationEngine,
  GQEmissionsYearSource.Survey,
  GQEmissionsYearSource.SurveyEstimate,
];

export function getHasOverrides({
  emissionsYearFragment,
  footprintEstimateOutputRecords,
}: {
  emissionsYearFragment: Pick<
    EmissionsYearFieldsTemp,
    'footprintEstimateOutputId'
  >;
  footprintEstimateOutputRecords: Array<FootprintEstimateOutputFieldsTemp>;
}): boolean | null {
  if (!emissionsYearFragment.footprintEstimateOutputId) {
    return false;
  }

  const footprintEstimateOutput = footprintEstimateOutputRecords.find(
    (feo) => feo.id === emissionsYearFragment.footprintEstimateOutputId
  );
  if (!footprintEstimateOutput) {
    return false;
  }
  const modelInput =
    footprintEstimateOutput.modelInput as CompanyEstimateJobInput;

  // TODO: Don't we have a mapping of these already somewhere?
  const overrides = [
    modelInput.scope1Kgco2e,
    modelInput.scope2LocationKgco2e,
    modelInput.scope2MarketKgco2e,
    modelInput.scope2UnknownKgco2e,
    modelInput.scope301Kgco2e,
    modelInput.scope302Kgco2e,
    modelInput.scope303Kgco2e,
    modelInput.scope304Kgco2e,
    modelInput.scope305Kgco2e,
    modelInput.scope306Kgco2e,
    modelInput.scope307Kgco2e,
    modelInput.scope308Kgco2e,
    modelInput.scope309Kgco2e,
    modelInput.scope310Kgco2e,
    modelInput.scope311Kgco2e,
    modelInput.scope312Kgco2e,
    modelInput.scope313Kgco2e,
    modelInput.scope314Kgco2e,
    modelInput.scope315Kgco2e,
    modelInput.scope316Kgco2e,
    modelInput.scope317Kgco2e,
    modelInput.scope3Kgco2e,
  ];

  return overrides.some((override) => isNotNullish(override));
}

function getEmissionsYearSource({
  emissionsYearFragment,
  privateDisclosure,
}: {
  emissionsYearFragment: Pick<
    EmissionsYearFieldsTemp,
    | 'buildingEstimateOutputId'
    | 'footprintEstimateOutputId'
    | 'privateDisclosureId'
    | 'publicDisclosureId'
  >;
  privateDisclosure:
    | Pick<PrivateDisclosureFieldsTemp, 'privateDisclosureType'>
    | null
    | undefined;
}): GQEmissionsYearSource {
  if (emissionsYearFragment.publicDisclosureId) {
    return GQEmissionsYearSource.PublicDisclosure;
  }
  if (emissionsYearFragment.buildingEstimateOutputId) {
    return GQEmissionsYearSource.BuildingEstimationEngine;
  }
  if (emissionsYearFragment.privateDisclosureId) {
    if (!privateDisclosure) {
      // we weren't given the private disclosure, so for now we assume the
      // caller intentionally omitted it. this is probably not ideal, but it's
      // safer to leave this way for now.
      return GQEmissionsYearSource.ManualEntry;
    }
    switch (privateDisclosure.privateDisclosureType) {
      case GQPrivateDisclosureType.WatershedMeasurement:
        return GQEmissionsYearSource.WatershedMeasurement;
      case GQPrivateDisclosureType.Survey:
        return GQEmissionsYearSource.Survey;
      case GQPrivateDisclosureType.SurveyEstimate:
        return GQEmissionsYearSource.SurveyEstimate;
      // We shouldn't need this, but for rare cases this prevents an error
      case GQPrivateDisclosureType.Estimate:
        return GQEmissionsYearSource.CompanyEstimatationEngine;
      case GQPrivateDisclosureType.Cdp:
        return GQEmissionsYearSource.PrivateCdp;
      case GQPrivateDisclosureType.ManualEntry:
        return GQEmissionsYearSource.ManualEntry;
      default:
        assertNever(privateDisclosure.privateDisclosureType);
    }
  }
  if (emissionsYearFragment.footprintEstimateOutputId) {
    return GQEmissionsYearSource.CompanyEstimatationEngine;
  }

  throw new Error('Emissions year source not found');
}

/*
    This is the canonical, universal class for an Emissions Year.
    It is a shared concept used by (in alphabetical order):
      - Climate Program
      - Finance
      - Reporting
      - Supply Chain

    The intention is to have a single concept that represents emissions for a
    single year. It might be historical actuals, or it might be forecasts or
    various other scenarios. So we need to be thoughtful about making certain
    we have the right one, but this class should ensure that the calculations
    we apply are consistent across these scenarios and across the teams that
    use them.

    Let's work together to keep this class clean, stable, semantic, and fast!
*/
export default class EmissionsYear {
  public readonly emissionsYearFragment: EmissionsYearFieldsTemp;
  public readonly emissionsYearSource: GQEmissionsYearSource;
  public readonly thirdPartyVerified: boolean | null;
  public readonly hasOverrides: boolean | null;
  public readonly scope1WithoutLulucfTco2e: number | null; // Scope 1 emissions without land use, land use change, and forestry if available
  public readonly pcafScoreScope1: number | null; // PCAF data quality score for Scope 1 emissions, if available
  public readonly emissionsSourceDescriptor: string;

  constructor({
    emissionsYearFragment,
    hasOverrides = null,
    scope1WithoutLulucfTco2e = null,
    pcafScoreScope1 = null,
    publicDisclosure = null,
    privateDisclosure = null,
  }: {
    emissionsYearFragment: EmissionsYearFieldsTemp;
    hasOverrides?: boolean | null;
    scope1WithoutLulucfTco2e?: number | null;
    pcafScoreScope1?: number | null;
    /**
     * Used to determine the emissions source descriptor
     */
    publicDisclosure?: PublicDisclosureFieldsTemp | null;
    /**
     * Used to determine emissions source and third-party verification, for
     * private disclosure emissions years
     */
    privateDisclosure?:
      | (Pick<PrivateDisclosureFieldsTemp, 'privateDisclosureType'> &
          PartialPick<PrivateDisclosureFieldsTemp, 'thirdPartyVerified'>)
      | null;
  }) {
    this.emissionsYearFragment = emissionsYearFragment;
    this.hasOverrides = hasOverrides;
    this.scope1WithoutLulucfTco2e = scope1WithoutLulucfTco2e;
    this.pcafScoreScope1 = pcafScoreScope1;

    this.emissionsYearSource = getEmissionsYearSource({
      emissionsYearFragment,
      privateDisclosure,
    });
    this.thirdPartyVerified = privateDisclosure?.thirdPartyVerified ?? null;
    this.emissionsSourceDescriptor = getEmissionsSourceDescriptor({
      emissionsYearSource: this.emissionsYearSource,
      reportingYear: emissionsYearFragment.reportingYear,
      publicDisclosure,
      hasOverrides: hasOverrides ?? false,
    });
  }

  ////////////////////////////////////////////////////////////////////////////////
  // First, all the silly getters (don't we have a way to simply spread these?)
  ////////////////////////////////////////////////////////////////////////////////

  get id(): string {
    return this.emissionsYearFragment.id;
  }
  get createdAt(): Date {
    return this.emissionsYearFragment.createdAt;
  }
  get publicDisclosureId(): string | null {
    return this.emissionsYearFragment.publicDisclosureId;
  }
  get privateDisclosureId(): string | null {
    return this.emissionsYearFragment.privateDisclosureId;
  }
  get footprintEstimateOutputId(): string | null {
    return this.emissionsYearFragment.footprintEstimateOutputId;
  }
  get buildingEstimateOutputId(): string | null {
    return this.emissionsYearFragment.buildingEstimateOutputId;
  }
  get assetCorporateId(): string | null {
    return this.emissionsYearFragment.assetCorporateId;
  }
  get reportingYear(): number {
    return this.emissionsYearFragment.reportingYear;
  }
  get numEmployees(): number | null {
    return this.emissionsYearFragment.numEmployees;
  }

  // Note: This is stored in the currency specified, not USD.
  // revenueUsd is calculated
  get revenue(): number | null {
    return this.emissionsYearFragment.revenue;
  }
  get revenueCurrency(): string | null {
    return this.emissionsYearFragment.revenueCurrency;
  }
  get currencyConversionRate(): number | null {
    return this.emissionsYearFragment.currencyConversionRate;
  }
  get units(): GQCompanyEmissionsUnits {
    return this.emissionsYearFragment.units;
  }
  get percentageCleanEnergy(): number | null {
    return this.emissionsYearFragment.percentageCleanEnergy;
  }
  get totalMwh(): number | null {
    return this.emissionsYearFragment.totalMwh;
  }
  get orgId(): string | null {
    return this.emissionsYearFragment.orgId;
  }
  get scenario(): GQEmissionsYearScenario | null {
    return this.emissionsYearFragment.scenario
      ? mapStrToEnum(
          this.emissionsYearFragment.scenario,
          GQEmissionsYearScenario
        )
      : null;
  }
  get updatedAt(): Date {
    return this.emissionsYearFragment.updatedAt;
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Instance methods (some just wrap static methods)
  ////////////////////////////////////////////////////////////////////////////////

  // Because Emissions Years can be stored as kgco2e or kgco2ePerDollar, it is
  // *imperative* that we use this function as an accessor for the emissions data
  getEmissionsByScopeKgco2e(emissionsKey: keyof ScopeKeysType): number | null {
    return EmissionsYear.getEmissionsFromEmissionsYearIfKgco2e(
      this.emissionsYearFragment,
      emissionsKey
    );
  }

  // Note: All of these unlabled scopes are all kg, not tonnes

  get scope1(): number | null {
    return this.getEmissionsByScopeKgco2e('scope1');
  }
  get scope2(): number | null {
    return this.getEmissionsByScopeKgco2e('scope2');
  }
  get scope2Location(): number | null {
    return this.getEmissionsByScopeKgco2e('scope2Location');
  }
  get scope2Market(): number | null {
    return this.getEmissionsByScopeKgco2e('scope2Market');
  }
  get scope2PurchasedCoolingWater(): number | null {
    return this.getEmissionsByScopeKgco2e('scope2PurchasedCoolingWater');
  }
  get scope2PurchasedHeatSteam(): number | null {
    return this.getEmissionsByScopeKgco2e('scope2PurchasedHeatSteam');
  }
  get scope2Unknown(): number | null {
    return this.getEmissionsByScopeKgco2e('scope2Unknown');
  }
  get scope3(): number | null {
    return this.getEmissionsByScopeKgco2e('scope3');
  }
  get scope301(): number | null {
    return this.getEmissionsByScopeKgco2e('scope301');
  }
  get scope302(): number | null {
    return this.getEmissionsByScopeKgco2e('scope302');
  }
  get scope303(): number | null {
    return this.getEmissionsByScopeKgco2e('scope303');
  }
  get scope304(): number | null {
    return this.getEmissionsByScopeKgco2e('scope304');
  }
  get scope305(): number | null {
    return this.getEmissionsByScopeKgco2e('scope305');
  }
  get scope306(): number | null {
    return this.getEmissionsByScopeKgco2e('scope306');
  }
  get scope307(): number | null {
    return this.getEmissionsByScopeKgco2e('scope307');
  }
  get scope308(): number | null {
    return this.getEmissionsByScopeKgco2e('scope308');
  }
  get scope309(): number | null {
    return this.getEmissionsByScopeKgco2e('scope309');
  }
  get scope310(): number | null {
    return this.getEmissionsByScopeKgco2e('scope310');
  }
  get scope311(): number | null {
    return this.getEmissionsByScopeKgco2e('scope311');
  }
  get scope312(): number | null {
    return this.getEmissionsByScopeKgco2e('scope312');
  }
  get scope313(): number | null {
    return this.getEmissionsByScopeKgco2e('scope313');
  }
  get scope314(): number | null {
    return this.getEmissionsByScopeKgco2e('scope314');
  }
  get scope315(): number | null {
    return this.getEmissionsByScopeKgco2e('scope315');
  }
  get scope316(): number | null {
    return this.getEmissionsByScopeKgco2e('scope316');
  }
  get scope317(): number | null {
    return this.getEmissionsByScopeKgco2e('scope317');
  }

  get totalKgco2e(): number | null {
    return (this.scope1 ?? 0) + (this.scope2 ?? 0) + (this.scope3 ?? 0);
  }

  get revenueUsd(): number | null {
    return EmissionsYear.getRevenueUsd(this);
  }

  get expenseCategory(): string | null {
    return this.emissionsYearFragment.expenseCategory;
  }

  scope123RevenueIntensityKgco2e(): {
    id: string;
    scope1: number | null;
    scope2Location: number | null;
    scope2Market: number | null;
    scope3: number | null;
  } {
    function calculateRevenueIntensity(
      emissionsKgco2e: number | null,
      revenueUsd: number | null
    ) {
      return emissionsKgco2e !== null && !!revenueUsd
        ? emissionsKgco2e / revenueUsd
        : null;
    }

    const id = `${this.id}-scope123RevenueIntensityKgco2e`;

    switch (this.units) {
      case 'kgco2ePerDollar':
      case null:
        return {
          id,
          scope1: null,
          scope2Location: null,
          scope2Market: null,
          scope3: null,
        };
      case 'kgco2e':
        return {
          id,
          scope1: calculateRevenueIntensity(this.scope1, this.revenueUsd),
          scope2Location: calculateRevenueIntensity(
            this.scope2Location,
            this.revenueUsd
          ),
          scope2Market: calculateRevenueIntensity(
            this.scope2Market,
            this.revenueUsd
          ),
          scope3: calculateRevenueIntensity(this.scope3, this.revenueUsd),
        };
      case 'allocatedKgco2e':
        return {
          id,
          scope1: null,
          scope2Location: null,
          scope2Market: null,
          scope3: null,
        };
      default:
        assertNever(this.units);
    }
  }

  get isEstimate(): boolean {
    return ESTIMATED_SOURCES.includes(this.emissionsYearSource);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Static methods
  // (we want these all consolidated here, but you probably want an instance method, right?)
  ////////////////////////////////////////////////////////////////////////////////

  // We have this function as a static so that it's available
  // for other use cases, but please don't calculate EmissionsYear
  // stuff outside of EmissionsYear!
  static getEmissionsFromEmissionsYearIfKgco2e(
    emissionsYear: EmissionsYearFieldsTemp,
    emissionsKey: keyof ScopeKeysType
  ): number | null {
    switch (emissionsYear.units) {
      case 'kgco2e':
      case 'kgco2ePerDollar':
      case 'allocatedKgco2e':
        return EmissionsYear.getEmissionsForScope(emissionsYear, emissionsKey);
      case null:
        return null;
      default:
        assertNever(emissionsYear.units);
    }
  }

  static hasEmissionsForScope(
    emissions: Partial<EmissionsInterfaceNoUnits>,
    scope: keyof ScopeKeysType
  ): boolean {
    const emissionsForScope = EmissionsYear.getEmissionsForScope(
      emissions,
      scope
    );
    return emissionsForScope !== null;
  }

  static hasScope1Emissions(
    emission: Partial<EmissionsInterfaceNoUnits>
  ): boolean {
    return EmissionsYear.hasEmissionsForScope(emission, 'scope1');
  }

  static hasScope2Emissions(
    emission: Partial<EmissionsInterfaceScope2Only>
  ): boolean {
    return (
      isNotNullish(emission.scope2Market) ||
      isNotNullish(emission.scope2Location) ||
      isNotNullish(emission.scope2PurchasedHeatSteam) ||
      isNotNullish(emission.scope2PurchasedCoolingWater) ||
      isNotNullish(emission.scope2Unknown)
    );
  }

  static getScope1Emissions(
    emission: Partial<EmissionsInterfaceNoUnits>
  ): number | null {
    return EmissionsYear.getEmissionsForScope(emission, 'scope1');
  }

  static getScope2Emissions(
    emission: Partial<EmissionsInterfaceNoUnits>
  ): number | null {
    if (!EmissionsYear.hasScope2Emissions(emission)) {
      return null;
    }

    // If Scope 2 market is non-null, return it, otherwise fall back to location.
    // This includes zero values if supplied. If the number got written to the db,
    // it should have passed a quality control mechanism.
    if (isNotNullish(emission.scope2Market)) {
      return emission.scope2Market;
    }
    if (isNotNullish(emission.scope2Location)) {
      return emission.scope2Location;
    }

    // These are fields we collect specifically for Meta NZSEP (as of Dec 2022).
    // If they are present, return their sum.
    return (
      (emission.scope2PurchasedHeatSteam ?? 0) +
      (emission.scope2PurchasedCoolingWater ?? 0) +
      (emission.scope2Unknown ?? 0)
    );
  }

  static getScope2EmissionsDetails(emission: EmissionsInterfaceScope2Only): {
    value: number | null;
    source: 'Market' | 'Location' | 'Other' | null;
  } {
    if (!EmissionsYear.hasScope2Emissions(emission)) {
      return {
        value: null,
        source: null,
      };
    }

    // If Scope 2 market is non-null, return it, otherwise fall back to location.
    if (emission.scope2Market !== null) {
      return {
        value: emission.scope2Market,
        source: 'Market',
      };
    }
    if (emission.scope2Location !== null) {
      return {
        value: emission.scope2Location,
        source: 'Location',
      };
    }

    // These are fields we collect specifically for Meta NZSEP (as of Dec 2022).
    // If they are present, return their sum.
    return {
      value:
        (emission.scope2PurchasedHeatSteam ?? 0) +
        (emission.scope2PurchasedCoolingWater ?? 0) +
        (emission.scope2Unknown ?? 0),
      source: 'Other',
    };
  }

  static hasUpstreamScope3CategoryEmissions(
    emission: Partial<EmissionsInterfaceScope3Only>
  ): boolean {
    return (
      isNotNullish(emission.scope301) ||
      isNotNullish(emission.scope302) ||
      isNotNullish(emission.scope303) ||
      isNotNullish(emission.scope304) ||
      isNotNullish(emission.scope305) ||
      isNotNullish(emission.scope306) ||
      isNotNullish(emission.scope307) ||
      isNotNullish(emission.scope308) ||
      isNotNullish(emission.scope316)
    );
  }

  static hasScope3CategoryEmissions(
    emission: Partial<EmissionsInterfaceScope3Only>
  ): boolean {
    return (
      EmissionsYear.hasUpstreamScope3CategoryEmissions(emission) ||
      isNotNullish(emission.scope309) ||
      isNotNullish(emission.scope310) ||
      isNotNullish(emission.scope311) ||
      isNotNullish(emission.scope312) ||
      isNotNullish(emission.scope313) ||
      isNotNullish(emission.scope314) ||
      isNotNullish(emission.scope315) ||
      isNotNullish(emission.scope316) ||
      isNotNullish(emission.scope317)
    );
  }

  static hasScope3Emissions(
    emission: Partial<EmissionsInterfaceScope3Only>
  ): boolean {
    return (
      isNotNullish(emission.scope3) ||
      EmissionsYear.hasScope3CategoryEmissions(emission)
    );
  }

  // The logic here is that if a scope3 number is present, we use it.
  // Otherwise we sum all of the above.
  // TODO: Does this make sense, though?
  static getScope3Emissions(
    emission: Partial<EmissionsInterfaceScope3Only>
  ): number | null {
    if (!EmissionsYear.hasScope3Emissions(emission)) {
      return null;
    }

    if (isNotNullish(emission.scope3)) {
      return emission.scope3;
    }

    return (
      (emission.scope301 ?? 0) +
      (emission.scope302 ?? 0) +
      (emission.scope303 ?? 0) +
      (emission.scope304 ?? 0) +
      (emission.scope305 ?? 0) +
      (emission.scope306 ?? 0) +
      (emission.scope307 ?? 0) +
      (emission.scope308 ?? 0) +
      (emission.scope309 ?? 0) +
      (emission.scope310 ?? 0) +
      (emission.scope311 ?? 0) +
      (emission.scope312 ?? 0) +
      (emission.scope313 ?? 0) +
      (emission.scope314 ?? 0) +
      (emission.scope315 ?? 0) +
      (emission.scope316 ?? 0) +
      (emission.scope317 ?? 0)
    );
  }

  static getEmissionsScopeField(
    emissions: Partial<EmissionsInterfaceNoUnits>,
    scope: keyof ScopeKeysType
  ): number | null {
    return emissions[scope] ?? null;
  }

  /*
      I know this may seem a bit excessive, but it allows us to not have logic everywhere.
      This is the single place where we talk about how to get this scope-level info and
      how to aggregate it safely.
  */
  static getEmissionsForScope(
    emissions: Partial<EmissionsInterfaceNoUnits>,
    scope: keyof ScopeKeysType
  ): number | null {
    switch (scope) {
      case 'scope2':
        return EmissionsYear.getScope2Emissions(emissions);
      case 'scope3':
        return EmissionsYear.getScope3Emissions(emissions);
      default:
        return EmissionsYear.getEmissionsScopeField(emissions, scope);
    }
  }

  static hasScope123Emissions(
    emission: Partial<EmissionsInterfaceNoUnits>
  ): boolean {
    return (
      EmissionsYear.hasEmissionsForScope(emission, 'scope1') &&
      EmissionsYear.hasScope2Emissions(emission) &&
      EmissionsYear.hasScope3Emissions(emission)
    );
  }

  static getUpstreamScope3Emissions(
    emission: Partial<EmissionsInterfaceScope3Only>
  ): number | null {
    if (!EmissionsYear.hasUpstreamScope3CategoryEmissions(emission)) {
      return null;
    }

    return (
      (emission.scope301 ?? 0) +
      (emission.scope302 ?? 0) +
      (emission.scope303 ?? 0) +
      (emission.scope304 ?? 0) +
      (emission.scope305 ?? 0) +
      (emission.scope306 ?? 0) +
      (emission.scope307 ?? 0) +
      (emission.scope308 ?? 0) +
      (emission.scope316 ?? 0)
    );
  }

  static getTotalEmissionsForEf(emission: GQCompanyEmissionsInterfaceV2): {
    amount: number;
    units: GQCompanyEmissionsUnits;
  } {
    return {
      amount:
        (EmissionsYear.getScope1Emissions(emission) ?? 0) +
        (EmissionsYear.getScope2Emissions(emission) ?? 0) +
        (EmissionsYear.getUpstreamScope3Emissions(emission) ??
          EmissionsYear.getScope3Emissions(emission) ??
          0),
      units: emission.units,
    };
  }

  static getRevenueUsd({
    revenue,
    currencyConversionRate,
  }: {
    revenue: EmissionsYear['revenue'];
    currencyConversionRate: EmissionsYear['currencyConversionRate'];
  }): number | null {
    return optionalNativeCurrencyToUsd({
      nativeCurrencyAmount: revenue,
      currencyConversionRate,
    });
  }
}
