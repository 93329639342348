import { Box, Chip, Checkbox } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import { GQFlags } from '@watershed/shared-universal/generated/graphql';
import { useFeatureFlagContext } from '../utils/FeatureFlag';
import { Dialog } from '@watershed/ui-core/components/Dialog';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';
import useLocale from '@watershed/intl/frontend/useLocale';

export default function FeatureFlagOverrideDialog({
  onClose,
}: {
  onClose: () => void;
}) {
  const { flags, flagOverrides, setOverride, originalFlags } =
    useFeatureFlagContext();

  // Overrides get cleared out from flagOverrides once they're returned
  // to the real flag state. But it's still possible you'd end up with
  // an "override" that is the same as the actual flag value, if the real
  // flag value changes after an override is applied, and the page reloads.
  // In that case the override has no effect.
  const getUsingRealFlagValue = (flag: GQFlags) => {
    return (
      !flagOverrides.has(flag) ||
      !!originalFlags.get(flag) === flagOverrides.get(flag)
    );
  };
  const locale = useLocale();

  const sortedFlags = Object.values(GQFlags).sort((flagA, flagB) => {
    // sort usingRealFlagValue, then by flag name
    const usingRealFlagValueA = getUsingRealFlagValue(flagA);
    const usingRealFlagValueB = getUsingRealFlagValue(flagB);
    return usingRealFlagValueA === usingRealFlagValueB
      ? flagA.localeCompare(flagB, locale)
      : usingRealFlagValueA
        ? 1
        : -1;
  });

  return (
    <Dialog
      onClose={onClose}
      header={{
        title: <Trans>Feature flag overrides</Trans>,
        subtitle: (
          <Box>
            <Trans>
              Override a feature flag for to preview how a new feature would
              appear to this org. Overrides expire at the end of your browser
              session.
            </Trans>
            <ErrorBox level="warning" variant="text" sx={{ marginTop: 2 }}>
              <Trans context="feature flags means we can turn a feature on or off">
                This only affects client-side flag checks!
              </Trans>
            </ErrorBox>
          </Box>
        ),
      }}
    >
      <div>
        {sortedFlags.map((flag) => {
          const usingRealFlagValue = getUsingRealFlagValue(flag);
          return (
            <Box key={flag}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={!!flags.get(flag)}
                  onChange={(_event, checked) => {
                    setOverride(flag, checked);
                  }}
                />
                {flag}
                {!usingRealFlagValue && (
                  <Chip
                    sx={{ marginLeft: 1 }}
                    label={
                      <Trans context="Override a feature flag">Override</Trans>
                    }
                    color="error"
                  />
                )}
              </label>
            </Box>
          );
        })}
      </div>
    </Dialog>
  );
}
