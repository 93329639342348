import {
  INDUSTRY_CODE_LIST,
  getIndustryFromIndustryCode,
  getIndustryLabelFromIndustryCode,
  getMultiBeaCodeFromIndustryCode,
  getTaxonomyFromIndustryCode,
  safelyGetIndustryLabelFromIndustryCode,
} from '@watershed/shared-universal/industryCodes/industryCodeUtils';
import { Trans } from '@lingui/react/macro';

import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  AutocompleteField,
  AutocompleteFieldNonFormik,
  AutocompleteFieldProps,
} from './AutocompleteField';
import {
  AutocompleteValue,
  Box,
  Popper,
  PopperProps,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import groupBy from 'lodash/groupBy';
import { GICS_CODES } from '@watershed/shared-universal/industryCodes/gics';
import {
  GICS_SECTORS,
  NAICS_SECTORS,
  GICS_INDUSTRY_GROUPS,
} from '@watershed/shared-universal/industryCodes/otherCodes';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import InfoIcon from '@watershed/icons/components/Info';
import IconButton from '../IconButton';
import { Dialog } from '../Dialog';
import { useDialog } from '../../hooks/useDialog';
import sortBy from 'lodash/sortBy';
import TextLink from '../TextLink';
import { routeForLearningHub } from '@watershed/shared-universal/dashboardRoutes';
import ArrowRightIcon from '@watershed/icons/components/ArrowRight';
import React from 'react';
import { formatPercentageNonzero } from '@watershed/shared-universal/utils/helpers';
import useLocale from '@watershed/intl/frontend/useLocale';

export function GrowingPopper(props: PopperProps) {
  return (
    <Popper
      sx={(theme) => ({
        '& .MuiAutocomplete-groupLabel': {
          backgroundColor: theme.palette.grey10,
          color: theme.palette.grey70,
        },
        width: 'fit-content !important',
        maxWidth: '600px',
      })}
      placement="top-start"
      {...props}
    />
  );
}

export type IndustryCodeAutocompleteExtraProps = {
  hideCode?: boolean;
  includeTaxonomy?: boolean;

  options?: Array<string>;
  grouped?: boolean;
};

/**
 * For searching, we want to include some extra stuff so folks can type in
 * sector or industry group.
 */
export function stringifyCodeFactory(options?: { includeTaxonomy: boolean }) {
  return (code: string): string => {
    const isGics = code in GICS_CODES;
    const firstTwo = code.substring(0, 2);

    return [
      options?.includeTaxonomy ? getTaxonomyFromIndustryCode(code) : null,
      getIndustryFromIndustryCode(code),
      code,
      isGics ? GICS_SECTORS[firstTwo] : NAICS_SECTORS[firstTwo],
      isGics ? GICS_INDUSTRY_GROUPS[code.substring(0, 4)] : null,
    ]
      .filter(isNotNullish)
      .join(' ');
  };
}

/**
 * Group by sector for either GICS or NAICS codes.
 */
export function groupFunction(option: string): string {
  const firstTwo = option.substring(0, 2);
  const sector =
    option in GICS_CODES ? GICS_SECTORS[firstTwo] : NAICS_SECTORS[firstTwo];
  return sector ?? 'Other';
}

function FormattedIndustryCode({
  code,
  sx,
}: {
  code: string;
  sx?: SxProps<Theme>;
}) {
  return (
    <Typography sx={sx}>
      {getTaxonomyFromIndustryCode(code)} {/* TODO: Figure this out */}
      <span style={{ fontVariantNumeric: 'tabular-nums' }}>{code}</span> –{' '}
      {getIndustryLabelFromIndustryCode(code, { includeCode: false })}
    </Typography>
  );
}

function BeaWeightingExplainerDialog({
  code,
  onClose,
}: {
  code: string;
  onClose: () => void;
}) {
  const locale = useLocale();
  const weightedBeaCodes = sortBy(
    Object.entries(getMultiBeaCodeFromIndustryCode(code)),
    (r) => -r[1]
  );

  return (
    <Dialog
      onClose={onClose}
      header={{ title: 'Industry code conversion to BEA' }}
      maxWidth="md"
      sx={{ zIndex: 1301 }} // larger than MUI default of 1300, to ensure it's above the popover
    >
      <Stack gap={4} maxHeight="600px" overflow="auto">
        <Typography sx={{ maxWidth: 600 }}>
          <Trans>
            Watershed converts all industry codes to BEA format to ensure
            consistent and reliable estimations.
          </Trans>{' '}
          <TextLink
            href={routeForLearningHub({
              article: 'CompanyEstimationEngineIndustryCodes',
              from: 'FinanceIndustryAutocomplete',
            })}
          >
            <Trans context="Link copy">Learn more about industry codes.</Trans>
          </TextLink>
        </Typography>
        <Stack direction="row" gap={3.5} flexShrink={0}>
          <FormattedIndustryCode code={code} />
          <Box display="grid" width={24} height={24}>
            <ArrowRightIcon sx={{ placeSelf: 'center' }} />
          </Box>
          {weightedBeaCodes.length > 1 ? (
            <Stack
              display="grid"
              gridTemplateColumns="max-content 1fr"
              gap={1.5}
            >
              {weightedBeaCodes.map(([beaCode, weight]) => (
                <React.Fragment key={beaCode}>
                  <Typography
                    sx={{ fontVariantNumeric: 'tabular-nums' }}
                    variant="body2"
                  >
                    {formatPercentageNonzero(weight, {
                      maximumFractionDigits: 2,
                      includeTrailingZeros: true,
                      locale,
                    })}
                  </Typography>
                  <FormattedIndustryCode code={beaCode} />
                </React.Fragment>
              ))}
            </Stack>
          ) : (
            <FormattedIndustryCode code={weightedBeaCodes[0][0]} />
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default function IndustryCodeAutocompleteNonFormik<
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
>({
  value,
  onChange,
  required,
  hideRequired,
  label = 'Industry code',
  placeholder = 'Select an industry code',
  options = INDUSTRY_CODE_LIST,
  hideCode = false,
  includeTaxonomy = false,
  inline,
  sx,
  disabled,
  grouped = true,
  id = 'naics',
  ...autoCompleteFieldProps
}: IndustryCodeAutocompleteExtraProps & {
  onChange: (
    value: AutocompleteValue<string, Multiple, DisabledClearable, undefined>
  ) => void;
  id?: string;
} & Omit<
    AutocompleteFieldProps<string, Multiple, DisabledClearable>,
    'options' | 'onChange' | 'id'
  >) {
  // Allow searching both by sector & full label
  const filterOptions = createFilterOptions({
    stringify: stringifyCodeFactory({ includeTaxonomy }),
  });

  // Autocomplete wants these to be presorted into groups or it doesn't group in
  // a reasonable way, so we do that here if we're grouping.
  const myOptions = grouped
    ? Object.values(groupBy(options, groupFunction)).flat()
    : options;

  const showBeaWeightingExplainerDialog = useDialog(
    BeaWeightingExplainerDialog
  );

  return (
    <AutocompleteFieldNonFormik
      {...autoCompleteFieldProps}
      id={id}
      sx={sx}
      label={label}
      visuallyHideLabel={!label}
      PopperComponent={GrowingPopper}
      placeholder={placeholder}
      options={myOptions}
      InputProps={
        value &&
        typeof value === 'string' &&
        getTaxonomyFromIndustryCode(value) !== 'BEA'
          ? {
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={() =>
                    showBeaWeightingExplainerDialog({
                      code: value,
                    })
                  }
                >
                  <InfoIcon />
                </IconButton>
              ),
            }
          : undefined
      }
      getOptionLabel={(code) =>
        safelyGetIndustryLabelFromIndustryCode(code, {
          includeCode: !hideCode,
          includeTaxonomy,
        })
      }
      onChange={(_event, value) => onChange(value)}
      value={value}
      required={required}
      hideRequired={hideRequired}
      groupBy={grouped ? groupFunction : undefined}
      filterOptions={filterOptions}
      fsUnmask
      inline={inline}
      disabled={disabled}
    />
  );
}

export function IndustryCodeAutocompleteField<
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
>({
  id,
  label = 'Industry code',
  grouped = false,
  hideCode = false,
  includeTaxonomy = false,
  options = INDUSTRY_CODE_LIST,
  placeholder = 'Type to search…',
  ...autocompleteFieldProps
}: IndustryCodeAutocompleteExtraProps &
  Omit<
    AutocompleteFieldProps<string, Multiple, DisabledClearable>,
    'options'
  >) {
  // Allow searching both by sector & full label
  const filterOptions = createFilterOptions({
    stringify: stringifyCodeFactory({ includeTaxonomy }),
  });

  // Autocomplete wants these to be presorted into groups or it doesn't group in
  // a reasonable way, so we do that here.
  const myOptions: ReadonlyArray<string> = grouped
    ? Object.values(groupBy(options, groupFunction)).flat()
    : options;

  return (
    <AutocompleteField
      {...autocompleteFieldProps}
      id={id}
      label={label}
      placeholder={placeholder}
      PopperComponent={GrowingPopper}
      autoCompleteOffPrettyPlease
      options={myOptions}
      getOptionLabel={(code: string) => {
        // SUP-6937: sometimes we get an empty list despite typescript assuring
        // us that option is a string.
        if (typeof code !== 'string') {
          return '';
        }
        return safelyGetIndustryLabelFromIndustryCode(code, {
          includeCode: !hideCode,
          includeTaxonomy,
        });
      }}
      groupBy={grouped ? groupFunction : undefined}
      filterOptions={filterOptions}
      fsUnmask
    />
  );
}
