import {
  BUSINESS_CATEGORY_LABELS,
  GHG_CATEGORY_LABELS,
} from './BusinessCategory';
import { msg } from '@lingui/core/macro';
import mapValues from 'lodash/mapValues';
import { BI_DIMENSION_FIELDS } from '../bi/fieldMetadata';
import { UNIT_LABELS } from './unitLabels';
import { MessageDescriptor } from '@lingui/core';
import { i18n } from '@watershed/intl';
import invariant from 'invariant';

export const BI_DIMENSION_LABELS = mapValues(
  BI_DIMENSION_FIELDS,
  (v) => v.displayNameMessageDescriptor
);

// These label keys are used to populate the new Drilldown page powered by
// MARTA, as well as other areas of the product, such as Dataset Summary.
const LABEL_MAPPINGS: { [key: string]: MessageDescriptor } = {
  // TODO maybe we can remove this when all product using BI
  ...BUSINESS_CATEGORY_LABELS,
  ...GHG_CATEGORY_LABELS,
  ...BI_DIMENSION_LABELS,
  ...UNIT_LABELS,
  building_unique_id: msg({
    context: 'Display label',
    message: 'Building unique ID',
  }),
  'capital goods': msg({ context: 'Display label', message: 'Capital goods' }),
  'carbon removal': msg({
    context: 'Display label',
    message: 'Carbon removal',
  }),
  'data centers': msg({ context: 'Display label', message: 'Data centers' }),
  energyProgram: msg({ context: 'Display label', message: 'Renewable energy' }),
  'food production': msg({
    context: 'Display label',
    message: 'Food production',
  }),
  fuel_kind: msg({ context: 'Display label', message: 'Fuel kind' }),
  'gnfr and_services': msg({
    context: 'Display label',
    message: 'GNFR & services',
  }),
  'home textiles': msg({ context: 'Display label', message: 'Home textiles' }),
  other: msg({ context: 'Display label', message: 'Other' }),
  other_additional_clean_power: msg({
    context: 'Display label',
    message: 'Additional clean power purchased',
  }),
  'product use': msg({ context: 'Display label', message: 'Product use' }),
  'pre-owned products': msg({
    context: 'Display label',
    message: 'Pre-owned products',
  }),
  professional_services: msg({
    context: 'Display label',
    message: 'Professional services',
  }),
  entity: msg({ context: 'Display label', message: 'Entity' }),
  vendorProgram: msg({
    context: 'Display label',
    message: 'Supplier climate program',
  }),
  account: msg({ context: 'Display label', message: 'Account' }),
  miles_flown: msg({
    context: 'Display label',
    message: 'Distance flown (passenger-mi)',
  }),
  num_flights: msg({ context: 'Display label', message: 'Number of flights' }),
  vendor_entity: msg({ context: 'Display label', message: 'Vendor' }),
  custom_dimensions_raw_data_vendor_entity: msg({
    context: 'Display label',
    message: 'Raw vendors',
  }),
  cost_usd: msg({ context: 'Display label', message: 'Cost (USD)' }),
  building_name: msg({
    context: 'Display label',
    message: 'Building identifier',
  }),
  location_city: msg({ context: 'Display label', message: 'City' }),
  location_state: msg({ context: 'Display label', message: 'State' }),
  location_country: msg({ context: 'Display label', message: 'Country' }),
  location_type: msg({ context: 'Display label', message: 'Location type' }),
  wfh: msg({ context: 'Display label', message: 'Work from home' }),
  on_site: msg({ context: 'Display label', message: 'On site' }),
  revenue_type: msg({ context: 'Display label', message: 'Revenue type' }),
  revenue: msg({ context: 'Display label', message: 'Revenue' }),
  net_revenue: msg({ context: 'Display label', message: 'Net revenue' }),
  revenue_usd: msg({ context: 'Display label', message: 'Revenue (USD)' }),
  currency: msg({ context: 'Display label', message: 'Currency' }),
  instance_type: msg({ context: 'Display label', message: 'Instance type' }),
  region: msg({ context: 'Display label', message: 'Region' }),
  reporting_entity: msg({
    context: 'Display label',
    message: 'Reporting entity',
  }),
  transport_mode: msg({ context: 'Display label', message: 'Transport mode' }),
  Scope1: msg({ context: 'Display label', message: 'Scope 1' }),
  Scope2: msg({ context: 'Display label', message: 'Scope 2' }),
  Scope3: msg({ context: 'Display label', message: 'Scope 3' }),
  CarbonRemoval: msg({ context: 'Display label', message: 'Carbon removal' }),
  'end use': msg({ context: 'Display label', message: 'End use' }),
  datasource: msg({ context: 'Display label', message: 'Datasource' }),
  quantityUnit: msg({ context: 'Display label', message: 'Quantity unit' }),
  quantityValue: msg({ context: 'Display label', message: 'Quantity value' }),
  quantity_value: msg({ context: 'Display label', message: 'Quantity value' }),
  expected_term_length_days: msg({
    context: 'Display label',
    message: 'Expected term length (days)',
  }),
  actual_term_length_days: msg({
    context: 'Display label',
    message: 'Actual term length (days)',
  }),
  incidents: msg({ context: 'Display label', message: 'Incidents' }),
  inputQuantity: msg({ context: 'Display label', message: 'Source value' }),
  electricity_type: msg({
    context: 'Display label',
    message: 'Electricity type',
  }),
  Clean: msg({ context: 'Display label', message: 'Clean' }),
  Grid: msg({ context: 'Display label', message: 'Grid' }),
  cost: msg({ context: 'Display label', message: 'Cost' }),
  gallons: msg({ context: 'Display label', message: 'Gallons' }),
  liter: msg({ context: 'Display label', message: 'Liters' }),
  file_name: msg({ context: 'Display label', message: 'File name' }),
  user: msg({ context: 'Display label', message: 'User' }),
  action: msg({ context: 'Display label', message: 'Action' }),
  sourceFile: msg({ context: 'Display label', message: 'Source File' }),
  objectType: msg({ context: 'Display label', message: 'Object Type' }),
  object: msg({ context: 'Display label', message: 'Object' }),
  field: msg({ context: 'Display label', message: 'Field' }),
  custom_dimensions: msg({
    context: 'Display label',
    message: 'Custom dimensions',
  }),
  vendor_subentity: msg({
    context: 'Display label',
    message: 'Vendor subentity',
  }),
  emissions_subspecifier: msg({
    context: 'Display label',
    message: 'Emissions subspecifier',
  }),
  unit: msg({ context: 'Display label', message: 'Unit' }),
  weight_distance_quantity: msg({
    context: 'Display label',
    message: 'Weight-distance quantity',
  }),
  truck: msg({ context: 'Display label', message: 'Truck' }),
  first_mile: msg({ context: 'Display label', message: 'First mile' }),
  last_mile_only: msg({ context: 'Display label', message: 'Last mile only' }),
  sea: msg({ context: 'Display label', message: 'Sea' }),
  truck_great_circle: msg({
    context: 'Display label',
    message: 'Truck great circle',
  }),
  air_great_circle: msg({
    context: 'Display label',
    message: 'Air great circle',
  }),
  air: msg({ context: 'Display label', message: 'Air' }),
  refrigerated_truck_great_circle: msg({
    context: 'Display label',
    message: 'Refrigerated truck great circle',
  }),
  refrigerated_truck: msg({
    context: 'Display label',
    message: 'Refrigerated truck',
  }),
  transportation_mode: msg({
    context: 'Display label',
    message: 'Transportation mode',
  }),
  electricity: msg({ context: 'Display label', message: 'Electricity' }),
  non_building_electricity_consumption: msg({
    context: 'Display label',
    message: 'Non-building electricity consumption',
  }),
  medium_heavy_truck: msg({
    context: 'Display label',
    message: 'Medium heavy truck',
  }),
  van_light_truck_electric: msg({
    context: 'Display label',
    message: 'Van light truck electric',
  }),
  motorcycle: msg({ context: 'Display label', message: 'Motorcycle' }),
  van_light_truck: msg({
    context: 'Display label',
    message: 'Van light truck',
  }),
  flight_route: msg({ context: 'Display label', message: 'Flight route' }),
  trip_kind: msg({ context: 'Display label', message: 'Trip kind' }),
  private_plane_model: msg({
    context: 'Display label',
    message: 'Private plane model',
  }),
  is_company_owned_or_leased: msg({
    context: 'Display label',
    message: 'Is company owned or leased',
  }),
  one_way: msg({ context: 'Display label', message: 'One way' }),
  round_trip: msg({ context: 'Display label', message: 'Round trip' }),
  passenger_quantity: msg({
    context: 'Display label',
    message: 'Passenger quantity',
  }),
  flight_cabin_class: msg({
    context: 'Display label',
    message: 'Flight cabin class',
  }),
  first: msg({ context: 'Display label', message: 'First' }),
  business: msg({ context: 'Display label', message: 'Business' }),
  premium_economy: msg({
    context: 'Display label',
    message: 'Premium economy',
  }),
  economy: msg({ context: 'Display label', message: 'Economy' }),
  datasetName: msg({ context: 'Display label', message: 'Dataset' }),
  origin_location_country: msg({
    context: 'Display label',
    message: 'Origin country',
  }),
  origin_location_postal_code: msg({
    context: 'Display label',
    message: 'Origin postal code',
  }),
  destination_location_country: msg({
    context: 'Display label',
    message: 'Destination country',
  }),
  destination_location_postal_code: msg({
    context: 'Display label',
    message: 'Destination postal code',
  }),
  weight_quantity: msg({
    context: 'Display label',
    message: 'Weight quantity',
  }),
  weight_unit: msg({ context: 'Display label', message: 'Weight unit' }),
  distance_quantity: msg({
    context: 'Display label',
    message: 'Distance quantity',
  }),
  distance_unit: msg({ context: 'Display label', message: 'Distance unit' }),
  has_matching_cloud_compute_usage: msg({
    context: 'Display label',
    message: 'Has matching cloud compute usage',
  }),
  site_identifier: msg({
    context: 'Display label',
    message: 'Site identifier',
  }),
  num_workers: msg({
    context: 'Display label',
    message: 'Number of workers',
  }),
  num_paid_adequate_wage: msg({
    context: 'Display label',
    message: 'Number paid adequate wage',
  }),
};

// We use this function to gate access so that we can translate all the MessageDescriptors
export function getMappingForLabel(key: string): string | undefined {
  const label = LABEL_MAPPINGS[key];
  return label ? i18n.t(label) : undefined;
}

/**
 * If you wish to guarantee the mapping for a label, then use this function.
 */
export function getEnsuredMappingForLabel(key: string): string {
  const mapping = getMappingForLabel(key);
  invariant(mapping, `Mapping must exist for label: ${key}`);
  return mapping;
}
