import { Theme, createTheme } from '@mui/material/styles';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import CloseIcon from '@watershed/icons/components/Close';
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';
import {
  SERIF_FONT_FAMILY,
  iconSizeMedium,
  SPACING_PX,
  getPaletteUtils,
  popoverTransitionDuration,
} from '@watershed/style/styleUtils';
import MessinaSansMonoWebWoff from '../utils/MessinaSansMonoWeb-Regular.woff';
import MessinaSansMonoWebWoff2 from '../utils/MessinaSansMonoWeb-Regular.woff2';
import MessinaSansBoldWoff from '../utils/MessinaSansWeb-Bold.woff';
import MessinaSansBoldWoff2 from '../utils/MessinaSansWeb-Bold.woff2';
import MessinaSansRegularWoff from '../utils/MessinaSansWeb-Regular.woff';
import MessinaSansRegularWoff2 from '../utils/MessinaSansWeb-Regular.woff2';
import MessinaSansSemiboldWoff from '../utils/MessinaSansWeb-SemiBold.woff';
import MessinaSansSemiboldWoff2 from '../utils/MessinaSansWeb-SemiBold.woff2';
// import MackinacProBookWoff from '../utils/P22MackinacProBook.woff';
// import MackinacProBookWoff2 from '../utils/P22MackinacProBook.woff2';
import MackinacProMediumWoff from '../utils/P22MackinacProMedium.woff';
import MackinacProMediumWoff2 from '../utils/P22MackinacProMedium.woff2';
import CheckmarkIcon from '@watershed/ui-core/components/CheckmarkIcon';
import { isButtonIconOnly } from './utils';
import { menuItemPadding } from '@watershed/style/styleUtils';
// eslint-disable-next-line no-restricted-imports
import { palette } from '@watershed/style/palette';

// Required to satisfy TS typings for MUI lab theming.
// https://mui.com/material-ui/about-the-lab/#typescript
import type {} from '@mui/lab/themeAugmentation';
import { SupportedLocale } from '@watershed/intl/constants';

const paletteUtils = getPaletteUtils(palette);

import {
  enUS as coreEnUS,
  jaJP as coreJaJP,
  deDE as coreDeDE,
  frFR as coreFrFR,
  esES as coreEsES,
  plPL as corePlPL,
  ptBR as corePtBR,
  trTR as coreTrTR,
  zhCN as coreZhCN,
} from '@mui/material/locale';
import {
  enUS as xDatePickersEnUS,
  jaJP as xDatePickersJaJP,
  deDE as xDatePickersDeDE,
  frFR as xDatePickersFrFR,
  esES as xDatePickersEsES,
  plPL as xDatePickersPlPL,
  ptBR as xDatePickersPtBR,
  trTR as xDatePickersTrTR,
  zhCN as xDatePickersZhCN,
} from '@mui/x-date-pickers/locales';
import {
  enUS as xDataGridEnUS,
  jaJP as xDataGridJaJP,
  deDE as xDataGridDeDE,
  frFR as xDataGridFrFR,
  esES as xDataGridEsES,
  ptBR as xDataGridPtBR,
  trTR as xDataGridTrTR,
  plPL as xDataGridPlPL,
  zhCN as xDataGridZhCN,
} from '@mui/x-data-grid/locales';

// Note: line heights are relative based on font size, so if you change font
// size change line height too!
const typography = {
  // This is a special display font that's large and uses our serif font (Makinac).
  title: {
    fontFamily: SERIF_FONT_FAMILY,
    color: palette.midnight,
    fontSize: 40,
    lineHeight: '48px',
    fontWeight: 500,
    letterSpacing: '-0.004em',
  },
  h1: {
    color: palette.text.primary,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 32 / 24,
    fontVariantNumeric: 'tabular-nums',
  },
  h2: {
    color: palette.text.primary,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 24 / 18,
    fontVariantNumeric: 'tabular-nums',
  },
  h3: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24 / 16,
    fontVariantNumeric: 'tabular-nums',
  },
  h4: {
    color: palette.text.primary,
    fontSize: 14, // same as body1, but bolder.
    fontWeight: 600,
    lineHeight: 24 / 14,
    fontVariantNumeric: 'tabular-nums',
  },
  // Same as h4 but with secondary color
  h5: {
    color: palette.text.secondary,
    fontSize: 14, // same as body1, but bolder.
    fontWeight: 600,
    lineHeight: 24 / 14,
    fontVariantNumeric: 'tabular-nums',
  },
  body1: {
    color: palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 24 / 14,
    fontVariantNumeric: 'tabular-nums',
    '& > a': {
      color: palette.primary.main,
    },
  },
  body2: {
    color: palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 24 / 14,
    fontVariantNumeric: 'tabular-nums',
    '& > a': {
      color: palette.primary.main,
    },
  },
  body3: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 20 / 12,
    fontVariantNumeric: 'tabular-nums',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 20 / 12,
    fontVariantNumeric: 'tabular-nums',
  },
} as const;

export function getTheme(locale: SupportedLocale): Theme {
  // TODO: Can we use dynamic imports instead?
  const muiLocaleConfigs = {
    'en-US': [xDatePickersEnUS, xDataGridEnUS, coreEnUS],
    'en-GB': [xDatePickersEnUS, xDataGridEnUS, coreEnUS],
    'en-PL': [xDatePickersJaJP, xDataGridJaJP, coreJaJP],
    'ja-JP': [xDatePickersJaJP, xDataGridJaJP, coreJaJP],
    'de-DE': [xDatePickersDeDE, xDataGridDeDE, coreDeDE],
    'fr-FR': [xDatePickersFrFR, xDataGridFrFR, coreFrFR],
    'pt-BR': [xDatePickersPtBR, xDataGridPtBR, corePtBR],
    'es-ES': [xDatePickersEsES, xDataGridEsES, coreEsES],
    'pl-PL': [xDatePickersPlPL, xDataGridPlPL, corePlPL],
    'tr-TR': [xDatePickersTrTR, xDataGridTrTR, coreTrTR],
    'zh-CN': [xDatePickersZhCN, xDataGridZhCN, coreZhCN],
  }[locale] ?? [xDatePickersEnUS, xDataGridEnUS, coreEnUS];

  return createTheme(
    {
      components: {
        MuiCssBaseline: {
          styleOverrides: `
          @font-face {
            font-family: MessinaSans;
            font-style: normal;
            font-display: fallback;
            font-weight: 400;
            src: url(${MessinaSansRegularWoff2}) format('woff2'),
              url(${MessinaSansRegularWoff}) format('woff');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
              U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
              U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: MessinaSans;
            font-style: normal;
            font-display: fallback;
            font-weight: 600;
            src: url(${MessinaSansSemiboldWoff2}) format('woff2'),
              url(${MessinaSansSemiboldWoff}) format('woff');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
              U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
              U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: MessinaSans;
            font-style: normal;
            font-display: fallback;
            font-weight: 700;
            src: url(${MessinaSansBoldWoff2}) format('woff2'),
              url(${MessinaSansBoldWoff}) format('woff');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
              U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
              U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: MackinacPro;
            font-style: normal;
            font-display: fallback;
            font-weight: 500;
            src: url(${MackinacProMediumWoff2}) format('woff2'),
              url(${MackinacProMediumWoff}) format('woff');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
              U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
              U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: MessinaSansMono;
            font-style: bold;
            font-display: fallback;
            font-weight: 700;
            src: url(${MessinaSansMonoWebWoff2}) format('woff2'),
              url(${MessinaSansMonoWebWoff}) format('woff');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
              U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
              U+2215, U+FEFF, U+FFFD;
          }
        `,
        },
        MuiAvatar: {
          styleOverrides: {
            root: {
              fontWeight: 600,
            },
            rounded: {
              borderRadius: '4px',
            },
          },
        },
        MuiAvatarGroup: {
          styleOverrides: {
            // By default, the avatars are right-aligned with `flexDirection:
            // 'row-reverse'`, so override this and also adjust the margins to match
            // the left-alignment.
            root: {
              flexDirection: 'row',
              '& .MuiAvatar-root:first-child': {
                marginLeft: 0,
              },
              '& .MuiAvatar-root:last-child': {
                marginLeft: '-8px',
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            elevation1: {
              boxShadow: paletteUtils.boxShadow,
            },
            elevation8: {
              boxShadow: paletteUtils.boxShadowMenu,
            },
            elevation9: {
              boxShadow: paletteUtils.boxShadowLg,
            },
            rounded: {
              borderRadius: '10px',
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              boxShadow: paletteUtils.boxShadowLg,
            },
            container: {
              // secondary.dark at 60%
              backgroundColor: `${palette.secondary.dark}99`,
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              borderBottom: `1px solid ${palette.divider}`,
              padding: 24,
              '& h2': {
                ...typography.h1,
                overflowWrap: 'break-word',
              },
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              padding: '16px',
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              borderTop: `1px solid ${palette.divider}`,
              padding: '16px',
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            ...typography,
          },
        },
        MuiContainer: {
          styleOverrides: {
            maxWidthMd: {
              maxWidth: '1024px !important', // 900px is default, in a media query
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            root: {
              padding: '10px 0',
            },
            thumb: {
              backgroundColor: palette.white,
              boxShadow: paletteUtils.boxShadow,
            },
            rail: {
              backgroundColor: palette.grey20,
              opacity: 1.0,
            },
            mark: {
              backgroundColor: palette.grey30,
              height: '4px',
              width: '4px',
              borderRadius: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
          styleOverrides: {
            root: {
              letterSpacing: '-0.03em',
            },
          },
        },
        MuiButtonGroup: {
          defaultProps: {
            variant: 'contained',
            color: 'secondary',
            disableRipple: true,
          },
          styleOverrides: {
            contained: {
              color: palette.text.primary,
              backgroundColor: palette.white,
              boxShadow: paletteUtils.boxShadowButton.secondary.default,
            },
            grouped: {
              // CSS specificity hack to override all variants & pseudo-states without !important
              '&.Mui-disabled.Mui-disabled.Mui-disabled': {
                color: palette.text.disabled,
                backgroundImage: 'none',
                backgroundColor: palette.background.paper,
                boxShadow: paletteUtils.boxShadowButtonGrouped.disabled,
                border: 'none',
              },
              ':not(.first-of-type)': {
                marginLeft: '-1px',
              },
              ':not(:last-of-type)': {
                borderRight: 0,
              },
              '&:focus': {
                zIndex: 1,
              },
            },
            // TODO: primary contained, or outline or any other variant
            groupedContainedSecondary: {
              boxShadow: paletteUtils.boxShadowButtonGrouped.secondary.default,
              '&:hover,  &:active': {
                boxShadow: paletteUtils.boxShadowButtonGrouped.secondary.hover,
              },
              '&:focus': {
                boxShadow: paletteUtils.boxShadowButtonGrouped.focus,
              },
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'contained',
            color: 'secondary',
          },
          styleOverrides: {
            root: ({ ownerState }) => ({
              letterSpacing: '-0.03em',
              textTransform: 'none',
              fontSize: 14,
              lineHeight: '16px',
              fontWeight: 600,

              flexShrink: 0,
              height: 32,
              padding: '0 12px',
              borderRadius: 6,
              textDecoration: 'none !important',
              transition: paletteUtils.transition,
              transitionProperty:
                'color, background-color, background-image, box-shadow',
              ...(isButtonIconOnly(ownerState) && {
                minWidth: ownerState.size === 'small' ? 24 : 32,
                padding: 0,
              }),
            }),
            iconSizeSmall: ({ ownerState }) =>
              !isButtonIconOnly(ownerState)
                ? {
                    '& svg': {
                      width: 12,
                      height: 12,
                    },
                  }
                : {},
            iconSizeMedium: ({ ownerState }) =>
              !isButtonIconOnly(ownerState)
                ? {
                    '& svg': {
                      width: iconSizeMedium,
                      height: iconSizeMedium,
                    },
                  }
                : {},
            endIcon: ({ ownerState }) =>
              isButtonIconOnly(ownerState)
                ? { margin: 0 }
                : {
                    marginLeft: 4,
                  },
            startIcon: ({ ownerState }) =>
              isButtonIconOnly(ownerState)
                ? { margin: 0 }
                : {
                    marginRight: 4,
                  },
            sizeSmall: ({ ownerState }) =>
              isButtonIconOnly(ownerState)
                ? {}
                : {
                    height: 28,
                    minWidth: 32,
                    fontSize: typography.body1.fontSize,
                  },
            textSecondary: {
              color: palette.text.primary,
              '&:hover, &:focus': {
                backgroundColor: palette.background.default,
              },
              '&:focus-visible': {
                boxShadow: paletteUtils.boxShadowButton.focus,
              },
              '&.Mui-disabled.Mui-disabled.Mui-disabled': {
                color: palette.text.disabled,
              },
            },
            textPrimary: {
              color: palette.primary.main,
              '&:hover, &:focus': {
                backgroundColor: palette.primary.light,
                color: palette.primary.dark,
              },
              '&:focus-visible': {
                boxShadow: paletteUtils.boxShadowButton.focus,
              },
              '&.Mui-disabled.Mui-disabled.Mui-disabled': {
                color: palette.cobalt30,
              },
            },
            contained: {
              // CSS specificity hack to override all variants & pseudo-states without !important
              '&.Mui-disabled.Mui-disabled.Mui-disabled': {
                color: palette.text.disabled,
                backgroundImage: 'none',
                backgroundColor: palette.background.paper,
                boxShadow: paletteUtils.boxShadowButton.disabled,
                opacity: 0.5,
              },
            },
            containedPrimary: {
              boxShadow: paletteUtils.boxShadowButton.primary.default,
              backgroundColor: palette.primary.main,
              '&:hover, &:focus': {
                boxShadow: paletteUtils.boxShadowButton.primary.hover,
              },
              '&:focus-visible': {
                boxShadow: paletteUtils.boxShadowButton.focus,
              },
              // CSS specificity hack to override all variants & pseudo-states without !important
              '&.Mui-disabled.Mui-disabled.Mui-disabled': {
                backgroundColor: palette.cobalt30,
                color: palette.white,
                boxShadow: 'none',
              },
            },
            containedSecondary: {
              color: palette.text.primary,
              backgroundColor: palette.white,
              boxShadow: paletteUtils.boxShadowButton.secondary.default,
              '&:hover, &:focus, &:active': {
                color: palette.text.primary,
                backgroundColor: palette.background.default,
                boxShadow: paletteUtils.boxShadowButton.secondary.hover,
              },
              '&:focus-visible': {
                boxShadow: paletteUtils.boxShadowButton.focus,
              },
              '&.Mui-disabled.Mui-disabled.Mui-disabled': {
                color: palette.text.disabled,
              },
            },
            containedError: {
              backgroundColor: palette.white,
              boxShadow: paletteUtils.boxShadowButton.destructive.default,
              '&, &:hover, &:focus, &:active': {
                color: palette.error.dark,
              },
              '&:focus-visible': {
                boxShadow: paletteUtils.boxShadowButton.destructive.focus,
              },
              '&:hover, &:active': {
                backgroundColor: palette.error.light,
                boxShadow: paletteUtils.boxShadowButton.destructive.hover,
              },
              '&.Mui-disabled.Mui-disabled.Mui-disabled': {
                color: palette.sun30,
              },
            },
            containedSizeSmall: {
              lineHeight: '20px',
              height: 24,
              fontSize: 14,
            },
            sizeLarge: {
              padding: '0 12px',
            },
            text: ({ ownerState }) =>
              isButtonIconOnly(ownerState)
                ? {}
                : {
                    padding: '0 12px',
                  },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              font: 'inherit',
              textTransform: 'none',
              fontWeight: 600,
            },
            sizeMedium: {
              '& svg': {
                width: iconSizeMedium,
                height: iconSizeMedium,
              },
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: palette.text.primary,
              padding: 0,
              borderRadius: 6,
              flexShrink: 0,
              '&:focus': {
                color: palette.primary.main,
                boxShadow: paletteUtils.boxShadowButton.focus,
              },
              '&:active': {
                backgroundColor: palette.secondary.light,
              },
            },
            sizeSmall: {
              padding: 0,
            },
            sizeLarge: {
              padding: 6,
            },
            colorPrimary: {
              boxShadow: paletteUtils.boxShadowButton.secondary.default,
              backgroundColor: palette.white,
              color: palette.text.primary,
              padding: 4,
              transition: paletteUtils.transition,
              transitionProperty: 'color, box-shadow',
              '&:hover': {
                color: palette.text.primary,
                backgroundColor: palette.background.default,
                boxShadow: paletteUtils.boxShadowButton.secondary.hover,
              },
              '&:focus': {
                color: palette.text.primary,
                boxShadow: paletteUtils.boxShadowButton.focus,
              },
            },
            colorSecondary: {
              color: palette.secondary.dark,
              transition: paletteUtils.transition,
              transitionProperty: 'color',
              '&:hover, &:focus': {
                color: palette.primary.main,
              },
            },
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            clearIcon: <CloseIcon />,
            popupIcon: <ChevronDownIcon />,
            ChipProps: { deleteIcon: <CloseIcon size={16} /> },
          },
          styleOverrides: {
            root: {
              overflow: 'visible',
              position: 'relative',
              '&.Mui-focused .MuiAutocomplete-inputRoot': {
                boxShadow: paletteUtils.boxShadowField.focus,
              },
              '&.Mui-focused .MuiFilledInput-root': {
                boxShadow: 'none',
                background: palette.grey20,
              },
              '& .MuiFilledInput-root': {
                background: palette.grey10,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '12px',

                '&:hover': {
                  background: palette.grey20,
                },

                '& svg': {
                  width: 16,
                  height: 16,
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
            },
            inputRoot: {
              '&:not(.MuiFilledInput-root)': {
                backgroundColor: palette.white,
                boxShadow: paletteUtils.boxShadowField.base,
              },
              color: palette.text.primary,
              borderRadius: 6,
              '&:before, &:after': {
                // content: 'unset !important',
                display: 'none !important',
              },
            },
            input: {
              font: 'inherit',
              letterSpacing: 'inherit',
              appearance: 'none',
              padding: '0 !important',
              lineHeight: '24px',
              height: 'auto',
              minWidth: '5ch !important',
              '&.Mui-disabled': {
                cursor: 'not-allowed',
              },
            },
            popupIndicator: {
              color: 'inherit',
            },
            clearIndicator: {
              color: 'inherit',
            },
            tagSizeSmall: {
              margin: 0,
              marginRight: 4,
            },
            groupLabel: {
              paddingTop: '6px',
              paddingBottom: '6px',
              fontWeight: 'normal',
              background: palette.panel,
              color: palette.text.secondary,
              top: 0,
            },
            paper: {
              borderRadius: 6,
              backgroundColor: palette.background.paper,
              boxShadow: paletteUtils.boxShadowMenu,
              border: `1px solid ${palette.grey30}`,
            },
            listbox: {
              padding: 0,
            },
            option: {
              lineHeight: '20px',
              '&[aria-selected="true"]': {
                backgroundColor: palette.secondary.light,
              },
              '&[data-focus="true"]': {
                backgroundColor: palette.secondary.light,
              },
            },
            endAdornment: {
              // Defaults to `calc(50% - 14px)` but that is too high, not vertically centered
              top: 'calc(50% - 12px);',
            },

            // This is getting overridden with margin 0, which has option tags touching each other,
            // which is gross.
            tag: {
              marginBottom: '2px !important',
              marginTop: '2px  !important',
              borderRadius: '4px', // default 6px doesn’t nest well
            },
          },
        },
        MuiSelect: {
          defaultProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            },
            IconComponent: ChevronDownIcon,
          },
          styleOverrides: {
            select: {
              font: 'inherit',
              letterSpacing: 'inherit',
              backgroundColor: palette.white,
              color: palette.text.primary,
              border: 0,
              boxShadow: paletteUtils.boxShadowField.base,
              borderRadius: 6,
              appearance: 'none',
              padding: '4px 28px 4px 12px !important',
              lineHeight: '24px',
              '&:focus': {
                outline: 0,
                boxShadow: paletteUtils.boxShadowField.focus,
              },
              '&:before, &:after': {
                // content: 'unset !important',
                display: 'none !important',
              },
              '&.Mui-disabled': {
                color: palette.secondary.main,
                backgroundColor: palette.background.paper,
                cursor: 'not-allowed',
              },
            },
            icon: {
              color: 'inherit',
              top: 'auto !important',
              right: 8,
              width: 14,
              height: 14,
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            root: {
              width: 40,
              height: 24,
              padding: 0,
              overflow: 'hidden',
            },
            switchBase: {
              padding: 2,
              '&.Mui-checked': {
                transform: 'translateX(16px)',
              },
            },
            colorPrimary: {
              color: palette.white,
              '&:active': {
                backgroundColor: 'unset !important',
              },
              '&.Mui-checked': {
                color: palette.white,
                '& + .MuiSwitch-track': {
                  backgroundColor: palette.primary.main,
                  opacity: 1,
                },
                '&:checked': {
                  backgroundColor: palette.primary.main,
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
            thumb: {
              boxShadow: paletteUtils.boxShadow,
            },
            track: {
              width: '100%',
              height: '100%',
              borderRadius: 12,
              opacity: 1,
              backgroundColor: palette.secondary.main,
              transition: paletteUtils.transition,
              transitionProperty: 'background-color',
            },
            sizeSmall: {
              width: 32,
              height: 20,
              padding: 0,
              '& .MuiSwitch-switchBase': {
                padding: 2,
                '&.Mui-checked': {
                  transform: 'translateX(12px)',
                },
              },
              '& .MuiSwitch-thumb': {
                width: 16,
                height: 16,
                boxShadow: 'none',
              },
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              marginInline: 0,
              '& .MuiFormControlLabel-label.Mui-disabled': {
                color: palette.grey70,
              },
              '&.MuiFormControlLabel-labelPlacementEnd .MuiSwitch-root + .MuiFormControlLabel-label':
                {
                  marginLeft: 8,
                },
            },
          },
        },
        MuiCard: {
          defaultProps: {
            variant: 'outlined',
          },
          styleOverrides: {
            root: {
              borderRadius: '6px',
            },
          },
        },
        MuiTooltip: {
          defaultProps: {
            arrow: true,
          },
          styleOverrides: {
            tooltip: {
              backgroundColor: palette.white,
              color: palette.text.primary,
              fontSize: 14,
              fontWeight: 400,
              padding: '8px 12px',
              boxShadow: paletteUtils.boxShadowMd,
              whiteSpace: 'pre-line',
            },
            arrow: {
              color: palette.white,
            },
          },
        },
        MuiPopover: {
          defaultProps: {
            transitionDuration: popoverTransitionDuration,
          },
          styleOverrides: {
            paper: {
              borderRadius: '6px',
            },
          },
        },
        MuiMenu: {
          defaultProps: {
            transitionDuration: popoverTransitionDuration,
          },
          styleOverrides: {
            list: {
              paddingTop: 0,
              paddingBottom: 0,
            },
            paper: {
              backgroundColor: palette.background.paper,
              border: `1px solid ${palette.grey20}`,
              boxShadow: paletteUtils.boxShadowMenu,
              marginTop: 4,
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              '&:first-of-type': {
                borderTop: `none`,
              },
              borderTop: `1px solid ${palette.border}`,
              fontSize: typography.body1.fontSize,
              ...menuItemPadding,
              lineHeight: '20px',
              '&:hover, &:active': {
                backgroundColor: palette.info.light,
              },
              '&.Mui-selected': {
                '&, &:hover': {
                  backgroundColor: palette.info.light,
                },
              },
            },
          },
        },
        MuiChip: {
          variants: [
            {
              props: { color: 'decorative' },
              style: {},
            },
          ],
          defaultProps: {
            deleteIcon: <CloseIcon size={16} />,
          },
          styleOverrides: {
            root: ({ ownerState: { color = 'secondary', clickable } }) => ({
              // adding body to increase specificity since mui adds this exact style and it happens to after in the style sheet for some reason
              'body &:hover': clickable
                ? {
                    backgroundColor:
                      paletteUtils.chipColors[color].hoverBackground,
                    color: paletteUtils.chipColors[color].text,
                    fill: paletteUtils.chipColors[color].text,
                  }
                : undefined,
              backgroundColor: paletteUtils.chipColors[color].background,
              color: paletteUtils.chipColors[color].text,
              fill: paletteUtils.chipColors[color].text,
              borderRadius: '6px',
              fontSize: 14,
              fontWeight: 600,
              height: 24,
              lineHeight: '24px',
              letterSpacing: '-0.48px',
            }),
            label: ({ theme }) => ({
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            }),
            icon: ({ theme }) => ({
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(-0.5),
              height: 16,
              width: 16,
            }),
            outlined: ({ ownerState: { color = 'secondary' } }) => ({
              backgroundColor: palette.paper,
              border: '1px solid',
              borderColor: paletteUtils.chipColors[color].background,
            }),
            deleteIcon: {
              '&, &:hover': {
                color: palette.secondary.dark,
              },
            },
            sizeSmall: {
              height: 20,
              fontSize: 13,
            },
            clickable: {
              transition: paletteUtils.transition,
              transitionProperty: 'color, background-color, fill',
              '&:hover, &.Mui-focusVisible': {
                backgroundColor: palette.cobalt05,
              },
              '&:active': {
                boxShadow: 'none',
              },
            },
          },
        },
        MuiInputLabel: {
          defaultProps: {
            shrink: false,
          },
          styleOverrides: {
            root: {
              ...typography.body1,
            },
            formControl: {
              top: 'unset',
              left: 'unset',
              position: 'unset',
              transform: 'unset',
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              marginTop: 8,
            },
          },
        },
        MuiInput: {
          defaultProps: {
            disableUnderline: true,
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              letterSpacing: '-0.03em',
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              border: 'none',
              '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },

              '& svg': {
                width: 16,
                height: 16,
              },
            },
            colorSecondary: {
              ...paletteUtils.textFieldStyles,
            },
            notchedOutline: {
              display: 'none',
            },
            adornedEnd: {
              '& > svg': {
                fill: palette.info.main,
                flexShrink: 0,
              },
            },
          },
        },

        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: palette.text.secondary,
            },
          },
        },

        MuiListSubheader: {
          styleOverrides: {
            root: {
              ...typography.h4,
              paddingTop: 8,
              paddingBottom: 8,
            },
            gutters: {
              // To match `MuiMenuItem` override.
              ...menuItemPadding,
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              width: 'fit-content',
              minHeight: 'unset',
              overflow: 'visible',
            },
            fixed: {
              overflow: 'visible !important',
            },
            flexContainer: {
              gap: 16,
            },
            indicator: {
              backgroundColor: palette.primary.main,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: 14,
              color: palette.text.secondary,
              letterSpacing: 'inherit',
              lineHeight: '16px',
              textTransform: 'none',
              minHeight: 'unset !important',
              minWidth: 'unset !important',
              padding: '12px 0',
              transition: paletteUtils.transition,
              transitionProperty: 'color, box-shadow',
              flexDirection: 'row',
              gap: 8,
              '&:hover': {
                color: palette.text.primary,
              },
              '&.Mui-disabled': {
                color: palette.text.disabled,
              },
              '&.Mui-selected': {
                color: palette.text.primary,
              },
              '&:focus': {
                outline: 0,
              },
              '& svg': {
                margin: '0 !important',
                width: 16,
                height: 16,
              },
            },
            labelIcon: {
              paddingTop: 6,
            },
            textColorInherit: {
              opacity: 1,
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              outlineColor: palette.primary.main,
            },
            button: ({ ownerState }) => ({
              letterSpacing: 'inherit',
              // allows variant font size to override our default
              fontSize:
                ownerState.variant == null || ownerState.variant === 'inherit'
                  ? 15
                  : undefined,
            }),
            underlineHover: {
              '& h1:hover, & h2:hover, & h3:hover, & h4:hover': {
                textDecoration: 'underline',
              },
            },
          },
        },
        MuiTableSortLabel: {
          styleOverrides: {
            root: {
              color: palette.text.secondary,
              lineHeight: 1.25,
            },
            icon: {
              marginLeft: 8,
              marginRight: 0,
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              '&:last-child > *': {
                borderBottom: 'none',
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: `1px solid ${palette.divider}`,
            },
            head: {
              ...typography.h4,
            },
            sizeSmall: {
              '&:last-child': {
                paddingRight: 24, // Undo weird default where this isn't the same
              },
            },
          },
        },
        MuiLinearProgress: {
          styleOverrides: {
            root: {
              height: 4,
              borderRadius: 2,
              transitionTimingFunction: 'ease-in-out',
            },
          },
        },
        MuiFormGroup: {
          styleOverrides: {
            root: {
              gap: 12,
            },
            row: {
              'legend + &': {
                marginTop: 4,
              },
            },
          },
        },
        MuiRadio: {
          defaultProps: {
            color: 'default',
            icon: <></>,
            checkedIcon: <></>,
          },
          styleOverrides: {
            root: {
              backgroundColor: palette.white,
              boxShadow: paletteUtils.boxShadowButton.secondary.default,
              width: 16,
              height: 16,
              borderRadius: 16,
              marginRight: 12,
              transition: paletteUtils.transition,
              transitionProperty: 'background-color',
              position: 'relative',
              // Selection circle
              '&.Mui-checked::before': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                backgroundColor: palette.white,
                borderRadius: 4,
                width: 6,
                height: 6,
              },
              '&.Mui-checked': {
                backgroundColor: palette.primary.main,
                boxShadow: paletteUtils.boxShadowButton.primary.default,
              },
              '&.Mui-disabled': {
                backgroundColor: palette.secondary.main,
                boxShadow: paletteUtils.boxShadowField.base,
              },
              '&:has(> input:focus)': {
                boxShadow: paletteUtils.boxShadowFocus,
              },
            },
          },
        },
        MuiCheckbox: {
          defaultProps: {
            color: 'default',
            icon: <></>,
            indeterminateIcon: (
              <span
                style={{
                  position: 'absolute',
                  width: 10,
                  height: 2,
                  borderRadius: 1,
                  backgroundColor: 'currentColor',
                }}
              />
            ),
            checkedIcon: (
              <CheckmarkIcon
                fill="currentColor"
                style={{ position: 'absolute', marginTop: 1, marginLeft: 1 }}
              />
            ),
          },
          styleOverrides: {
            root: {
              backgroundColor: palette.white,
              color: palette.lightGrey,
              boxShadow: paletteUtils.boxShadowButton.secondary.default,
              width: 16,
              height: 16,
              borderRadius: 4,
              marginRight: 12,
              transition: 'none',
              '&.Mui-checked': {
                backgroundColor: palette.primary.main,
                color: palette.white,
                boxShadow: paletteUtils.boxShadowButton.primary.default,
              },
              '&.MuiCheckbox-indeterminate': {
                backgroundColor: palette.primary.main,
                color: palette.white,
                boxShadow: paletteUtils.boxShadowButton.primary.default,
              },
              '&.Mui-focusVisible': {
                boxShadow: paletteUtils.boxShadowFocus,
              },
              '&.Mui-disabled': {
                backgroundColor: palette.surface,
                color: palette.lightGrey,
                boxShadow: paletteUtils.boxShadowField.base,
              },
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              background: palette.white,
              color: palette.slate,
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderColor: palette.divider,
            },
            columnHeaders: {
              backgroundColor: palette.grey10,
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderColor: palette.divider,
            },
            columnHeaderTitle: {
              whiteSpace: 'pre-wrap',
              lineHeight: '16px',
            },
            columnHeaderTitleContainer: {
              '& .MuiDataGrid-iconButtonContainer': {
                '& .MuiBadge-badge': { display: 'none' },
              },
            },
            pinnedColumnHeaders: {
              backgroundColor: palette.grey10,
              borderBottomStyle: 'none', // there's double border if we don't clear this
              borderColor: palette.divider,
              boxShadow: paletteUtils.boxShadow,
            },
            columnSeparator: {
              transform: 'scaleY(5)',
              transformOrigin: 'center',
              // This value is set by the library, so we need !important to control it
              opacity: '0 !important',
              '&:hover, &:focus': {
                opacity: '1 !important',
              },
            },
            iconSeparator: {
              fill: palette.grey20,
            },

            pinnedColumns: {
              boxShadow: paletteUtils.boxShadow,
              backgroundColor: palette.white,
              '&& .MuiDataGrid-row': {
                paddingLeft: '0',
                paddingRight: '0',
              },
            },
            menuIconButton: {
              marginRight: '10px',
            },
            row: {
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderColor: palette.divider,
              '&.Mui-hovered, &:hover': {
                backgroundColor: palette.cobalt05,
              },
            },
            // Note: It is intentional that the cell and the row have bottom border.
            // This is because of overflow hidden on the cell that hides the row border,
            // but the row extends beyond the cell, so this is necessary.
            cell: {
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderColor: palette.divider,
              position: 'relative',
            },
            checkboxInput: {
              margin: 0,
            },
            footerContainer: {
              borderTop: 1,
              borderTopStyle: 'solid',
              borderColor: palette.divider,
            },
            footerCell: {
              color: palette.slate,
            },
            // @ts-expect-error this is from premium DataGrid
            toolbarQuickFilter: {
              // For some reason MUI offsets inputs by 4px by default :sob:
              '& input': {
                marginLeft: 0,
              },
            },
          },
        },
        MuiTimelineDot: {
          styleOverrides: {
            root: {
              boxShadow: 'none',
            },
          },
        },
      },
      typography: {
        ...typography,
        // eslint-disable-next-line @watershed/no-join-commas
        fontFamily: [
          'MessinaSans',
          'system-ui',
          '-apple-system',
          'BlinkMacSystemFont',
          'Segoe UI',
          'Roboto',
          'Oxygen-Sans',
          'Ubuntu',
          'Cantarell',
          'Helvetica',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(', '),
        fontSize: 14,
        htmlFontSize: 14,
        fontWeightMedium: 600,
        fontWeightBold: 600,
      },
      palette,
      shape: {
        borderRadius: 6,
      },
      spacing: SPACING_PX,
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          addProjectDialog: 480,
          exploreProjectGridTwoColumns: 1048,
          exploreProjectGridThreeColumns: 1448,
          exploreProjectGridFourColumns: 1832,
        },
      },
      transitions: {
        duration: {
          shortest: 100,
          shorter: 125,
          short: 175,
          standard: 250,
          complex: 375,
          enteringScreen: 225,
          leavingScreen: 195,
        },
      },
    },
    ...muiLocaleConfigs
  );
}

const theme = getTheme('en-US');

export default theme;
