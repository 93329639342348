import ChartIcon from '@watershed/icons/components/Chart';
import { useLingui } from '@lingui/react/macro';

import HomeIcon from '@watershed/icons/components/Home';
import FactoryIcon from '@watershed/icons/components/Factory';
import ReductionsIcon from '@watershed/icons/components/Reductions';
import ReportIcon from '@watershed/icons/components/Report';
import SetSquareIcon from '@watershed/icons/components/SetSquare';
import CleanPowerIcon from '@watershed/icons/components/CleanPower';
import StackIcon from '@watershed/icons/components/Stack';
import ActivityIcon from '@watershed/icons/components/Activity';
import DatasetIcon from '@watershed/icons/components/Dataset';
import EmissionFactorsIcon from '@watershed/icons/components/EmissionFactors';
import FootprintsIcon from '@watershed/icons/components/Footprints';
import ShoppingBasketIcon from '@watershed/icons/components/ShoppingBasket';
import KickoffIcon from '@watershed/icons/components/Kickoff';
import CommitmentIcon from '@watershed/icons/components/Commitment';
import ScienceIcon from '@watershed/icons/components/Science';
import {
  routeForReductions,
  routeForBenchmarks,
  routeForFootprintConfiguration,
  routeForFootprintOverview,
  routeForFootprintProductLevelEmissions,
  routeForFootprintSuppliers,
  routeForFormalReporting,
  routeForHome,
  routeForMarketplace,
  routeForMeasureProjects,
  routeForMeasurementProject,
  routeForSimulation,
  routeForEmissionsFactors,
  routeForTasks,
  routeForDatasets,
  routeForSavedViewsLandingPage,
  routeForActivityDataOverview,
  routeForMethodologies,
  routeForReferenceData,
  routeForEmissionsModels,
  routeForFootprintList,
} from '@watershed/shared-universal/dashboardRoutes';
import {
  GQFlags,
  GQPermissionType,
  GQRegion,
} from '@watershed/shared-universal/generated/graphql';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import { useMemo } from 'react';
import { useAllFeatureFlags } from '../../../utils/FeatureFlag';
import { hasPermission } from '@watershed/shared-universal/utils/permissionUtils';
import { useUserContext } from '../../../utils/UserContext';
import type { NavSectionItem, NavSectionData, SideBarVariant } from '../utils';
import { hasPermissionToManageAnySupplier } from '@watershed/shared-frontend/utils/permissions/SupplierPermissionUtils';
import {
  hasPermissionsForMeasurementProjects,
  hasPermissionsForMethodologyCustomization,
  hasPermissionsForReductionPlans,
  hasAccessToBenchmarks,
} from '../../../utils/featureAccess';
import { hasFootprintReadyForRedux } from '../../../utils/redux';
import { FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM } from '@watershed/shared-universal/footprint/constants';
import pick from 'lodash/pick';
import ReportDisclosureIcon from '@watershed/icons/components/ReportDisclosure';
import { useGetEngagementTasksTypesAndStatus } from '../../footprint/suppliers/engagementTasks/useEngagementTasks';
import { sortMeasurementProjects } from '@watershed/shared-universal/utils/measurementUtils';

/**
 * Constructs our location object from a string route, assumes the route has no origin!
 */
export function makeNavItemLocationFromRoute(
  route: string
): NavSectionItem['location'] {
  return pick(new URL(window.location.origin + route), [
    'pathname',
    'search',
    'hash',
  ]);
}

export default function useSidebarStandardVariant(): SideBarVariant {
  // We have a lot of utility functions here, so just use the hook
  // to subscribe to locale changes, and use the t macro for actual strings.
  const { t } = useLingui();
  const userContext = useUserContext();
  const featureFlags = useAllFeatureFlags();
  const tasksNavItem = useTasksNavSection();

  const navEntries = useMemo(() => {
    const { permissions } = userContext;

    const homeNavSection: NavSectionData = {
      subentries: [
        {
          icon: HomeIcon,
          name: t`Home`,
          hasPermission: hasPermission(permissions, [
            GQPermissionType.ViewFootprintDetail,
          ]),
          location: makeNavItemLocationFromRoute(routeForHome()),
        },
        tasksNavItem,
      ].filter(isNotNullish),
    };

    const measurementsNavSection: NavSectionData = {
      name: t`Measure`,
      subentries: [],
    };

    const projects =
      userContext.projects.length > 0
        ? sortMeasurementProjects(
            userContext.projects.filter((project) => project.active)
          )
        : [];
    measurementsNavSection.subentries.push({
      name: t`Measurements`,
      icon: SetSquareIcon,
      location: makeNavItemLocationFromRoute(routeForMeasureProjects()),
      disabled: !userContext.watershedPlanIncludesMeasurement,
      hasPermission: hasPermission(
        permissions,
        [GQPermissionType.ManageDatasource, GQPermissionType.ApproveDatasource],
        { allowAnyObject: true }
      ),
      menu:
        projects.length > 0
          ? {
              name: t`Active`,
              items: projects
                .map((project) => ({
                  id: project.id,
                  name: project.name,
                  location: makeNavItemLocationFromRoute(
                    routeForMeasurementProject(project.id)
                  ),
                }))
                .concat([
                  {
                    id: 'all',
                    name: t`View all`,
                    location: makeNavItemLocationFromRoute(
                      routeForMeasureProjects()
                    ),
                  },
                ]),
            }
          : undefined,
    });

    measurementsNavSection.subentries.push({
      name: t`Datasets`,
      location: makeNavItemLocationFromRoute(routeForDatasets()),
      hasPermission:
        hasPermissionsForMeasurementProjects(userContext) &&
        hasPermission(
          permissions,
          [
            GQPermissionType.ManageDatasource,
            GQPermissionType.ApproveDatasource,
          ],
          {
            allowAnyObject: true,
          }
        ),
      icon: DatasetIcon,
    });

    const isPipelinesEnabled = featureFlags.get(
      GQFlags.CalculationEnablePipelinesPrototype
    );

    const isMultipleFootprintsEnabled = featureFlags.get(
      GQFlags.EnableMultipleFootprints
    );

    if (
      featureFlags.get(GQFlags.ActivityDataPage) ||
      featureFlags.get(GQFlags.ShowActivityDataInDataReview) ||
      isPipelinesEnabled
    ) {
      measurementsNavSection.subentries.push({
        name: t`Activity data`,
        location: makeNavItemLocationFromRoute(routeForActivityDataOverview()),
        hasPermission: hasPermissionsForMeasurementProjects(userContext),
        icon: ActivityIcon,
      });
    }
    if (featureFlags.get(GQFlags.CalculationMethodologyCustomizationPage)) {
      measurementsNavSection.subentries.push({
        name: t`Emissions factors`,
        location: makeNavItemLocationFromRoute(routeForEmissionsFactors()),
        hasPermission: hasPermissionsForMethodologyCustomization(userContext),
        icon: EmissionFactorsIcon,
      });
    }

    if (isMultipleFootprintsEnabled) {
      // TODO(jenner): We may want to add a separate permissions check for this
      // when we do the permissions refactor.
      measurementsNavSection.subentries.push({
        icon: FootprintsIcon,
        name: t`Footprints`,
        location: makeNavItemLocationFromRoute(routeForFootprintList()),
        hasPermission: hasPermission(permissions, [
          GQPermissionType.ViewFootprintDetail,
        ]),
      });
    } else {
      measurementsNavSection.subentries.push({
        icon: FootprintsIcon,
        name: t`Footprints`,
        location: makeNavItemLocationFromRoute(
          routeForFootprintConfiguration()
        ),
        hasPermission: hasPermission(permissions, [
          GQPermissionType.ViewFootprintDetail,
        ]),
      });
    }

    const hasPermissionsForCalculateSection =
      hasPermissionsForMeasurementProjects(userContext) &&
      hasPermission(
        permissions,
        [GQPermissionType.ManageDatasource, GQPermissionType.ApproveDatasource],
        {
          allowAnyObject: true,
        }
      );
    const calculateNavSection: NavSectionData = {
      name: t`Calculate`,
      subentries: [
        {
          name: t`Methodologies`,
          location: makeNavItemLocationFromRoute(routeForMethodologies()),
          hasPermission: hasPermissionsForCalculateSection,
          icon: ScienceIcon,
        },
        {
          name: t`Reference data`,
          location: makeNavItemLocationFromRoute(routeForReferenceData()),
          hasPermission: hasPermissionsForCalculateSection,
          icon: ScienceIcon,
        },
        {
          name: t`Emissions models`,
          location: makeNavItemLocationFromRoute(routeForEmissionsModels()),
          hasPermission: hasPermissionsForCalculateSection,
          icon: ScienceIcon,
        },
      ],
    };

    const analyzeNavSection: NavSectionData = {
      name: t`Analyze`,
      subentries: [],
    };

    if (userContext.footprintInterval) {
      analyzeNavSection.subentries.push({
        icon: StackIcon,
        name: t`Overview`,
        location: makeNavItemLocationFromRoute(routeForFootprintOverview()),
        hasPermission: hasPermission(permissions, [
          GQPermissionType.ViewFootprintDetail,
        ]),
      });
      const drilldownSearchParams = new URLSearchParams();
      if (userContext.latestPublishedFootprintVersion) {
        drilldownSearchParams.append(
          FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM,
          userContext.latestPublishedFootprintVersion
        );
      }
      analyzeNavSection.subentries.push({
        name: t`Drilldown`,
        location: makeNavItemLocationFromRoute(routeForSavedViewsLandingPage()),
        hasPermission: hasPermission(permissions, [
          GQPermissionType.ViewFootprintDetail,
        ]),
        icon: ChartIcon,
      });
      if (featureFlags.get(GQFlags.FootprintProductLevelEmissions)) {
        analyzeNavSection.subentries.push({
          name: t`Products and materials`,
          location: makeNavItemLocationFromRoute(
            routeForFootprintProductLevelEmissions()
          ),
          hasPermission: hasPermission(permissions, [
            GQPermissionType.ViewFootprintDetail,
          ]),
          icon: ShoppingBasketIcon,
        });
      }
    }
    if (
      featureFlags.get(GQFlags.FootprintSuppliersPage) &&
      userContext.region === GQRegion.Us
    ) {
      analyzeNavSection.subentries.push({
        name: t`Supply chain`,
        disabled: !userContext.footprintInterval,
        tooltip: !userContext.footprintInterval
          ? t`You need a published footprint to use the supply chain module.`
          : undefined,
        location: makeNavItemLocationFromRoute(routeForFootprintSuppliers()),
        hasPermission:
          hasPermission(permissions, [GQPermissionType.ViewFootprintDetail]) &&
          hasPermissionToManageAnySupplier(permissions),
        icon: FactoryIcon,
      });
    }
    if (userContext.region === GQRegion.Us) {
      analyzeNavSection.subentries.push({
        name: t`Benchmarks`,
        location: makeNavItemLocationFromRoute(routeForBenchmarks()),
        hasPermission: hasAccessToBenchmarks(userContext, featureFlags),
        icon: KickoffIcon,
      });
    }

    const reportNavSection: NavSectionData = {
      name: t`Report`,
      subentries: [
        {
          icon: ReportIcon,
          name: t`Disclosures & reports`,
          location: makeNavItemLocationFromRoute(routeForFormalReporting()),
          disabled: !userContext.watershedPlanIncludesReporting,
          hasPermission: hasPermission(permissions, [
            GQPermissionType.ViewEmployeeReport,
            GQPermissionType.ViewFootprintDetail,
          ]),
        },
      ],
    } as const;

    const footprintReadyForRedux = hasFootprintReadyForRedux(
      userContext.orgFiscalYearStartMonth,
      userContext.footprintInterval
    );

    const planNavSection: NavSectionData = {
      name: t`Plan`,
      subentries: [],
    } as const;
    planNavSection.subentries.push({
      name: t`Reduction plans`,
      location: makeNavItemLocationFromRoute(routeForReductions()),
      disabled: !footprintReadyForRedux,
      tooltip: !footprintReadyForRedux
        ? t`You need a published footprint with one full year of data to use the reductions tool.`
        : undefined,
      hasPermission: hasPermissionsForReductionPlans(userContext),
      icon: ReductionsIcon,
    });
    if (featureFlags.get(GQFlags.SimulationEnableSavingScenarios)) {
      planNavSection.subentries.push({
        icon: CommitmentIcon,
        name: t`Simulations`,
        location: makeNavItemLocationFromRoute(routeForSimulation()),
        hasPermission: hasPermission(permissions, [
          GQPermissionType.ViewFootprintDetail,
        ]),
      });
    }
    planNavSection.subentries.push({
      name: t`Marketplace`,
      location: makeNavItemLocationFromRoute(routeForMarketplace()),
      icon: CleanPowerIcon,
      disabled: false,
      hasPermission: hasPermission(permissions, [
        GQPermissionType.ManageMarketplacePurchases,
      ]),
    });

    const navSections: ReadonlyArray<NavSectionData | null> = [
      homeNavSection,
      measurementsNavSection,
      isPipelinesEnabled ? calculateNavSection : null,
      analyzeNavSection.subentries.length > 0 ? analyzeNavSection : null,
      reportNavSection,
      planNavSection,
    ];

    return navSections.filter(isNotNullish);
  }, [t, userContext, featureFlags, tasksNavItem]);

  return { navEntries };
}

function useTasksNavSection(): NavSectionItem | null {
  const { t } = useLingui();
  const userContext = useUserContext();
  const { engagementTasks } = useGetEngagementTasksTypesAndStatus();

  if (
    !hasPermission(userContext.permissions, [
      GQPermissionType.ManageDisclosures,
    ])
  ) {
    return null;
  }

  if (engagementTasks.length === 0) {
    return null;
  }

  const incompleteTasks = engagementTasks.filter(
    (task) => task.submittedAt === null
  );

  return {
    name: t`Network tasks`,
    location: makeNavItemLocationFromRoute(routeForTasks()),
    hasPermission: hasPermission(userContext.permissions, [
      GQPermissionType.ManageDisclosures,
    ]),
    chip: incompleteTasks.length > 0 ? incompleteTasks.length.toString() : null,
    icon: ReportDisclosureIcon,
  };
}
