import { Cache, cacheExchange } from '@urql/exchange-graphcache';
import {
  ActiveOrganizationDocument,
  AllPortfoliosDocument,
  ClimateProgramProjectsForHomepageDocument,
  CommentDiscussionsForAnchorDocument,
  EngagementTaskContentsFragmentDoc,
  FinanceSettingsDocument,
  FinanceSnapshotsDocument,
  GetAllAssignableUsersDocument,
  GetContactsForCompanyDocument,
  GetContactsForOrgDocument,
  GetDrilldownSavedViewsDocument,
  GetEngagementCohortsDocument,
  GetSupplierAttachmentsDocument,
  GetSupplierColumnsForCustomFieldDocument,
  GetSupplierViewsDocument,
  NewMeasurementWizardDocument,
  OrgStructureVersionsDocument,
  OrgUnitTypesDocument,
  OrgUnitGridPageDocument,
  PostMeasurementVendorMatchingTableRowsDocument,
  PreMeasurementVendorMatchingTableRowsDocument,
  OrgUnitTypeDocument,
  SupplierScorecardsDocument,
  FootprintsDocument,
} from '@watershed/shared-frontend/generated/urql';
import {
  GQActiveOrganizationQuery,
  GQAllPortfoliosQuery,
  GQCreateFundMutation,
  GQCreateMarketplacePortfolioPayload,
  GQCreateUserPayload,
  GQDrilldownSavedView,
  GQDuplicateMarketplacePortfolioPayload,
  GQFrozenDatasourceSummary,
  GQGetAllAssignableUsersQuery,
  GQGetDrilldownSavedViewsQuery,
  GQIndustrySector,
  GQMutationDeletePlanArgs,
  GQMutationDeleteRoleArgs,
  GQNetZeroSummaryYear,
  GQUpdateSupplierCustomDataMutation,
  GQUpdateSupplierCustomDataMutationVariables,
  GQGetSupplierAttachmentsQuery,
  GQCreateSupplierAttachmentsMutation,
  GQCreateSupplierAttachmentsMutationVariables,
  GQDeleteSupplierAttachmentMutation,
  GQDeleteSupplierAttachmentMutationVariables,
  GQAddEngagementTaskCommentMutation,
  GQAddEngagementTaskCommentMutationVariables,
  GQSupplierViewChartOrder,
  GQFinanceSettingsQuery,
  GQDeleteFundsPayload,
  GQEvaluatedEmissionsModelsResults,
  GQSimpleTimeseries,
  GQCreatePrivateDisclosureMutation,
  GQUpdatePrivateDisclosureMutation,
  GQNewMeasurementWizardQuery,
  GQCreateClimateProgramProjectForMeasurementMutation,
  GQCreateClimateProgramProjectMutation,
  GQMutationDeletePeerCompanyGroupArgs,
  GQSimulationSwapOption,
  GQSimulationTargetingOptions,
  GQProductsAndMaterialSimulationData,
  GQSimulationEligibleFootprintIntervals,
  GQDeleteAssetsMutation,
  GQDeleteSnapshotMutation,
  GQSimulationOptionDetails,
  GQClimateProgramProjectsForHomepageQuery,
  GQDeleteClimateProgramCalendarEventPayload,
  GQDeleteClimateProgramTimelineProjectPayload,
  GQUpsertOrgCompanyForNameMutation,
  GQUpsertOrgCompanyForNameMutationVariables,
  GQCreateCompanyChangeRequestCreateMutation,
  GQCreateCompanyChangeRequestCreateMutationVariables,
  GQWithdrawCompanyChangeRequestMutation,
  GQDeleteOrgCompanyMutation,
  GQDeleteOrgCompanyMutationVariables,
  GQCreateEngagementCohortMutation,
  GQGetEngagementCohortsQuery,
  GQSetEngagementCohortsForCompanyMutation,
  GQCreateEngagementCohortMutationVariables,
  GQDeleteCompanyEngagementCohortMutation,
  GQDeleteCompanyEngagementCohortMutationVariables,
  GQCreateEngagementCohortsForCompanyMutation,
  GQCreateEngagementCohortsForCompanyMutationVariables,
  GQSetEngagementCohortAndMembersMutation,
  GQCreateAssetHoldingMutation,
  GQCreateAssetHoldingMutationVariables,
  GQDeleteAssetHoldingMutation,
  GQDeleteAssetHoldingMutationVariables,
  GQDeleteAssetHoldingsMutation,
  GQDeleteAssetHoldingsMutationVariables,
  GQCreateAssetCorporateMutation,
  GQCreateAssetRealEstateMutation,
  GQCreateAssetGroupMutation,
  GQPostMeasurementVendorMatchingTableRowsQuery,
  GQPreMeasurementVendorMatchingTableRowsQuery,
  GQUpdateOrgProfileMutation,
  GQUpdateOrgProfileMutationVariables,
  GQSetPublishEngagementTaskConfigMutation,
  GQCreateSupplierContactMutation,
  GQCreateSupplierContactMutationVariables,
  GQDeleteSupplierContactMutation,
  GQDeleteSupplierContactMutationVariables,
  GQUpdateSupplierContactMutation,
  GQUpdateSupplierContactMutationVariables,
  GQGetContactsForOrgQuery,
  GQGetContactsForCompanyQuery,
  GQMethodologyCustomizationColumnDisplayFormat,
  GQCreateFinanceTagMutation,
  GQCreateFinanceTagMutationVariables,
  GQDeleteFinanceTagMutation,
  GQDeleteFinanceTagMutationVariables,
  GQDeleteFinanceTagsMutation,
  GQDeleteFinanceTagsMutationVariables,
  GQCreateFinanceReportingTagsMutation,
  GQCreateFinanceReportingTagsMutationVariables,
  GQDeleteFinanceSavedViewPayload,
  GQFinanceSnapshotsQuery,
  GQDeleteAssetYearsMutation,
  GQDeleteAssetYearsMutationVariables,
  GQCreateSupplierSavedViewMutation,
  GQGetSupplierViewsQuery,
  GQDeleteSupplierSavedViewMutation,
  GQDeleteSupplierSavedViewMutationVariables,
  GQUpdateSupplierSavedViewMutation,
  GQUpdateSupplierSavedViewMutationVariables,
  GQCreateSupplierSavedViewMutationVariables,
  GQMethodologyCustomizationMissingSourceDataConfig,
  GQCreateDiscussionMutation,
  GQCreateDiscussionMutationVariables,
  GQCommentDiscussionsForAnchorQuery,
  GQUpdateFinanceAutomaticRemindersMutation,
  GQMethodologyCustomizationDisplaySection,
  GQMethodologyCustomizationDisplayRow,
  GQSetPreferredLocaleMutation,
  GQSetPreferredLocaleMutationVariables,
  GQUpdateFootprintReviewRequestStatusMutation,
  GQUpdateFootprintReviewRequestStatusMutationVariables,
  GQUpsertNonFootprintSupplierMutation,
  GQUpsertNonFootprintSupplierMutationVariables,
  GQDeleteNonFootprintSupplierMutation,
  GQDeleteNonFootprintSupplierMutationVariables,
  GQBusinessActivityTypeWithReleases,
  GQCheckAndGenerateFacilitiesBartsMutation,
  GQDismissDeduplicationSuggestionMutationVariables,
  GQMutationResetFootprintExclusionSuggestionsArgs,
  GQUpsertOrgStructureVersionMutation,
  GQUpsertOrgStructureVersionMutationVariables,
  GQOrgStructureVersionsQuery,
  GQCreateOrgUnitTypeMutation,
  GQCreateOrgUnitTypeMutationVariables,
  GQOrgUnitTypesQuery,
  GQCreateOrgUnitMutation,
  GQCreateOrgUnitMutationVariables,
  GQOrgUnitGridPageQuery,
  GQDeleteOrgStructureVersionMutation,
  GQDeleteOrgStructureVersionMutationVariables,
  GQDuplicateOrgStructureVersionMutation,
  GQDuplicateOrgStructureVersionMutationVariables,
  GQDeleteOrgUnitTypeMutation,
  GQDeleteOrgUnitTypeMutationVariables,
  GQCreateAssetSovereignBondMutation,
  GQCreateAssetPersonalMotorVehicleInsuranceMutation,
  GQDeleteSupplierTableColumnMutation,
  GQDeleteSupplierTableColumnMutationVariables,
  GQGetSupplierColumnsForCustomFieldQuery,
  GQDeleteOrgUnitsMutation,
  GQDeleteOrgUnitsMutationVariables,
  GQCreateOrgUnitTypeRelationshipMutation,
  GQCreateOrgUnitTypeRelationshipMutationVariables,
  GQOrgUnitTypeQuery,
  GQDeleteOrgUnitTypeRelationshipMutation,
  GQDeleteOrgUnitTypeRelationshipMutationVariables,
  GQCreateSupplierScoreCriteriaMutation,
  GQSupplierScorecardsQuery,
  GQAddDataApprovalCommentMutation,
  GQAddDataApprovalCommentMutationVariables,
  GQRequestDataApprovalMutation,
  GQRequestDataApprovalMutationVariables,
  GQApproveDataMutation,
  GQApproveDataMutationVariables,
  GQRejectDataMutation,
  GQRejectDataMutationVariables,
  GQGetJobResultsQuery,
  GQBackgroundJobState,
  GQObjectType,
  GQConfirmCandidateMutation,
  GQConfirmCandidateMutationVariables,
  GQFootprintsQuery,
  GQCreateFootprintPayload,
  GQRequestUnlockDataMutation,
  GQRequestUnlockDataMutationVariables,
  GQGetCdpWorkflowSyncStatusQuery,
} from '@watershed/shared-universal/generated/graphql';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import {
  patchSupplierInCache,
  updateSuppliersDataFragment,
} from '../components/footprint/suppliers/utils/cacheUtils';
import { createSupplierId } from '@watershed/shared-universal/utils/SuppliersUtils';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import isNullish from '@watershed/shared-universal/utils/isNullish';
import { Exchange } from 'urql';
import {
  updateDataApprovalObjectChangelogCache,
  updateDataApprovalUserUploadTaskChangelogCache,
} from '../components/measure/datasources/datasourceId/approvalFlow/utils/dataApprovalsViewUtils';
import { CompanyChangeRequestSchema } from '@watershed/shared-universal/companyChangeRequestSchemas';

type BasicIntrospectionQuery = { __schema: any };

export function invalidateAllTopLevelQueriesForNode(
  cache: Cache,
  topLevelQueryName: string
) {
  const allFields = cache.inspectFields('Query');
  const queries = allFields.filter((x) => x.fieldName === topLevelQueryName);
  for (const query of queries) {
    const { fieldName, arguments: variables } = query;
    cache.invalidate('Query', fieldName, variables || undefined);
  }
}

function invalidateDeletedRecords(
  cache: Cache,
  deletedRecords: Array<string>,
  typename: string
) {
  if (deletedRecords) {
    deletedRecords.forEach((record) => {
      cache.invalidate({
        __typename: typename,
        id: record,
      });
    });
  }
}

function patchFragmentInCache({
  cache,
  fragment,
  maybeIdToUpdate,
  patch,
}: {
  cache: Cache;
  fragment: DocumentNode;
  maybeIdToUpdate: string | undefined;
  patch: (current: any) => any;
}) {
  if (!maybeIdToUpdate) {
    return;
  }

  const currentValue = cache.readFragment(fragment, {
    id: maybeIdToUpdate,
  });

  if (!currentValue) {
    return;
  }
  cache.writeFragment(fragment, patch(currentValue));
}

const FundList = gql`
  query FundList {
    funds {
      __typename
      id
    }
  }
`;
const AssetCorporateList = gql`
  query AssetCorporateList {
    assetsCorporate {
      id
    }
  }
`;
const AssetRealEstateList = gql`
  query AssetRealEstateList {
    assetsRealEstate {
      id
    }
  }
`;
const AssetGroupList = gql`
  query AssetGroupList {
    assetGroups {
      id
    }
  }
`;
const AssetSovereignBondList = gql`
  query AssetSovereignBondList {
    assetSovereignBonds {
      id
    }
  }
`;
const AssetPersonalMotorVehicleInsuranceList = gql`
  query AssetPersonalMotorVehicleInsuranceList {
    assetPersonalMotorVehicleInsurances {
      id
    }
  }
`;

/**
 * Builds a cache exchange for the given schema.
 *
 * Accepts a callback for when the locale changes as a temporary measure to
 * ensure the locale header for subsequent queries is set immediately -- i.e.
 * doesn't wait for a state change in the locale atom to be committed. Once some
 * teething issues with our GQL setup are resolved (we've migrated to using
 * suspense, and urql provides a way to tell when cache invalidations are
 * settled), we should be able to remove this awkward callback behaviour.
 */
const cache: (
  schema: BasicIntrospectionQuery,
  onSetLocale: (locale: string) => void
) => Exchange = (schema, onSetLocale = () => {}) =>
  cacheExchange({
    keys: {
      // Returning null means graphcache will skip normalizing these types.
      // See https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/
      FacilitiesMetadata: () => null,
      Footprint: () => null,
      FootprintSummary: () => null,
      FootprintAnalysisTimeSeriesDataPoint: () => null,
      FootprintAnalysisTimeseriesSeries: () => null,
      FootprintAnalysisTopVendors: () => null,
      FootprintAnalysisTopVendor: () => null,
      FootprintAnalysisTopVendorsSummarySentences: () => null,
      TimeseriesForGroup: () => null,
      FootprintSummaryGroups: () => null,
      CustomIntensityDataPoint: () => null,
      Vendor: () => null,
      FootprintAnalysis: () => null,
      ConversionChainItemForDashboard: () => null,
      ConversionFactorForDashboard: () => null,
      CalculationChain: () => null,
      BusinessActivityTypeWithReleases: (initialData) => {
        const data = initialData as GQBusinessActivityTypeWithReleases;
        return data.businessActivityType;
      },
      BiSavedViewSnapshotQueryContext: () => null,
      BiDynamicNormalizingMeasure: () => null,
      BiDynamicNormalizingQuery: () => null,
      BiQueryFilter: () => null,
      TraceDetails: () => null,
      CalculationDataLineagePayload: () => null,
      UutRowsDataLineage: () => null,
      FileDataLineage: () => null,
      FilterExpressionGroup: () => null,
      FilterExpressionPrimitive: () => null,
      FilterExpressionGroupWithNewFilters: () => null,
      FilterExpressionPrimitiveWithNewFilters: () => null,
      IntensityDenominatorDataPoint: () => null,
      IntensityDenominatorRecord: () => null,
      FootprintBridgeData: () => null,
      PlanVariables: () => null,
      PlanCarbonFund: () => null,

      FootprintChangelog: () => null,

      EnergyConsumptionBreakdown: () => null,
      EnergyConsumptionDataPoint: () => null,
      FootprintFieldValue: () => null,

      BenchmarkBreakdownDataPoint: () => null,
      BenchmarkScopeBreakdownDataPoint: () => null,
      NetZeroSummary: () => null,
      NetZeroSummaryYear: (initialData) => {
        const data = initialData as unknown as GQNetZeroSummaryYear;
        return `${data.year}-${data.kind}`;
      },
      MethodologyCustomizationColumnDisplayFormat: (initialData) => {
        const data =
          initialData as GQMethodologyCustomizationColumnDisplayFormat;
        return data.parquetColumnName;
      },
      MethodologyCustomizationMissingSourceDataConfig: (initialData) => {
        const data =
          initialData as GQMethodologyCustomizationMissingSourceDataConfig;
        return data.message;
      },
      MethodologyCustomizationDisplaySection: (initialData) => {
        const data = initialData as GQMethodologyCustomizationDisplaySection;
        return data.sectionName;
      },
      MethodologyCustomizationDisplayRow: (initialData) => {
        // Dependent on both the customization type and the mapping config ids, but not type config, but mapping config isn't always requested
        const data = initialData as GQMethodologyCustomizationDisplayRow;
        return `${data.customizationTypeId}-${data.mappingConfigIds?.join(
          '-'
        )}`;
      },
      EmployeeReport: () => null,
      EmployeeReportArchetypePurchase: () => null,
      CategorizedEmissionData: () => null,
      SimpleTimeseries: (data) =>
        (data as Partial<GQSimpleTimeseries>).id ?? null,
      MarkdownCardInfo: () => null,
      ClimateProgramLifecycle: () => null,
      ClimateProgramInfo: () => null,
      ClimateProgramInfoStep: () => null,
      ClimateProgramInfoTarget: () => null,
      ReductionTargetsInfo: () => null,
      ReductionTargetsInfoItem: () => null,
      DatasetSummary: () => null,
      DatasourceSummary: () => null,
      Disclosure: () => null,
      ReportQuestionSeriesMetricItem: () => null,
      PeerExternalReportAnswersForIdentifiersPayload: () => null,
      UserUploadedTableSchemaColumn: () => null,
      UserUploadedTableDataPreview: () => null,
      UserUploadedTableRoboCleanerResult: () => null,
      UserUploadedTableUserReviewMetadata: () => null,
      ColumnMapping: () => null,
      UserUploadedTableCell: () => null,
      YearMonthInterval: () => null,
      UserRoleAssignment: () => null,
      QuantityTrendAnomaly: () => null,
      FinancialsAccount: () => null,
      UserUploadedTableParseConfig: () => null,
      CanonicalSchemaExampleRowFields: () => null,
      CanonicalSchemaExampleData: () => null,
      IngestionQuestionAnswer: () => null,
      IngestionQuestionResponse: () => null,
      IngestionQuestionOption: () => null,
      IngestionExampleDataRow: () => null,
      IngestionExampleDataColumn: () => null,
      IngestionExampleDataRowField: () => null,
      AdditionalQuestionResponse: () => null,
      MeasurementProjectDatasetProgress: () => null,

      FinanceSectorEmissions: () => null,
      FinanceAggregateMetric: () => null,
      FootprintExclusionRuleTargetedEmissions: () => null,
      ForecastPeriod: () => null,
      ForecastPeriodPoint: () => null,
      FrozenDatasourceSummary: (data) =>
        (data as GQFrozenDatasourceSummary).signedUrl,
      CurrencyOverride: () => null,
      SerializableError: () => null,
      Timeseries: () => null,
      LabeledTimeseries: () => null,
      SuppliersWithInterval: () => null,
      SupplierTable: () => null,
      SupplierViewColumnOrder: () => null,
      SupplierViewChartOrder: (data) =>
        (data as GQSupplierViewChartOrder).chartId,
      SupplierHistoricalEmissions: () => null,
      SuppliersSettings: () => 'suppliersSettings', // Only one instance
      SurveyDefinitionAndStatus: () => null,
      UserInputtedForecastPeriodPoint: () => null,
      FootprintEstimateOutputByScope: () => null,
      FinanceView: () => null,
      IndustrySector: (data) => (data as GQIndustrySector).sectorCode,
      InstructionsBundle: () => null,
      DependentReportItems: () => null,
      ResolvedReportQuestionInputsSuccess: () => null,
      ResolvedReportQuestionInputsFailure: () => null,
      RenewableEnergyPercentagePoint: () => null,
      InstructionsStep: () => null,
      TabularData: () => null,
      InputQuantityByGroup: () => null,
      MaterialFootprintRowUpdatedTaxonomy: () => null,
      ProductFootprintRowUpdatedTaxonomy: () => null,
      EvaluatedEmissionsModelsResults: (data) => {
        // Combine keys to approximate a unique key for the whole object
        const results = data as GQEvaluatedEmissionsModelsResults;
        const versionIds = results.emissionsModelVersions
          .map((v) => v.id)
          .sort();
        return versionIds.join('-');
      },

      SimulationSwapOption: (data) => {
        const swapOption = data as GQSimulationSwapOption;
        return `${
          swapOption.sourceCategory
        }->${swapOption.targetCategories.join('-')}`;
      },

      ProductsAndMaterialSimulationData: (data) => {
        const simulationData = data as GQProductsAndMaterialSimulationData;
        return simulationData.products.map((p) => p.id).join('-');
      },

      SimulationTargetingOptions: (data) => {
        const targetingOptions = data as GQSimulationTargetingOptions;
        return `${targetingOptions.categoryLabel}-${
          targetingOptions.field
        }-${targetingOptions.options.join('-')}`;
      },

      /**
       * Cost, emissions, and assumptions data all could make two
       * choices unique. To avoid having to check every one of the
       * nested fields without any id here, we don't cache this.
       */
      SimulationSwapOverlayData: () => null,

      /**
       * Cost, emissions, and assumptions data all could make two
       * choices unique. To avoid having to check every one of the
       * nested fields without any id here, we don't cache this.
       */
      SimulationSwapOverlay: () => null,

      SimulationCostPerUnitOverride: () => null,

      SimulationEligibleFootprintIntervals: (data) => {
        const typedData = data as GQSimulationEligibleFootprintIntervals;
        return typedData.intervals.flatMap((i) => [i.start, i.end]).join('-');
      },
      SimulationOptionDetails: (data) => {
        const optionDetails = data as GQSimulationOptionDetails;
        return optionDetails.category;
      },

      DatasetWithStage: () => null, // the IDs here aren't unique, and this isn't a type we update in the cache

      VectorNameAndValues: () => null,

      DrilldownForReportQuestionPayload: () => null,
      CustomReportControlsPayload: () => null,
      ReportQuestionSingleValueMetric: () => null,
      ReportQuestionSeriesMetric: () => null,
      CdpLatestSyncWorkflowStatusPayload: () => null,

      TraceEmissionsModelVariableDetails: () => null,
      StringKeyValue: () => null,
      FootprintAnalysisCustomIntensityDataPoint: () => null,
      ExampleTracesForFingerprints: () => null,
      EmissionsModelVariableDetails: () => null,
      BartColumnDetails: () => null,
      ReferenceValueDetails: () => null,
      TraceReferenceValueCitationDetails: () => null,
      TraceReferenceValueCitationDetailsOrg: () => null,
      OneSchemaJwtPayload: () => null,
      FootprintGenerationInfo: () => null,
      SdiInstructionsStep: () => null,
      SdiInstructionsFaqType: () => null,
      CadtBuildingsPushPreview: () => null,
      ParquetColumn: () => null,
      UtilitiesMetadataByType: () => null,

      FinanceSnapshotAsset: () => null,
      FinanceMeasurementProgressRow: () => null,
      MeasuredFootprintDetails: () => null,
      GridColumnConfigurationSingleSelect: () => null,
      DataGridSortItem: () => null,
      FinanceHeader: () => null,

      // #region BI types without ids.

      BiDimensionListOptionItem: () => null,
      BiDimensionMeta: () => null,
      BiDisplayValueMapping: () => null,
      BiMeasureMeta: () => null,
      BiFieldBooleanMeta: () => null,
      BiFieldStringMeta: () => null,
      BiFieldDateMeta: () => null,
      BiFieldYearMonthMeta: () => null,
      BiFieldNumberMeta: () => null,
      ActivityContextMetadata: () => null,

      // #endregion
    },
    updates: {
      Query: {
        backgroundJob: (result: GQGetJobResultsQuery, _args, cache, _info) => {
          // Invalidate things here that need to be invalidated after some background job finishes
          if (result?.job?.state !== GQBackgroundJobState.Done) {
            return;
          }
          switch (result?.job?.kind) {
            case 'processFileUploadWorkflow':
            case 'checkAndGenerateFacilitiesBartsWorkflow':
            case 'pushUtilitiesDiffWorkflow': {
              invalidateAllTopLevelQueriesForNode(cache, 'biQueryMetadata');
              invalidateAllTopLevelQueriesForNode(cache, 'biQueryData');
              invalidateAllTopLevelQueriesForNode(cache, 'facilities');
              invalidateAllTopLevelQueriesForNode(
                cache,
                'buildingWithUtilities'
              );
              break;
            }
            case 'pushBartBuildingsWorkflow': {
              invalidateAllTopLevelQueriesForNode(cache, 'facilities');
              break;
            }
          }
        },
        cdpSyncWorkflowStatus: (
          result: GQGetCdpWorkflowSyncStatusQuery,
          _args,
          cache,
          _info
        ) => {
          for (const questionSyncStatus of result.cdpSyncWorkflowStatus
            .questions) {
            cache.invalidate('Query', 'cdpSyncQuestionStatusForReport', {
              reportId: questionSyncStatus.reportId,
            });
          }
        },
      },
      Mutation: {
        archiveReport: (result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'formalReports');
          cache.invalidate('Query', 'climateProgramTimelineProjects');
        },
        bulkStartCompanySurveyEngagement: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'engagementTaskBatches');
          invalidateAllTopLevelQueriesForNode(cache, 'portcoEngagementTasks');
          invalidateAllTopLevelQueriesForNode(cache, 'engagementTasks');
        },
        createCdpApiRegistration: (result, _args, cache, _info) => {
          cache.invalidate('Query', 'cdpApiRegistrationForReport');
        },
        deleteCdpApiRegistration: (result, _args, cache, _info) => {
          cache.invalidate('Query', 'cdpApiRegistrationForReport');
        },
        createReportWithConfig: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'formalReports');
          cache.invalidate('Query', 'climateProgramTimelineProjects');
        },
        createReportWithCustomConfig: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'formalReports');
          cache.invalidate('Query', 'climateProgramTimelineProjects');
        },
        startCdpSyncAnswers: (_result, _args, cache, _info) => {
          cache.invalidate('Query', 'cdpLatestSyncWorkflowStatus');
        },
        upsertCompanyReportingFacts(_result, _args, cache, _info) {
          // Because this _could_ be a create, we want to invalidate the fetch
          // node.
          invalidateAllTopLevelQueriesForNode(cache, 'companyReportingFacts');
          invalidateAllTopLevelQueriesForNode(cache, 'recommendedReportKinds');
        },
        deleteEngagementTaskConfig(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'engagementTaskConfigs');
        },
        createPeerCompanyGroup: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'peerCompanyGroups');
        },
        createMeasurementProjectFromWizard: (result, args, cache, info) => {
          // First evict the current measurement project if present
          cache.invalidate('Query', 'activeMeasurementProjects');
          cache.invalidate('Query', 'climateProgramTimelineProjects');

          // Update the activeOrganization query, so the new measurement project shows up in the sidebar.
          cache.updateQuery(
            { query: ActiveOrganizationDocument, variables: {} },
            (data) => {
              if (data !== null) {
                // @ts-expect-error
                data.activeOrganization.measurementProjects.push(
                  // @ts-expect-error
                  result.createMeasurementProjectFromWizard.measurementProject
                );
                return data;
              } else {
                return null;
              }
            }
          );
        },
        updateOrgProfile: (
          result: GQUpdateOrgProfileMutation,
          args: GQUpdateOrgProfileMutationVariables,
          cache,
          info
        ) => {
          if (
            !info.error &&
            result.updateOrgProfile.orgProfile.completedAt &&
            args.input.isComplete
          ) {
            // Fix for CP-2324. We try to avoid overfetching for forceRedirect but that means
            // we can have a stale value. Specifically, during the onboarding flow we complete
            // onboarding which changes the forceRedirect value for /home from
            // /onboarding/company-metadata to null.
            invalidateAllTopLevelQueriesForNode(cache, 'forceRedirect');
          }
        },
        createClimateProgramProject: (
          result:
            | GQCreateClimateProgramProjectMutation
            | GQCreateClimateProgramProjectForMeasurementMutation,
          args,
          cache,
          info
        ) => {
          // in the new measurement wizard, the next step renders immediately.
          // the data needs to be there to avoid a gimmicky refetch. this will
          // update the cache for all users of the `climateProgramProjects`
          // field on `Query`, so it also works for the homepage.
          const payload =
            result.createClimateProgramProject.climateProgramProject;
          if (payload && 'datasetRequirements' in payload) {
            cache.updateQuery(
              {
                query: NewMeasurementWizardDocument,
              },
              (data: GQNewMeasurementWizardQuery | null) => {
                if (data?.climateProgramProjects) {
                  data.climateProgramProjects.push({
                    ...payload,
                    __typename: 'ClimateProgramProject',
                  });
                }
                return data;
              }
            );
          } else {
            cache.invalidate('Query', 'climateProgramTimelineProjects');
            invalidateAllTopLevelQueriesForNode(
              cache,
              'climateProgramProjects'
            );
          }
        },
        deleteClimateProgramTimelineProject: (result, args, cache, info) => {
          const payload =
            result.deleteClimateProgramTimelineProject as GQDeleteClimateProgramTimelineProjectPayload | null;
          cache.updateQuery(
            {
              query: ClimateProgramProjectsForHomepageDocument,
            },
            (data: GQClimateProgramProjectsForHomepageQuery | null) => {
              if (!data || !data.climateProgramTimelineProjects) {
                return data;
              }
              data.climateProgramTimelineProjects =
                data.climateProgramTimelineProjects.filter(
                  (cptp) => cptp.id !== payload?.id
                );
              return data;
            }
          );
        },
        deleteClimateProgramCalendarEvent: (result, _args, cache, _info) => {
          const payload =
            result.deleteClimateProgramCalendarEvent as GQDeleteClimateProgramCalendarEventPayload | null;
          cache.updateQuery(
            {
              query: ClimateProgramProjectsForHomepageDocument,
            },
            (data: GQClimateProgramProjectsForHomepageQuery | null) => {
              if (!data || !data.climateProgramCalendarEvents) {
                return data;
              }
              data.climateProgramCalendarEvents =
                data.climateProgramCalendarEvents.filter(
                  (cpp) => cpp.id !== payload?.id
                );
              return data;
            }
          );
        },
        createClimateProgramCalendarEvent: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'climateProgramCalendarEvents'
          );
        },
        createRole: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'roles');
        },
        deleteRole: (result, args, cache, info) => {
          cache.invalidate({
            __typename: 'Role',
            id: (args as GQMutationDeleteRoleArgs).input.roleId,
          });
        },
        deletePeerCompanyGroup: (result, args, cache, info) => {
          cache.invalidate({
            __typename: 'PeerCompanyGroup',
            id: (args as GQMutationDeletePeerCompanyGroupArgs).input.id,
          });
        },
        createPlanWithDefaults: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'plans');
        },
        duplicatePlan: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'plans');

          // Ideally, we'd update the activeOrganization.plans query, so the new
          // plan shows up in the sidebar.  But there is an URQL bug where if
          // NODE_ENV=production, calling cache.updateQuery to update the
          // activeOrganization.plans causes it to null out the result.plan.
        },
        deletePlan: (result, args, cache, info) => {
          cache.invalidate({
            __typename: 'Plan',
            id: (args as GQMutationDeletePlanArgs).input.planId,
          });
        },
        createDrilldownSavedView: (result, args, cache, info) => {
          const payload =
            result.createDrilldownSavedView as GQDrilldownSavedView | null;
          if (payload) {
            cache.updateQuery(
              {
                query: GetDrilldownSavedViewsDocument,
              },
              (data: GQGetDrilldownSavedViewsQuery | null) => {
                if (data?.drilldownSavedViews) {
                  data.drilldownSavedViews.push(payload);
                }
                return data;
              }
            );
          }
        },
        deleteDrilldownSavedView: (result, args, cache, info) => {
          cache.invalidate({
            __typename: 'DrilldownSavedView',
            id: (result.deleteDrilldownSavedView as GQDrilldownSavedView).id,
          });
        },
        createBiSavedView: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biSavedViews');
        },
        createBiSavedViewAndPropagateToCustomReports: (
          result,
          args,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biSavedViews');
          invalidateAllTopLevelQueriesForNode(cache, 'reportQuestion');
          invalidateAllTopLevelQueriesForNode(cache, 'formalReport');
        },
        updateBiSavedView: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biSavedViews');
          invalidateAllTopLevelQueriesForNode(cache, 'reportQuestion');
          invalidateAllTopLevelQueriesForNode(cache, 'formalReport');
        },
        restoreDeletedSavedView: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biSavedViews');
          invalidateAllTopLevelQueriesForNode(cache, 'reportQuestion');
          invalidateAllTopLevelQueriesForNode(cache, 'formalReport');
        },
        renameBiSavedView: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biSavedViews');
          invalidateAllTopLevelQueriesForNode(cache, 'reportQuestion');
          invalidateAllTopLevelQueriesForNode(cache, 'formalReport');
        },
        deleteBiSavedView: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biSavedViews');
          invalidateAllTopLevelQueriesForNode(cache, 'reportQuestion');
          invalidateAllTopLevelQueriesForNode(cache, 'formalReport');
        },
        createBiCustomMetric: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biCustomMetrics');
        },
        updateBiCustomMetric: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biCustomMetrics');
        },
        deleteBiCustomMetric: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'biCustomMetrics');
        },
        createReportFileAttachments: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'reportAttachmentItems');
        },
        createReportURLAttachmentItem: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'reportAttachmentItems');
        },
        deleteReportAttachmentItem: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'reportAttachmentItems');
        },
        deleteReportAttachment: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'reportAttachmentItemsForQuestion'
          );
        },
        createReportAttachments: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'reportAttachmentItemsForQuestion'
          );
          invalidateAllTopLevelQueriesForNode(cache, 'reportAttachmentItems');
        },
        // TODO: After we move createUser() mutation to TS, we can add back the
        // `org { users { ... } }` return node, which will allow urql to update
        // the users cache itself.
        createUser(result, args, cache, info) {
          const payload = result.createUser as GQCreateUserPayload | null;

          if (payload) {
            cache.updateQuery(
              {
                query: GetAllAssignableUsersDocument,
              },
              (data) => {
                if (data !== null) {
                  (
                    data as GQGetAllAssignableUsersQuery
                  ).organization?.users?.edges.push({
                    __typename: 'UserEdge',
                    node: payload.user,
                  });
                  return data;
                }
                return null;
              }
            );
          }
        },
        createMarketplacePortfolio: (result, args, cache, info) => {
          const payload =
            result.createMarketplacePortfolio as GQCreateMarketplacePortfolioPayload | null;

          if (payload) {
            cache.updateQuery(
              {
                query: AllPortfoliosDocument,
              },
              (data) => {
                if (data !== null) {
                  (
                    data as GQAllPortfoliosQuery
                  ).marketplacePortfolios?.edges.push({
                    __typename: 'MarketplacePortfolioEdge',
                    node: payload.portfolio,
                  });
                  return data;
                }
                return null;
              }
            );
          }
        },
        duplicateMarketplacePortfolio: (result, args, cache, info) => {
          const payload =
            result.duplicateMarketplacePortfolio as GQDuplicateMarketplacePortfolioPayload | null;

          if (payload) {
            cache.updateQuery(
              {
                query: AllPortfoliosDocument,
              },
              (data) => {
                if (data !== null) {
                  (
                    data as GQAllPortfoliosQuery
                  ).marketplacePortfolios?.edges.push({
                    __typename: 'MarketplacePortfolioEdge',
                    node: payload.portfolio,
                  });
                  return data;
                }
                return null;
              }
            );
          }
        },
        deleteMarketplacePortfolio: (result, args, cache, info) => {
          cache.invalidate('Query', 'marketplacePortfolios');
        },

        createFootprint: (result, args, cache, info) => {
          const payload =
            result.createFootprint as GQCreateFootprintPayload | null;
          if (!payload) return;

          cache.updateQuery(
            { query: FootprintsDocument },
            (data: GQFootprintsQuery | null) => {
              if (data === null) return null;

              data.footprints.edges.unshift({
                __typename: 'FootprintEdge',
                node: payload.footprint,
              });

              return data;
            }
          );
        },

        ///////////////////////////////////////////////////////////////////////
        // Win Finance (start)
        ///////////////////////////////////////////////////////////////////////
        createSnapshot: (result, args, cache, info) => {
          cache.updateQuery(
            { query: FinanceSnapshotsDocument, variables: {} },
            (data: GQFinanceSnapshotsQuery | null) => {
              // @ts-expect-error
              if (data !== null && result && result.createSnapshot?.snapshot) {
                data.financeSnapshots?.push(
                  // @ts-expect-error
                  result.createSnapshot.snapshot
                );
                return data;
              } else {
                return null;
              }
            }
          );
        },
        deleteSnapshot: (
          result: GQDeleteSnapshotMutation,
          args,
          cache,
          info
        ) => {
          cache.updateQuery(
            { query: FinanceSnapshotsDocument, variables: {} },
            (data: GQFinanceSnapshotsQuery | null) => {
              if (data !== null && result && result.deleteSnapshot?.id) {
                const existingRecords = data.financeSnapshots;
                if (!existingRecords || !Array.isArray(existingRecords))
                  return null;
                const deletedRecordId = result.deleteSnapshot.id;
                if (deletedRecordId) {
                  const index = existingRecords.findIndex(
                    (record) => record.id === deletedRecordId
                  );
                  if (index !== -1) {
                    existingRecords.splice(index, 1);
                  }
                }
                return data;
              } else {
                return null;
              }
            }
          );
        },
        createFinanceSavedView: (result, args, cache, info) => {
          cache.updateQuery(
            { query: ActiveOrganizationDocument, variables: {} },
            (data: GQActiveOrganizationQuery | null) => {
              if (data !== null && result && result.createFinanceSavedView) {
                data.activeOrganization.financeSavedViews?.push(
                  // @ts-expect-error
                  result.createFinanceSavedView.financeSavedView
                );
                return data;
              } else {
                return null;
              }
            }
          );
        },
        updateFinanceSavedView: (result, args, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'financeHoldingView');
          invalidateAllTopLevelQueriesForNode(cache, 'financeView');
        },
        deleteFinanceSavedView: (result, args, cache, info) => {
          const payload =
            result.deleteFinanceSavedView as GQDeleteFinanceSavedViewPayload | null;
          cache.updateQuery(
            { query: ActiveOrganizationDocument, variables: {} },
            (data: GQActiveOrganizationQuery | null) => {
              if (!data || !data.activeOrganization.financeSavedViews) {
                return data;
              }
              data.activeOrganization.financeSavedViews =
                data.activeOrganization.financeSavedViews.filter(
                  (fsv) => fsv.id !== payload?.id
                );
              return data;
            }
          );
        },
        createFund: (result: GQCreateFundMutation, args: any, cache, _info) => {
          const payload = result.createFund;

          if (payload) {
            cache.updateQuery({ query: FundList }, (data) => {
              const existingRecords = data?.funds;
              if (!existingRecords || !Array.isArray(existingRecords))
                return null;
              const newRecord = payload.fund;
              if (newRecord) {
                existingRecords.push(newRecord);
              }
              return data;
            });

            cache.updateQuery(
              {
                query: ActiveOrganizationDocument,
                variables: {},
              },
              (data) => {
                if (data == null) return null;
                (
                  data as GQActiveOrganizationQuery
                ).activeOrganization?.funds?.push(payload.fund);
                return data;
              }
            );
            cache.updateQuery(
              {
                query: FinanceSettingsDocument,
                variables: {},
              },
              (data) => {
                if (data == null) return null;
                (data as GQFinanceSettingsQuery).funds?.push(payload.fund);
                return data;
              }
            );
          }
        },
        deleteFunds: (
          result: { deleteFunds: GQDeleteFundsPayload },
          args: any,
          cache,
          _info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          const { ids } = result.deleteFunds;
          cache.updateQuery({ query: FundList }, (data) => {
            if (!data?.funds || !Array.isArray(data.funds)) return null;
            data.funds = data.funds.filter((fund) => !ids.includes(fund.id));
            return data;
          });
          cache.updateQuery(
            {
              query: ActiveOrganizationDocument,
              variables: {},
            },
            (data) => {
              if (
                data == null ||
                !(data as GQActiveOrganizationQuery).activeOrganization?.funds
              )
                return null;
              (data as GQActiveOrganizationQuery).activeOrganization.funds =
                (
                  data as GQActiveOrganizationQuery
                ).activeOrganization?.funds?.filter(
                  (fund) => !ids.includes(fund.id)
                ) || [];

              return data;
            }
          );
          cache.updateQuery(
            {
              query: FinanceSettingsDocument,
              variables: {},
            },
            (data) => {
              if (data == null) return null;
              data.funds = (data as GQFinanceSettingsQuery).funds.filter(
                (fund) => !ids.includes(fund.id)
              );
              return data;
            }
          );
        },
        createAssetCorporate: (
          result: GQCreateAssetCorporateMutation,
          _args: any,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          cache.updateQuery({ query: AssetCorporateList }, (data) => {
            const existingRecords = data?.assetsCorporate;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const newAsset = result.createAssetCorporate?.asset;
            if (newAsset) {
              existingRecords.push(newAsset);
            }
            return data;
          });

          if (result.createAssetCorporate?.asset?.assetGroupId) {
            cache.invalidate({
              __typename: 'AssetGroup',
              id: result.createAssetCorporate.asset.assetGroupId,
            });
          }
        },
        updateAssetCorporate: (result: any, args: any, cache, info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');
        },
        createAssetRealEstate: (
          result: GQCreateAssetRealEstateMutation,
          args: any,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          cache.updateQuery({ query: AssetRealEstateList }, (data) => {
            const existingRecords = data?.assetsRealEstate;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const newAsset = result.createAssetRealEstate?.asset;
            if (newAsset) {
              existingRecords.push(newAsset);
            }
            return data;
          });
          if (result.createAssetRealEstate?.asset?.assetGroupId) {
            cache.invalidate({
              __typename: 'AssetGroup',
              id: result.createAssetRealEstate.asset.assetGroupId,
            });
          }
        },
        updateAssetRealEstate: (result: any, args: any, cache, info) => {
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');
        },
        createAssetGroup: (
          result: GQCreateAssetGroupMutation,
          _args: unknown,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          cache.updateQuery({ query: AssetGroupList }, (data) => {
            const existingRecords = data?.assetGroups;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const newAsset = result.createAssetGroup?.asset;
            if (newAsset) {
              existingRecords.push(newAsset);
            }
            return data;
          });
        },
        updateAssetGroup: (result: any, args: any, cache, info) => {
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');
        },
        createAssetSovereignBond: (
          result: GQCreateAssetSovereignBondMutation,
          _args,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          cache.updateQuery({ query: AssetSovereignBondList }, (data) => {
            const existingRecords = data?.assetSovereignBond;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const newAsset = result.createAssetSovereignBond?.asset;
            if (newAsset) {
              existingRecords.push(newAsset);
            }
            return data;
          });
        },
        updateAssetSovereignBond: (result: any, args: any, cache, info) => {
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');
        },
        createAssetPersonalMotorVehicleInsurance: (
          result: GQCreateAssetPersonalMotorVehicleInsuranceMutation,
          _args,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          cache.updateQuery(
            { query: AssetPersonalMotorVehicleInsuranceList },
            (data) => {
              const existingRecords = data?.assetPersonalMotorVehicleInsurance;
              if (!existingRecords || !Array.isArray(existingRecords))
                return null;
              const newAsset =
                result.createAssetPersonalMotorVehicleInsurance?.asset;
              if (newAsset) {
                existingRecords.push(newAsset);
              }
              return data;
            }
          );
        },
        updateAssetPersonalMotorVehicleInsurance: (
          result: any,
          args: any,
          cache,
          info
        ) => {
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');
        },
        deleteAssets(result: GQDeleteAssetsMutation, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.assetCorporateIds,
            'AssetCorporate'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.assetRealEstateIds,
            'AssetRealEstate'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.assetGroupIds,
            'AssetGroup'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.assetSovereignBondIds,
            'AssetSovereignBond'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.assetPersonalMotorVehicleInsuranceIds,
            'AssetPersonalMotorVehicleInsurance'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.assetYearIds,
            'FinanceAssetYear'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.assetHoldingIds,
            'FinanceAssetHolding'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.privateDisclosureIds,
            'PrivateDisclosure'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.emissionsYearIds,
            'EmissionsYear'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssets?.footprintEstimateOutputIds,
            'FootprintEstimateOutput'
          );

          //couldnt find FundHoldingBenchmark in cache
        },
        createFinanceTag: (
          result: GQCreateFinanceTagMutation,
          args: GQCreateFinanceTagMutationVariables,
          cache,
          info
        ) => {
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');

          const FinanceTagList = gql`
            query FinanceTagList {
              financeTags {
                id
              }
            }
          `;
          cache.updateQuery({ query: FinanceTagList }, (data) => {
            const existingRecords = data?.financeTags;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const newRecord = result.createFinanceTag?.financeTag;
            if (newRecord) {
              existingRecords.push(newRecord);
            }
            return data;
          });
        },
        deleteFinanceTag: (
          result: GQDeleteFinanceTagMutation,
          args: GQDeleteFinanceTagMutationVariables,
          cache,
          info
        ) => {
          if (info.error) return;
          const FinanceTagList = gql`
            query FinanceTagList {
              financeTags {
                id
              }
            }
          `;
          cache.updateQuery({ query: FinanceTagList }, (data) => {
            const existingRecords = data?.financeTags;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const deletedRecordId = result.deleteFinanceTag.id;
            if (deletedRecordId) {
              const index = existingRecords.findIndex(
                (record) => record.id === deletedRecordId
              );
              if (index !== -1) {
                existingRecords.splice(index, 1);
              }
            }
            return data;
          });
        },
        deleteFinanceTags: (
          result: GQDeleteFinanceTagsMutation,
          args: GQDeleteFinanceTagsMutationVariables,
          cache,
          info
        ) => {
          if (info.error) return;
          invalidateDeletedRecords(
            cache,
            result.deleteFinanceTags.ids,
            'FinanceTag'
          );
        },
        createFinanceReportingTags: (
          result: GQCreateFinanceReportingTagsMutation,
          args: GQCreateFinanceReportingTagsMutationVariables,
          cache,
          info
        ) => {
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');

          const FinanceTagList = gql`
            query FinanceTagList {
              financeTags {
                id
              }
            }
          `;
          cache.updateQuery({ query: FinanceTagList }, (data) => {
            const existingRecords = data?.financeTags;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const newRecords = result.createFinanceReportingTags?.financeTags;
            if (newRecords) {
              existingRecords.push(...newRecords);
            }
            return data;
          });
        },
        createAssetYear: (result: any, args: any, cache, info) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');
        },
        updateAssetYear: (result: any, args: any, cache, info) => {
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');
        },
        deleteAssetYears: (
          result: GQDeleteAssetYearsMutation,
          args: GQDeleteAssetYearsMutationVariables,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          invalidateDeletedRecords(
            cache,
            result.deleteAssetYears?.assetYearIds,
            'FinanceAssetYearNew'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssetYears?.assetHoldingIds,
            'FinanceAssetHolding'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssetYears?.privateDisclosureIds,
            'PrivateDisclosure'
          );
          invalidateDeletedRecords(
            cache,
            result.deleteAssetYears?.emissionsYearIds,
            'EmissionsYear'
          );
        },
        createAssetHolding: (
          result: GQCreateAssetHoldingMutation,
          args: GQCreateAssetHoldingMutationVariables,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');

          const AssetHoldingList = gql`
            query AssetHoldingList {
              assetHoldings {
                id
              }
            }
          `;
          cache.updateQuery({ query: AssetHoldingList }, (data) => {
            const existingRecords = data?.assetHoldings;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const newRecord = result.createAssetHolding?.assetHolding;
            if (newRecord) {
              existingRecords.push(newRecord);
            }
            return data;
          });
        },
        updateAssetHolding: (result: any, args: any, cache, info) => {
          if (info.error) return;
          invalidateAllTopLevelQueriesForNode(cache, 'logEvents');
          invalidateAllTopLevelQueriesForNode(cache, 'financeChangelog');
        },
        deleteAssetHolding: (
          result: GQDeleteAssetHoldingMutation,
          args: GQDeleteAssetHoldingMutationVariables,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          const AssetHoldingList = gql`
            query AssetHoldingList {
              assetHoldings {
                id
              }
            }
          `;
          cache.updateQuery({ query: AssetHoldingList }, (data) => {
            const existingRecords = data?.assetHoldings;
            if (!existingRecords || !Array.isArray(existingRecords))
              return null;
            const deletedRecordId = result.deleteAssetHolding?.id;
            if (deletedRecordId) {
              const index = existingRecords.findIndex(
                (record) => record.id === deletedRecordId
              );
              if (index !== -1) {
                existingRecords.splice(index, 1);
              }
            }
            return data;
          });
        },
        deleteAssetHoldings: (
          result: GQDeleteAssetHoldingsMutation,
          args: GQDeleteAssetHoldingsMutationVariables,
          cache,
          info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
          if (info.error) return;
          const ids = result.deleteAssetHoldings?.ids;
          if (ids) {
            invalidateDeletedRecords(cache, ids, 'FinanceAssetHolding');
          }
        },

        updateFinanceAutomaticReminders: (
          result: GQUpdateFinanceAutomaticRemindersMutation,
          args: any,
          cache
        ) => {
          // not ideal, i know
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeAutomaticReminders'
          );
        },
        ///////////////////////////////////////////////////////////////////////
        // Win Finance (end)
        ///////////////////////////////////////////////////////////////////////

        createSupplierScoreCriteria: (
          result: GQCreateSupplierScoreCriteriaMutation,
          args,
          cache
        ) => {
          cache.updateQuery(
            { query: SupplierScorecardsDocument },
            (data?: GQSupplierScorecardsQuery | null) => {
              if (!data) {
                return data;
              }

              const newCriteria =
                result.createSupplierScoreCriteria.supplierScoreCriteria;

              const scorecardIndex = data.supplierScorecards.findIndex(
                (scorecard) => scorecard.id === newCriteria.supplierScoreId
              );
              if (scorecardIndex === -1) {
                return data;
              }

              data.supplierScorecards[scorecardIndex].criteria.push(
                newCriteria
              );
              return data;
            }
          );
        },

        withdrawCompanyChangeRequest: (
          result: GQWithdrawCompanyChangeRequestMutation,
          args,
          cache
        ) => {
          const PendingCompanyChangeRequests = gql`
            query PendingCompanyChangeRequests {
              pendingCompanyChangeRequests {
                id
              }
            }
          `;
          cache.updateQuery({ query: PendingCompanyChangeRequests }, (data) => {
            const existingRecords = data?.pendingCompanyChangeRequests;
            if (!existingRecords || !Array.isArray(existingRecords)) {
              return null;
            }
            const deletedId = result.withdrawCompanyChangeRequest.id;
            // remove record from orgCompanies inplace
            const index = existingRecords.findIndex(
              (record) => record.id === deletedId
            );
            if (index !== -1) {
              existingRecords.splice(index, 1);
            }
            return data;
          });

          function removePendingCompanyCreateRequest(
            rows: GQPostMeasurementVendorMatchingTableRowsQuery['postMeasurementVendorMatchingTableRows']['vendorRows']['edges']
          ) {
            const idx = rows.findIndex(
              (row) =>
                row?.node?.pendingCompanyCreateRequest?.id ===
                result.withdrawCompanyChangeRequest.id
            );
            if (idx !== -1) {
              const node = rows[idx]?.node;
              if (node) {
                node.pendingCompanyCreateRequest = null;
              }
            }
          }

          cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'postMeasurementVendorMatchingTableRows'
            )
            .forEach((field) => {
              cache.updateQuery(
                {
                  query: PostMeasurementVendorMatchingTableRowsDocument,
                  variables: { ...field.arguments },
                },
                (data) => {
                  const rows = (
                    data as GQPostMeasurementVendorMatchingTableRowsQuery
                  ).postMeasurementVendorMatchingTableRows.vendorRows.edges;
                  removePendingCompanyCreateRequest(rows);
                  return data;
                }
              );
            });

          cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'preMeasurementVendorMatchingTableRows'
            )
            .forEach((field) => {
              cache.updateQuery(
                {
                  query: PreMeasurementVendorMatchingTableRowsDocument,
                  variables: { ...field.arguments },
                },
                (data) => {
                  const rows = (
                    data as GQPreMeasurementVendorMatchingTableRowsQuery
                  ).preMeasurementVendorMatchingTableRows.vendorRows.edges;
                  removePendingCompanyCreateRequest(rows);
                  return data;
                }
              );
            });

          invalidateAllTopLevelQueriesForNode(
            cache,
            'preMeasurementSupplierMappingV1Analytics'
          );
        },

        upsertOrgCompanyForName: (
          result: GQUpsertOrgCompanyForNameMutation,
          args: GQUpsertOrgCompanyForNameMutationVariables,
          cache,
          info
        ) => {
          const Organization = gql`
            query OrganizationForCacheUpdate {
              organization {
                id
                orgCompanies {
                  id
                  name
                }
              }
            }
          `;

          cache.updateQuery({ query: Organization }, (data) => {
            const org = data?.organization as any;
            const orgCompanies = org?.orgCompanies;
            if (!org || !orgCompanies || !Array.isArray(orgCompanies)) {
              return null;
            }
            const newRecord = result.upsertOrgCompanyForName?.orgCompany;
            if (newRecord) {
              // remove record from orgCompanies inplace
              const index = orgCompanies.findIndex(
                (record) => record.id === newRecord.id
              );
              if (index !== -1) {
                orgCompanies.splice(index, 1);
              }

              orgCompanies.push(newRecord);
            }
            return data;
          });
          invalidateAllTopLevelQueriesForNode(cache, 'suppliersV2');
          invalidateAllTopLevelQueriesForNode(
            cache,
            'preMeasurementSupplierMappingV1Analytics'
          );

          cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'postMeasurementVendorMatchingTableRows'
            )
            .forEach((field) => {
              cache.updateQuery(
                {
                  query: PostMeasurementVendorMatchingTableRowsDocument,
                  variables: { ...field.arguments },
                },
                (
                  data: GQPostMeasurementVendorMatchingTableRowsQuery | null
                ) => {
                  if (!data) {
                    return data;
                  }

                  const rows =
                    data.postMeasurementVendorMatchingTableRows.vendorRows
                      .edges;
                  const idx = rows.findIndex(
                    (row) =>
                      row?.node?.rawVendorEntity ===
                      result.upsertOrgCompanyForName.orgCompany.name
                  );
                  if (idx !== -1) {
                    const node = rows[idx]?.node;
                    if (node) {
                      node.orgCompany =
                        result.upsertOrgCompanyForName.orgCompany;
                    }
                  }
                  return data;
                }
              );
            });

          // TODO(Henry): dedupe logic between this and the post measurement update right above lol
          cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'preMeasurementVendorMatchingTableRows'
            )
            .forEach((field) => {
              cache.updateQuery(
                {
                  query: PreMeasurementVendorMatchingTableRowsDocument,
                  variables: { ...field.arguments },
                },
                (data: GQPreMeasurementVendorMatchingTableRowsQuery | null) => {
                  if (!data) {
                    return data;
                  }

                  const rows =
                    data.preMeasurementVendorMatchingTableRows.vendorRows.edges;
                  const idx = rows.findIndex(
                    (row) =>
                      row?.node?.rawVendorEntity ===
                      result.upsertOrgCompanyForName.orgCompany.name
                  );
                  if (idx !== -1) {
                    const node = rows[idx]?.node;
                    if (node) {
                      node.orgCompany =
                        result.upsertOrgCompanyForName.orgCompany;
                    }
                  }
                  return data;
                }
              );
            });
        },

        upsertNonFootprintSupplier: (
          _result: GQUpsertNonFootprintSupplierMutation,
          _args: GQUpsertNonFootprintSupplierMutationVariables,
          cache
        ) => {
          invalidateAllTopLevelQueriesForNode(cache, 'suppliersV2');
        },
        deleteNonFootprintSupplier: (
          _result: GQDeleteNonFootprintSupplierMutation,
          _args: GQDeleteNonFootprintSupplierMutationVariables,
          cache
        ) => {
          invalidateAllTopLevelQueriesForNode(cache, 'suppliersV2');
        },
        deleteOrgCompany: (
          result: GQDeleteOrgCompanyMutation,
          args: GQDeleteOrgCompanyMutationVariables,
          cache
        ) => {
          const Organization = gql`
            query OrganizationForDeleteOrgCompanyCacheUpdate {
              organization {
                id
                orgCompanies {
                  id
                  name
                }
              }
            }
          `;

          cache.updateQuery({ query: Organization }, (data) => {
            const org = data?.organization as any;
            const orgCompanies = org?.orgCompanies;
            if (!org || !orgCompanies || !Array.isArray(orgCompanies)) {
              return null;
            }
            const deletedRecordId = result.deleteOrgCompany?.id;
            if (deletedRecordId) {
              // remove record from orgCompanies inplace
              const index = orgCompanies.findIndex(
                (record) => record.id === deletedRecordId
              );
              if (index !== -1) {
                orgCompanies.splice(index, 1);
              }
            }
            return data;
          });

          function deleteOrgCompanyFromVendorMatchingTableRows(
            rows: GQPostMeasurementVendorMatchingTableRowsQuery['postMeasurementVendorMatchingTableRows']['vendorRows']['edges']
          ) {
            const idx = rows.findIndex(
              (row) => row?.node?.orgCompany?.id === result.deleteOrgCompany.id
            );
            if (idx !== -1) {
              const node = rows[idx]?.node;
              if (node) {
                node.orgCompany = null;
              }
            }
          }

          cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'postMeasurementVendorMatchingTableRows'
            )
            .forEach((field) => {
              cache.updateQuery(
                {
                  query: PostMeasurementVendorMatchingTableRowsDocument,
                  variables: { ...field.arguments },
                },
                (
                  data: GQPostMeasurementVendorMatchingTableRowsQuery | null
                ) => {
                  if (!data) {
                    return data;
                  }
                  const rows =
                    data.postMeasurementVendorMatchingTableRows.vendorRows
                      .edges;
                  deleteOrgCompanyFromVendorMatchingTableRows(rows);
                  return data;
                }
              );
            });

          cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'preMeasurementVendorMatchingTableRows'
            )
            .forEach((field) => {
              cache.updateQuery(
                {
                  query: PreMeasurementVendorMatchingTableRowsDocument,
                  variables: { ...field.arguments },
                },
                (data: GQPreMeasurementVendorMatchingTableRowsQuery | null) => {
                  if (!data) {
                    return data;
                  }
                  const rows =
                    data.preMeasurementVendorMatchingTableRows.vendorRows.edges;
                  deleteOrgCompanyFromVendorMatchingTableRows(rows);
                  return data;
                }
              );
            });
        },

        createCompanyChangeRequestCreate: (
          result: GQCreateCompanyChangeRequestCreateMutation,
          args: GQCreateCompanyChangeRequestCreateMutationVariables,
          cache,
          info
        ) => {
          const PendingChangeReqsForCreateCache = gql`
            query PendingChangeReqsForCreateCache {
              pendingCompanyChangeRequests {
                id
              }
            }
          `;

          cache.updateQuery(
            { query: PendingChangeReqsForCreateCache },
            (data) => {
              const existingRecords = data?.pendingCompanyChangeRequests as any;
              if (!existingRecords || !Array.isArray(existingRecords)) {
                return null;
              }

              const newRecord =
                result.createCompanyChangeRequestCreate?.companyChangeRequest;
              existingRecords.push(newRecord);
              return data;
            }
          );

          function addPendingCompanyCreateRequest(
            rows: GQPostMeasurementVendorMatchingTableRowsQuery['postMeasurementVendorMatchingTableRows']['vendorRows']['edges']
          ) {
            const payload = CompanyChangeRequestSchema.safeParse(
              result.createCompanyChangeRequestCreate.companyChangeRequest
                .payload
            );
            if (!payload.success) {
              return;
            }

            const idx = rows.findIndex(
              (row) =>
                row?.node?.rawVendorEntity === payload.data.orgCompanyName
            );
            if (idx !== -1) {
              const node = rows[idx]?.node;
              if (node) {
                node.pendingCompanyCreateRequest =
                  result.createCompanyChangeRequestCreate.companyChangeRequest;
              }
            }
          }

          cache
            .inspectFields('Query')
            .filter(
              (field) =>
                field.fieldName === 'postMeasurementVendorMatchingTableRows'
            )
            .forEach((field) => {
              cache.updateQuery(
                {
                  query: PostMeasurementVendorMatchingTableRowsDocument,
                  variables: { ...field.arguments },
                },
                (data) => {
                  const rows = (
                    data as GQPostMeasurementVendorMatchingTableRowsQuery
                  ).postMeasurementVendorMatchingTableRows.vendorRows.edges;
                  addPendingCompanyCreateRequest(rows);
                  return data;
                }
              );
            });

          // TODO(Henry): make this better
          invalidateAllTopLevelQueriesForNode(
            cache,
            'preMeasurementVendorMatchingTableRows'
          );
          invalidateAllTopLevelQueriesForNode(
            cache,
            'preMeasurementSupplierMappingV1Analytics'
          );
        },

        addEngagementTaskComment: (
          result: GQAddEngagementTaskCommentMutation,
          args: GQAddEngagementTaskCommentMutationVariables,
          cache,
          _info
        ) => {
          const comment = result.addEngagementTaskComment?.comment;
          if (!comment) {
            return;
          }

          patchFragmentInCache({
            cache,
            fragment: EngagementTaskContentsFragmentDoc,
            maybeIdToUpdate: args.engagementTaskId,
            patch: (engagementTask) => ({
              ...engagementTask,
              comments: [...engagementTask.comments, comment],
            }),
          });
        },
        updateSupplierCustomData: (
          result: GQUpdateSupplierCustomDataMutation,
          _args: GQUpdateSupplierCustomDataMutationVariables,
          cache,
          _info
        ) => {
          const newCustomData =
            result.updateSupplierCustomData?.supplierCustomData;
          if (!newCustomData) {
            return;
          }
          patchSupplierInCache(
            cache,
            result.updateSupplierCustomData?.supplierId,
            (gqlSupplier) => ({
              ...gqlSupplier,
              notes: newCustomData.notes,
              customDataV2: {
                ...gqlSupplier.customDataV2,
                ...newCustomData.customData,
              },
            }),
            (supplier) => ({
              ...supplier,
              priority: newCustomData.priority ?? supplier.priority,
              notes: newCustomData.notes ?? supplier.notes,
              customDataV2: {
                ...supplier.customDataV2,
                ...newCustomData.customData,
              },
            })
          );
        },
        createSupplierSavedView: (
          { createSupplierSavedView }: GQCreateSupplierSavedViewMutation,
          args: GQCreateSupplierSavedViewMutationVariables,
          cache,
          _info
        ) => {
          cache.updateQuery(
            { query: GetSupplierViewsDocument },
            (data: GQGetSupplierViewsQuery | null) => {
              data?.supplierViews?.push(createSupplierSavedView.view);
              return data;
            }
          );
        },
        updateSupplierSavedView: (
          { updateSupplierSavedView }: GQUpdateSupplierSavedViewMutation,
          args: GQUpdateSupplierSavedViewMutationVariables,
          cache,
          _info
        ) => {
          cache.updateQuery(
            { query: GetSupplierViewsDocument },
            (data: GQGetSupplierViewsQuery | null) => {
              if (updateSupplierSavedView.view) {
                const index =
                  data?.supplierViews?.findIndex(
                    (view) => view.id === updateSupplierSavedView.view.id
                  ) ?? -1;
                if (index !== -1) {
                  data?.supplierViews?.splice(index, 1);
                }

                data?.supplierViews?.push(updateSupplierSavedView.view);
              }
              return data;
            }
          );
        },
        deleteSupplierSavedView: (
          { deleteSupplierSavedView }: GQDeleteSupplierSavedViewMutation,
          args: GQDeleteSupplierSavedViewMutationVariables,
          cache,
          _info
        ) => {
          cache.updateQuery(
            { query: GetSupplierViewsDocument },
            (data: GQGetSupplierViewsQuery | null) => {
              if (data?.supplierViews === undefined) {
                return data;
              }
              data.supplierViews = data.supplierViews?.filter(
                (view) => view.id !== deleteSupplierSavedView.id
              );
              return data;
            }
          );
        },
        createSupplierContact: (
          result: GQCreateSupplierContactMutation,
          args: GQCreateSupplierContactMutationVariables,
          cache,
          _info
        ) => {
          const newContact = result.createSupplierContact?.contact;
          if (!newContact) {
            return;
          }
          cache.updateQuery(
            {
              query: GetContactsForOrgDocument,
            },
            (data: GQGetContactsForOrgQuery | null) => {
              data?.contacts.push({
                ...newContact,
                companyId: args.input.companyId,
              });
              return data;
            }
          );
          cache.updateQuery(
            {
              query: GetContactsForCompanyDocument,
              variables: { companyId: args.input.companyId },
            },
            (data: GQGetContactsForCompanyQuery | null) => {
              data?.contactsForCompany.push({
                ...newContact,
                companyId: args.input.companyId,
              });
              return data;
            }
          );
        },
        updateSupplierContact: (
          result: GQUpdateSupplierContactMutation,
          args: GQUpdateSupplierContactMutationVariables,
          cache,
          _info
        ) => {
          const newContact = result.updateSupplierContact?.contact;
          if (!newContact) {
            return;
          }
          cache.updateQuery(
            {
              query: GetContactsForOrgDocument,
            },
            (data: GQGetContactsForOrgQuery | null) => {
              data?.contacts.push(newContact);
              return data;
            }
          );

          cache.updateQuery(
            {
              query: GetContactsForCompanyDocument,
              variables: { companyId: newContact.companyId },
            },
            (data: GQGetContactsForCompanyQuery | null) => {
              data?.contactsForCompany.push(newContact);
              return data;
            }
          );
        },
        deleteSupplierContact: (
          _result: GQDeleteSupplierContactMutation,
          args: GQDeleteSupplierContactMutationVariables,
          cache,
          info
        ) => {
          cache.updateQuery(
            {
              query: GetContactsForOrgDocument,
            },
            (data: GQGetContactsForOrgQuery | null) => {
              if (data?.contacts === undefined) {
                return data;
              }
              data.contacts = data?.contacts.filter(
                (contact) => contact.id !== args.input.id
              );
              return data;
            }
          );
          // Can remove this if statement once we make companyId required
          if (isNotNullish(args.input.companyId)) {
            cache.updateQuery(
              {
                query: GetContactsForCompanyDocument,
                variables: { companyId: args.input.companyId },
              },
              (data: GQGetContactsForCompanyQuery | null) => {
                if (data?.contactsForCompany === undefined) {
                  return data;
                }
                data.contactsForCompany = data?.contactsForCompany.filter(
                  (contact) => contact.id !== args.input.id
                );
                return data;
              }
            );
          }
        },
        createSupplierAttachments: (
          result: GQCreateSupplierAttachmentsMutation,
          args: GQCreateSupplierAttachmentsMutationVariables,
          cache,
          info
        ) => {
          cache.updateQuery(
            {
              query: GetSupplierAttachmentsDocument,
              variables: { companyId: args.input.companyId },
            },
            (data: GQGetSupplierAttachmentsQuery | null) => {
              data?.supplierAttachments.push(
                ...(result.createSupplierAttachments?.fileUrls.map(
                  (fileUrl) => fileUrl.attachment
                ) ?? [])
              );
              return data;
            }
          );
        },
        deleteSupplierAttachment: (
          _result: GQDeleteSupplierAttachmentMutation,
          args: GQDeleteSupplierAttachmentMutationVariables,
          cache,
          info
        ) => {
          cache.updateQuery(
            {
              query: GetSupplierAttachmentsDocument,
              variables: { companyId: args.input.companyId },
            },
            (data: GQGetSupplierAttachmentsQuery | null) => {
              if (data?.supplierAttachments === undefined) {
                return data;
              }
              data.supplierAttachments = data?.supplierAttachments.filter(
                (attachment) =>
                  attachment.fileMetadataId !== args.input.fileMetadataId
              );
              return data;
            }
          );
        },
        deleteSupplierTableColumn: (
          { deleteSupplierTableColumn }: GQDeleteSupplierTableColumnMutation,
          args: GQDeleteSupplierTableColumnMutationVariables,
          cache,
          _info
        ) => {
          cache.updateQuery(
            {
              query: GetSupplierColumnsForCustomFieldDocument,
            },
            (data: GQGetSupplierColumnsForCustomFieldQuery | null) => {
              if (data?.supplierTableColumnsDashboard === undefined) {
                return data;
              }
              data.supplierTableColumnsDashboard =
                data.supplierTableColumnsDashboard?.filter(
                  (column) => column.id !== args.input.id
                );
              return data;
            }
          );
        },
        createEngagementCohort: (
          { createEngagementCohort }: GQCreateEngagementCohortMutation,
          args: GQCreateEngagementCohortMutationVariables,
          cache,
          _info
        ) => {
          cache.updateQuery(
            { query: GetEngagementCohortsDocument },
            (data: GQGetEngagementCohortsQuery | null) => {
              data?.engagementCohorts?.push(createEngagementCohort);
              return data;
            }
          );
        },

        createEngagementCohortsForCompany: (
          {
            createEngagementCohortsForCompany: newCohorts,
          }: GQCreateEngagementCohortsForCompanyMutation,
          {
            input: { companyId },
          }: GQCreateEngagementCohortsForCompanyMutationVariables,
          cache,
          _info
        ) => {
          cache.updateQuery(
            { query: GetEngagementCohortsDocument },
            (data: GQGetEngagementCohortsQuery | null) => {
              data?.engagementCohorts?.push(...newCohorts);
              return data;
            }
          );
          patchSupplierInCache(
            cache,
            createSupplierId({ companyId }),
            (gqlSupplier) => gqlSupplier,
            (supplier) => ({
              ...supplier,
              engagementCohortIds: [
                ...supplier.engagementCohortIds,
                ...newCohorts.map((cohort) => cohort.id),
              ],
            })
          );
        },
        setEngagementCohortsForCompany: (
          {
            setEngagementCohortsForCompany,
          }: GQSetEngagementCohortsForCompanyMutation,
          args,
          cache,
          info
        ) => {
          const { companyId, cohortIds } = setEngagementCohortsForCompany;
          patchSupplierInCache(
            cache,
            createSupplierId({ companyId }),
            (gqlSupplier) => gqlSupplier,
            (supplier) => ({
              ...supplier,
              engagementCohortIds: cohortIds,
            })
          );
        },
        setEngagementCohortAndMembers: (
          {
            setEngagementCohortAndMembers,
          }: GQSetEngagementCohortAndMembersMutation,
          args,
          cache,
          info
        ) => {
          const { oldCompanyIds, engagementCohortWithCompanyIds } =
            setEngagementCohortAndMembers;

          oldCompanyIds.forEach((companyId) =>
            patchSupplierInCache(
              cache,
              createSupplierId({ companyId }),
              (gqlSupplier) => gqlSupplier,
              (supplier) => ({
                ...supplier,
                engagementCohortIds: supplier.engagementCohortIds.filter(
                  (cohortId) => cohortId !== engagementCohortWithCompanyIds.id
                ),
              })
            )
          );

          engagementCohortWithCompanyIds.companyIds?.forEach((companyId) =>
            patchSupplierInCache(
              cache,
              createSupplierId({ companyId }),
              (gqlSupplier) => gqlSupplier,
              (supplier) => ({
                ...supplier,
                engagementCohortIds: [
                  engagementCohortWithCompanyIds.id,
                  ...supplier.engagementCohortIds.filter(
                    (cohortId) => cohortId !== engagementCohortWithCompanyIds.id
                  ),
                ],
              })
            )
          );

          cache.updateQuery(
            { query: GetEngagementCohortsDocument },
            (data: GQGetEngagementCohortsQuery | null) => {
              if (isNullish(data)) {
                return null;
              }
              data.engagementCohorts = data.engagementCohorts.map((cohort) => {
                if (cohort.id !== engagementCohortWithCompanyIds.id) {
                  return cohort;
                }
                return {
                  ...cohort,
                  name: engagementCohortWithCompanyIds.name,
                };
              });
              return data;
            }
          );
        },
        deleteCompanyEngagementCohort: (
          {
            deleteCompanyEngagementCohort: { affectedCompanyIds },
          }: GQDeleteCompanyEngagementCohortMutation,
          args: GQDeleteCompanyEngagementCohortMutationVariables,
          cache,
          _info
        ) => {
          cache.invalidate('Query', 'engagementCohorts');
          affectedCompanyIds.forEach((companyId) =>
            patchSupplierInCache(
              cache,
              createSupplierId({ companyId }),
              (gqlSupplier) => gqlSupplier,
              (supplier) => ({
                ...supplier,
                engagementCohortIds: supplier.engagementCohortIds.filter(
                  (engagementCohortId) =>
                    engagementCohortId !== args.input.cohortId
                ),
              })
            )
          );
        },
        updateEngagementTask(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeMeasurementProgressRows'
          );
          invalidateAllTopLevelQueriesForNode(
            cache,
            'supplierEmissionsFactorsTableRows'
          );
        },
        updateEngagementTaskConfig(result, args, cache, info) {
          invalidateAllTopLevelQueriesForNode(cache, 'engagementTaskConfigs');
        },
        upsertCompanyPortalSettings(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'companyPortalSettings');
        },
        joinWaitlist(_result, _args, cache, _info) {
          cache.invalidate('Query', 'productWaitlistTypes');
        },
        leaveWaitlist(_result, _args, cache, _info) {
          cache.invalidate('Query', 'productWaitlistTypes');
        },
        joinReportWaitlist(_result, _args, cache, _info) {
          cache.invalidate('Query', 'reportWaitlistTypes');
        },
        leaveReportWaitlist(_result, _args, cache, _info) {
          cache.invalidate('Query', 'reportWaitlistTypes');
        },
        setAssetBenchmarks(_result, _args, cache, _info) {
          invalidateAllTopLevelQueriesForNode(cache, 'assetCorporate');
        },

        createMarketplaceEacPurchase(_result, _args, cache, _info) {
          cache.invalidate('Query', 'marketplacePurchases');
        },
        createPrivateDisclosure(
          result: GQCreatePrivateDisclosureMutation,
          _args,
          cache,
          _info
        ) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'suppliersWithTargetDisclosures'
          );
          const disclosure = result.createPrivateDisclosure?.disclosure;
          // private disclosures *should* have a company but it is not
          // guaranteed by our types today
          if (disclosure && disclosure.company) {
            updateSuppliersDataFragment(
              cache,
              { companyId: disclosure.company.id },
              (supplier) => ({
                // TODO (fravic): Handle targets outside of the current disclosure
                // Maybe the mutation can return those other disclosures as well?
                ...supplier,
                // eslint-disable-next-line @watershed/no-join-commas
                targets: (disclosure.targets ?? [])
                  .map((t) => t.description)
                  .join(', '),
              })
            );
          }
        },
        updatePrivateDisclosure(
          result: GQUpdatePrivateDisclosureMutation,
          _args,
          cache,
          _info
        ) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'suppliersWithTargetDisclosures'
          );
          const disclosure = result.updatePrivateDisclosure?.disclosure;
          // private disclosures *should* have a company but it is not
          // guaranteed by our types today
          if (disclosure && disclosure.company) {
            updateSuppliersDataFragment(
              cache,
              { companyId: disclosure.company.id },
              (supplier) => ({
                // TODO (fravic): Handle targets outside of the current disclosure
                // Maybe the mutation can return those other disclosures as well?
                ...supplier,
                // eslint-disable-next-line @watershed/no-join-commas
                targets: (disclosure.targets ?? [])
                  .map((t) => t.description)
                  .join(', '),
              })
            );
          }
        },

        acceptFinanceImportDiff: (_result, _args, cache, _info) => {
          // This is a temporary solution to invalidate the cache for the
          // finance import diff, which could impact lots of models. There
          // should be a more targeted way to invalidate the cache for only
          // the models that were impacted by the finance import diff.
          invalidateAllTopLevelQueriesForNode(cache, 'funds');
          invalidateAllTopLevelQueriesForNode(cache, 'assetsCorporate');
          invalidateAllTopLevelQueriesForNode(cache, 'assetsRealEstate');
          invalidateAllTopLevelQueriesForNode(cache, 'assetGroups');
          invalidateAllTopLevelQueriesForNode(cache, 'assetSovereignBonds');
          invalidateAllTopLevelQueriesForNode(
            cache,
            'assetPersonalMotorVehicleInsurances'
          );
          invalidateAllTopLevelQueriesForNode(cache, 'assetYears');
          invalidateAllTopLevelQueriesForNode(cache, 'sovereignBondAssetYears');
          invalidateAllTopLevelQueriesForNode(
            cache,
            'personalMotorVehicleInsuranceAssetYears'
          );
          invalidateAllTopLevelQueriesForNode(cache, 'assetHoldings');
          invalidateAllTopLevelQueriesForNode(
            cache,
            'personalMotorVehicleInsuranceAssetHoldings'
          );
          invalidateAllTopLevelQueriesForNode(cache, 'contacts');
          invalidateAllTopLevelQueriesForNode(
            cache,
            'financeModelCardMetadata'
          );
        },

        editSimulationCandidateCost: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'getOverlayForConfigType');
          // Necessary since we also query for these on invidiual portfolios
          invalidateAllTopLevelQueriesForNode(cache, 'simulationPortfolios');
        },
        runSimulation: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'simulationScenario');
        },
        deleteSimulationScenario: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'simulationPortfolios');
          invalidateAllTopLevelQueriesForNode(cache, 'simulationPortfolio');
        },
        deleteSimulationPortfolio: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'simulationPortfolios');
        },
        createSimulationPortfolio: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'simulationPortfolios');
        },
        duplicateScenarioWithPortfolio: (_result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(cache, 'simulationPortfolios');
        },
        generateAutoFootprint: (result, _args, cache, _info) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'latestFootprintAssemblyRun'
          );
        },
        generateCustomerUploadedReferenceDataRevision: (
          result,
          _args,
          cache,
          _info
        ) => {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'methodologyCustomizationRowsForOrg'
          );
        },
        setPublishEngagementTaskConfig: (
          result: GQSetPublishEngagementTaskConfigMutation,
          args,
          cache,
          info
        ) => {
          // update cache for queries that don't
          // want unpublished org specific configs
          cache
            .inspectFields('Query')
            .filter((field) => field.fieldName === 'engagementTaskConfigs')
            .filter(
              (field) =>
                field.arguments &&
                !field.arguments['includeUnpublishedOrgSpecific']
            )
            .forEach((field) => {
              const { fieldName, arguments: variables } = field;
              cache.invalidate('Query', fieldName, variables || undefined);
            });
        },
        pushUtilitiesDiff(_result, _args, cache, info) {
          if (!info.error) {
            invalidateAllTopLevelQueriesForNode(cache, 'buildingWithUtilities');
            invalidateAllTopLevelQueriesForNode(cache, 'utilitiesMetadata');
          }
        },
        createBuildingUtility(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(cache, 'utilitiesMetadata');
          const org = cache.resolve('Query', 'organization');
          if (typeof org !== 'string') {
            return;
          }
          cache.invalidate(org, 'buildingCompletionIntervalsUntyped');
        },
        updateBuildingUtility(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(cache, 'utilitiesMetadata');
          const org = cache.resolve('Query', 'organization');
          if (typeof org !== 'string') {
            return;
          }
          cache.invalidate(org, 'buildingCompletionIntervalsUntyped');
        },
        deleteBuildingUtility(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(cache, 'utilitiesMetadata');
          const org = cache.resolve('Query', 'organization');
          if (typeof org !== 'string') {
            return;
          }
          cache.invalidate(org, 'buildingCompletionIntervalsUntyped');
        },
        createBuilding(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'dataApprovalObjectChangelog'
          );
        },
        updateBuilding(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'dataApprovalObjectChangelog'
          );
        },
        updateBuildings(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'dataApprovalObjectChangelog'
          );
        },
        deleteBuildings(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'dataApprovalObjectChangelog'
          );
          invalidateAllTopLevelQueriesForNode(cache, 'facilities');
        },
        saveDeltaProgressValueMapping(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'valueMappingAggregatedQuantities'
          );
        },
        approveAllValueMappingRecords(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(
            cache,
            'datasourceValueMappingRule'
          );
        },
        createDiscussion(
          result: GQCreateDiscussionMutation,
          args: GQCreateDiscussionMutationVariables,
          cache
        ) {
          const { discussion } = result.createDiscussion;
          cache.updateQuery(
            {
              query: CommentDiscussionsForAnchorDocument,
              variables: { input: args.input.anchor },
            },
            (data: GQCommentDiscussionsForAnchorQuery | null) => {
              data?.commentDiscussionsForAnchor?.discussions.push(discussion);
              return data;
            }
          );
        },
        resetFootprintExclusionSuggestions(
          result,
          args: GQMutationResetFootprintExclusionSuggestionsArgs,
          cache,
          info
        ) {
          const footprintSnapshotId = args.input.footprintSnapshotId;
          const org = cache.resolve('Query', 'organization');
          if (typeof org !== 'string') {
            return;
          }
          cache.invalidate(org, 'footprintExclusionSuggestions', {
            footprintSnapshotId,
          });
        },
        dismissFootprintExclusionSuggestion(result, args, cache, info) {
          // info variables are the actual arguments for the graphql query,
          // whereas args are the arguments to the mutation field (they're different!)
          const suggestionId = (
            info.variables as GQDismissDeduplicationSuggestionMutationVariables
          ).suggestionId;

          // Resolve the current org
          const org = cache.resolve('Query', 'organization');
          if (!org || typeof org !== 'string') {
            return;
          }
          cache
            .inspectFields(org)
            .filter(
              // Find specific instances of footprintExclusionSuggestions,
              // allowing us to update footprintExclusionSuggestions across queries
              (field) => field.fieldName === 'footprintExclusionSuggestions'
            )
            .forEach((field) => {
              const suggestions = cache.resolve(org, field.fieldKey);
              if (!suggestions || !Array.isArray(suggestions)) {
                return;
              }
              // Re-link the suggestions to remove the dismissed suggestionId
              cache.link(
                org,
                field.fieldKey,
                suggestions.filter((suggestionKey) => {
                  if (typeof suggestionKey !== 'string') {
                    return;
                  }
                  return !suggestionKey.includes(suggestionId);
                })
              );
            });
        },
        updateWatershedFootprintReviewRequestStatus(
          result: GQUpdateFootprintReviewRequestStatusMutation,
          args: GQUpdateFootprintReviewRequestStatusMutationVariables,
          cache
        ) {
          const {
            watershedFootprintReviewRequest: { discussion },
          } = result.updateWatershedFootprintReviewRequestStatus;

          if (discussion) {
            cache.updateQuery(
              {
                query: CommentDiscussionsForAnchorDocument,
                variables: { input: { productSection: 'FootprintQuestion' } },
              },
              (data: GQCommentDiscussionsForAnchorQuery | null) => {
                const cachedDiscussions =
                  data?.commentDiscussionsForAnchor?.discussions;
                if (cachedDiscussions) {
                  const discussionIdx = cachedDiscussions.findIndex(
                    (d) => d.id === discussion.id
                  );
                  if (discussionIdx) {
                    cachedDiscussions[discussionIdx] = discussion;
                  }
                }
                return data;
              }
            );
          }
        },
        setPreferredLocale(
          _result: GQSetPreferredLocaleMutation,
          args: GQSetPreferredLocaleMutationVariables,
          cache
        ) {
          onSetLocale(args.input.locale);
          cache.invalidate('Query');
        },
        checkAndGenerateFacilitiesBarts(
          _result: GQCheckAndGenerateFacilitiesBartsMutation,
          _args,
          cache
        ) {
          // At this point, we'll be running a background job to refresh
          // facilities BARTs, and the facilities data summaries UI will be
          // blocked. We invlidate the data summary queries here so that when
          // the UI is unblocked, the query is re-run. We're invalidating *all*
          // BI queries here (not just facilities) but these queries are
          // relatively fast anyway, and I don't want to have to worry about
          // keeping the query variables in sync here.
          invalidateAllTopLevelQueriesForNode(cache, 'biQueryMetadata');
          invalidateAllTopLevelQueriesForNode(cache, 'biQueryData');
        },
        upsertOrgStructureVersion(
          result: GQUpsertOrgStructureVersionMutation,
          args: GQUpsertOrgStructureVersionMutationVariables,
          cache
        ) {
          cache.updateQuery(
            {
              query: OrgStructureVersionsDocument,
            },
            (data: GQOrgStructureVersionsQuery | null) => {
              if (data) {
                // we only need to update the cache if something was created,
                // a.k.a. we didn't pass an id
                // This is because cache updates are free for update queries
                // are free using urql (i.e. an id and __typename were returned)
                if (!args.input.id) {
                  data.orgStructureVersions.push(
                    result.upsertOrgStructureVersion.orgStructureVersion
                  );
                }
              }
              return data;
            }
          );
        },
        deleteOrgStructureVersion(
          result: GQDeleteOrgStructureVersionMutation,
          _args: GQDeleteOrgStructureVersionMutationVariables,
          cache
        ) {
          cache.updateQuery(
            {
              query: OrgStructureVersionsDocument,
            },
            (data: GQOrgStructureVersionsQuery | null) => {
              if (data) {
                data.orgStructureVersions = data.orgStructureVersions.filter(
                  (version) =>
                    version.id !==
                    result.deleteOrgStructureVersion.orgStructureVersion.id
                );
              }
              return data;
            }
          );
        },
        duplicateOrgStructureVersion(
          result: GQDuplicateOrgStructureVersionMutation,
          _args: GQDuplicateOrgStructureVersionMutationVariables,
          cache
        ) {
          cache.updateQuery(
            {
              query: OrgStructureVersionsDocument,
            },
            (data: GQOrgStructureVersionsQuery | null) => {
              if (data) {
                data.orgStructureVersions.push(
                  result.duplicateOrgStructureVersion.orgStructureVersion
                );
              }
              return data;
            }
          );
        },
        createOrgUnitType(
          result: GQCreateOrgUnitTypeMutation,
          args: GQCreateOrgUnitTypeMutationVariables,
          cache
        ) {
          cache.updateQuery(
            {
              query: OrgUnitTypesDocument,
              variables: { versionId: args.input.versionId },
            },
            (data: GQOrgUnitTypesQuery | null) => {
              if (data) {
                data.orgUnitTypes.push(result.createOrgUnitType.orgUnitType);
              }
              return data;
            }
          );
        },
        deleteOrgUnitType(
          result: GQDeleteOrgUnitTypeMutation,
          args: GQDeleteOrgUnitTypeMutationVariables,
          cache
        ) {
          cache.updateQuery(
            {
              query: OrgUnitTypesDocument,
              variables: { versionId: args.input.versionId },
            },
            (data: GQOrgUnitTypesQuery | null) => {
              if (data) {
                data.orgUnitTypes = data.orgUnitTypes.filter(
                  (type) => type.id !== result.deleteOrgUnitType.orgUnitType.id
                );
              }
              return data;
            }
          );
        },
        createOrgUnit(
          result: GQCreateOrgUnitMutation,
          args: GQCreateOrgUnitMutationVariables,
          cache
        ) {
          cache.updateQuery(
            {
              query: OrgUnitGridPageDocument,
              variables: {
                versionId: args.input.versionId,
                orgUnitTypeId: args.input.orgUnitTypeId,
              },
            },
            (data: GQOrgUnitGridPageQuery | null) => {
              if (data) {
                data.orgUnits.push(result.createOrgUnit.orgUnit);
              }
              return data;
            }
          );
        },
        deleteOrgUnits(
          result: GQDeleteOrgUnitsMutation,
          args: GQDeleteOrgUnitsMutationVariables,
          cache
        ) {
          invalidateDeletedRecords(
            cache,
            result.deleteOrgUnits.orgUnits.map((unit) => unit.id),
            'OrgUnit'
          );
        },
        createOrgUnitTypeRelationship(
          result: GQCreateOrgUnitTypeRelationshipMutation,
          args: GQCreateOrgUnitTypeRelationshipMutationVariables,
          cache
        ) {
          cache.updateQuery(
            {
              query: OrgUnitTypeDocument,
              variables: {
                versionId: args.input.versionId,
                orgUnitTypeId: args.input.orgUnitTypeIdTo,
              },
            },
            (data: GQOrgUnitTypeQuery | null) => {
              if (data) {
                const orgUnitTypeTo = data.orgUnitTypes.find(
                  ({ id }) => id === args.input.orgUnitTypeIdTo
                );
                if (orgUnitTypeTo) {
                  orgUnitTypeTo.relationships.push(
                    result.createOrgUnitTypeRelationship.orgUnitTypeRelationship
                  );
                }
              }
              return data;
            }
          );
          cache.updateQuery(
            {
              query: OrgUnitTypeDocument,
              variables: {
                versionId: args.input.versionId,
                orgUnitTypeId: args.input.orgUnitTypeIdFrom,
              },
            },
            (data: GQOrgUnitTypeQuery | null) => {
              if (data) {
                const orgUnitTypeIdFrom = data.orgUnitTypes.find(
                  ({ id }) => id === args.input.orgUnitTypeIdFrom
                );
                if (orgUnitTypeIdFrom) {
                  orgUnitTypeIdFrom.relationships.push(
                    result.createOrgUnitTypeRelationship.orgUnitTypeRelationship
                  );
                }
              }
              return data;
            }
          );
        },
        deleteOrgUnitTypeRelationship(
          result: GQDeleteOrgUnitTypeRelationshipMutation,
          args: GQDeleteOrgUnitTypeRelationshipMutationVariables,
          cache
        ) {
          invalidateDeletedRecords(
            cache,
            [result.deleteOrgUnitTypeRelationship.orgUnitTypeRelationship.id],
            'OrgUnitTypeRelationship'
          );
        },
        addDataApprovalComment(
          result: GQAddDataApprovalCommentMutation,
          args: GQAddDataApprovalCommentMutationVariables,
          cache
        ) {
          if (args.input.buildingId) {
            updateDataApprovalObjectChangelogCache(
              cache,
              args.input.buildingId,
              GQObjectType.Building,
              result.addDataApprovalComment.newChangelogEvents
            );
          }
          updateDataApprovalUserUploadTaskChangelogCache(
            cache,
            'UserUploadTask',
            args.input.userUploadTaskId,
            result.addDataApprovalComment.newChangelogEvents
          );
        },
        requestDataApproval(
          result: GQRequestDataApprovalMutation,
          args: GQRequestDataApprovalMutationVariables,
          cache
        ) {
          updateDataApprovalUserUploadTaskChangelogCache(
            cache,
            args.input.targetKind,
            args.input.targetId,
            result.requestDataApproval.newChangelogEvents
          );
        },
        approveData(
          result: GQApproveDataMutation,
          args: GQApproveDataMutationVariables,
          cache
        ) {
          updateDataApprovalUserUploadTaskChangelogCache(
            cache,
            args.input.targetKind,
            args.input.targetId,
            result.approveData.newChangelogEvents
          );
        },
        rejectData(
          result: GQRejectDataMutation,
          args: GQRejectDataMutationVariables,
          cache
        ) {
          updateDataApprovalUserUploadTaskChangelogCache(
            cache,
            args.input.targetKind,
            args.input.targetId,
            result.rejectData.newChangelogEvents
          );
        },
        requestUnlockData(
          result: GQRequestUnlockDataMutation,
          args: GQRequestUnlockDataMutationVariables,
          cache
        ) {
          updateDataApprovalUserUploadTaskChangelogCache(
            cache,
            args.input.targetKind,
            args.input.targetId,
            result.requestUnlockData.newChangelogEvents
          );
        },
        confirmUtilitySchema(
          _result: GQConfirmCandidateMutation,
          _args: GQConfirmCandidateMutationVariables,
          cache
        ) {
          invalidateAllTopLevelQueriesForNode(cache, 'buildingWithUtilities');
        },
        assignFacilitiesToTasks(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(cache, 'facilities');
        },
        unassignFacilitiesFromTask(_result, _args, cache, info) {
          invalidateAllTopLevelQueriesForNode(cache, 'facilities');
        },
      },
    },
    schema,
  });

export default cache;
