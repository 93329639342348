import maxBy from 'lodash/maxBy';
import {
  GQCdpVendorDataFieldsFragment,
  GQCompanyClimateCommitmentFieldsForCcisFragment,
  GQCompanyClimateCommitmentKind,
  GQCompanyClimateProgress,
  GQCompanySbtCommitmentStage,
  GQSbtCommitmentFieldsFragment,
  GQScope3EvaluationStatus,
  GQSupplierHistoricalEmissions,
} from '../generated/graphql';
import capitalize from 'lodash/capitalize';
import assertNever from './assertNever';
import { isCleanEnergyCommitment } from './climateCommitmentUtils';
import EmissionsYear, {
  EmissionsInterfaceNoUnits,
  LegacyScopeKeysType,
} from '../companyData/EmissionsYear';
import groupBy from 'lodash/groupBy';
import isNotNullish from './isNotNullish';

/*
    Company Utils
    =============
    
    These are collection of semi-random utilities useful for companies
*/

const DisclosureTypes = ['emissions', 'targets', 'initiatives'] as const;
export type DisclosureType = (typeof DisclosureTypes)[number];

export const CLIMATE_PROGRESS_TO_DISCLOSURES: Record<
  GQCompanyClimateProgress,
  Array<DisclosureType>
> = {
  [GQCompanyClimateProgress.DisclosedEmissionsAndSetCommitments]: [
    'emissions',
    'targets',
  ],
  [GQCompanyClimateProgress.DisclosedEmissions]: ['emissions'],
  [GQCompanyClimateProgress.DisclosedEmissionsTargetsInitiatives]: [
    'emissions',
    'targets',
    'initiatives',
  ],
  [GQCompanyClimateProgress.DisclosedTargets]: ['targets'],
  [GQCompanyClimateProgress.DisclosedTargetsInitiatives]: [
    'targets',
    'initiatives',
  ],
  [GQCompanyClimateProgress.DisclosedInitiatives]: ['initiatives'],
  [GQCompanyClimateProgress.DisclosedEmissionsInitiatives]: [
    'emissions',
    'initiatives',
  ],
  [GQCompanyClimateProgress.None]: [],
  [GQCompanyClimateProgress.Unknown]: [],
};

export const DISCLOSURE_TO_CLIMATE_PROGRESSES: Record<
  DisclosureType,
  Array<GQCompanyClimateProgress>
> = {
  emissions: [
    GQCompanyClimateProgress.DisclosedEmissionsAndSetCommitments,
    GQCompanyClimateProgress.DisclosedEmissions,
    GQCompanyClimateProgress.DisclosedEmissionsTargetsInitiatives,
    GQCompanyClimateProgress.DisclosedEmissionsInitiatives,
  ],
  targets: [
    GQCompanyClimateProgress.DisclosedEmissionsAndSetCommitments,
    GQCompanyClimateProgress.DisclosedEmissionsTargetsInitiatives,
    GQCompanyClimateProgress.DisclosedTargets,
    GQCompanyClimateProgress.DisclosedTargetsInitiatives,
  ],
  initiatives: [
    GQCompanyClimateProgress.DisclosedEmissionsTargetsInitiatives,
    GQCompanyClimateProgress.DisclosedTargetsInitiatives,
    GQCompanyClimateProgress.DisclosedInitiatives,
    GQCompanyClimateProgress.DisclosedEmissionsInitiatives,
  ],
};

export const getClimateProgressLabel = (
  climateProgress?: GQCompanyClimateProgress
): string => {
  switch (climateProgress) {
    case GQCompanyClimateProgress.None: {
      return 'None found';
    }
    case GQCompanyClimateProgress.Unknown: {
      return 'Unknown';
    }
    case undefined: {
      return 'Unknown';
    }
    default: {
      return capitalize(
        // TODO: i18n (please resolve or remove this TODO line if legit)
        // eslint-disable-next-line @watershed/no-join-commas
        CLIMATE_PROGRESS_TO_DISCLOSURES[climateProgress].join(', ')
      );
    }
  }
};

export const ORDERED_SBT_STAGES: Array<GQCompanySbtCommitmentStage> = [
  GQCompanySbtCommitmentStage.None,
  GQCompanySbtCommitmentStage.Committed,
  GQCompanySbtCommitmentStage.TargetsDeveloped,
  GQCompanySbtCommitmentStage.TargetsSubmitted,
  GQCompanySbtCommitmentStage.TargetsSet,
];

export const SBT_COMMITMENT_STAGE_LABELS: Record<
  GQCompanySbtCommitmentStage,
  string
> = {
  [GQCompanySbtCommitmentStage.None]: 'Not started',
  [GQCompanySbtCommitmentStage.Committed]: 'Committed',
  [GQCompanySbtCommitmentStage.TargetsDeveloped]: 'Targets developed',
  [GQCompanySbtCommitmentStage.TargetsSubmitted]: 'Submitted to SBTi',
  [GQCompanySbtCommitmentStage.TargetsSet]: 'Validated by SBTi',
};

export const TARGET_STATE_OTHER_TARGETS_LABEL = 'Other targets';
export const TARGET_STATE_COMMITTED_LABEL = 'Committed to SBTi';

export const COMMITMENT_KINDS: Array<GQCompanyClimateCommitmentKind> = [
  GQCompanyClimateCommitmentKind.NetZero,
  GQCompanyClimateCommitmentKind.CarbonNeutral,
  GQCompanyClimateCommitmentKind.ScienceBasedTarget,
  GQCompanyClimateCommitmentKind.CleanEnergy,
];

export function getUniqueCompanyClimateCommitments(
  allCommitments: Array<GQCompanyClimateCommitmentFieldsForCcisFragment>
): Array<GQCompanyClimateCommitmentFieldsForCcisFragment> {
  return Object.entries(
    groupBy(allCommitments, (commitment) => commitment.kind)
  )
    .map(([kind, commitments]) =>
      getCompanyCommitmentByKind(
        kind as GQCompanyClimateCommitmentKind,
        commitments
      )
    )
    .filter(isNotNullish);
}

export function getCompanyCommitmentByKind<
  T extends Pick<
    GQCompanyClimateCommitmentFieldsForCcisFragment,
    'kind' | 'commitmentMadeDate'
  > & { commitment?: GQSbtCommitmentFieldsFragment },
>(kind: GQCompanyClimateCommitmentKind, commitments: Array<T>): T | null {
  const commitmentsForKind = commitments.filter((commitment) => {
    if (commitment.kind !== kind) {
      return false;
    }

    // For SBT, ignore commitments that have been removed.
    if (
      kind === GQCompanyClimateCommitmentKind.ScienceBasedTarget &&
      commitment.commitment?.stage === 'Removed'
    ) {
      return false;
    }
    return true;
  });

  if (commitmentsForKind.length === 0) {
    return null;
  }

  // If there are more than one clean energy commitments,
  // pick the one with the highest % clean energy target.
  if (kind === GQCompanyClimateCommitmentKind.CleanEnergy) {
    return (
      maxBy(commitmentsForKind, (commitment) => {
        return isCleanEnergyCommitment(commitment)
          ? commitment.targetPercentageCleanEnergy ?? 0
          : 0;
      }) ?? null
    );
  }

  // For other kind of commitments, pick the one with the latest commitment date.
  // If no commitments have a commitment date, return the first
  return (
    maxBy(commitmentsForKind, (commitment) => {
      return commitment.commitmentMadeDate;
    }) ?? (commitmentsForKind.length > 0 ? commitmentsForKind[0] : null)
  );
}

export type SupplierScope3HistoricalEmissions = Pick<
  GQSupplierHistoricalEmissions,
  | 'scope301'
  | 'scope302'
  | 'scope303'
  | 'scope304'
  | 'scope305'
  | 'scope306'
  | 'scope307'
  | 'scope308'
  | 'scope309'
  | 'scope310'
  | 'scope311'
  | 'scope312'
  | 'scope313'
  | 'scope314'
  | 'scope315'
  | 'scope316'
  | 'scope317'
>;

export function getScope3SubscopeEmissions(
  emission: Partial<EmissionsInterfaceNoUnits>
): SupplierScope3HistoricalEmissions {
  if (!EmissionsYear.hasScope3Emissions(emission)) {
    return {
      scope301: null,
      scope302: null,
      scope303: null,
      scope304: null,
      scope305: null,
      scope306: null,
      scope307: null,
      scope308: null,
      scope309: null,
      scope310: null,
      scope311: null,
      scope312: null,
      scope313: null,
      scope314: null,
      scope315: null,
      scope316: null,
      scope317: null,
    };
  }
  return {
    scope301: emission.scope301 ?? null,
    scope302: emission.scope302 ?? null,
    scope303: emission.scope303 ?? null,
    scope304: emission.scope304 ?? null,
    scope305: emission.scope305 ?? null,
    scope306: emission.scope306 ?? null,
    scope307: emission.scope307 ?? null,
    scope308: emission.scope308 ?? null,
    scope309: emission.scope309 ?? null,
    scope310: emission.scope310 ?? null,
    scope311: emission.scope311 ?? null,
    scope312: emission.scope312 ?? null,
    scope313: emission.scope313 ?? null,
    scope314: emission.scope314 ?? null,
    scope315: emission.scope315 ?? null,
    scope316: emission.scope316 ?? null,
    scope317: emission.scope317 ?? null,
  };
}

export function isScope3SubcategoryMaterial(
  scope3EvaluationStatus?: GQScope3EvaluationStatus
): boolean {
  return (
    scope3EvaluationStatus === 'RELEVANT_CALCULATED' ||
    scope3EvaluationStatus === 'RELEVANT_NOT_YET_CALCULATED'
  );
}

export function isScopeThirdPartyVerified(
  emissisonYearReport: {
    cdpVendorData?: GQCdpVendorDataFieldsFragment | null;
  },
  scope: LegacyScopeKeysType
): boolean {
  switch (scope) {
    case 'scope1':
      return !!emissisonYearReport.cdpVendorData?.scope1Verified;
    case 'scope2':
      return !!emissisonYearReport.cdpVendorData?.scope2Verified;
    case 'scope3':
      return !!emissisonYearReport.cdpVendorData?.scope3Verified;
    // We don't have data for subscope level verification at this time.
    default:
      return false;
  }
}

/**
 *  True means the scope is material. False does not mean the scope is not material.
 *  The materiality could be unknown, for example.
 *  */
export function isScopeMaterial(
  emissisonYearReport: {
    cdpVendorData?: GQCdpVendorDataFieldsFragment | null;
  },
  scope: LegacyScopeKeysType
): boolean {
  switch (scope) {
    case 'scope1':
      return true;
    case 'scope2':
      return true;
    case 'scope3':
      // TODO
      return true;
    case 'scope301':
    case 'scope302':
    case 'scope303':
    case 'scope304':
    case 'scope305':
    case 'scope306':
    case 'scope307':
    case 'scope308':
    case 'scope309':
    case 'scope310':
    case 'scope311':
    case 'scope312':
    case 'scope313':
    case 'scope314':
    case 'scope315':
    case 'scope316':
    case 'scope317':
      return isScope3SubcategoryMaterial(
        emissisonYearReport.cdpVendorData?.[`${scope}EvaluationStatus`]
      );
    default:
      assertNever(scope);
  }
}

export function getAllScopeEmissionsAndRatios(
  emissions: Partial<EmissionsInterfaceNoUnits>
): SupplierScope3HistoricalEmissions &
  Pick<
    GQSupplierHistoricalEmissions,
    | 'scope1'
    | 'scope2'
    | 'scope3'
    | 'scope1Ratio'
    | 'scope2Ratio'
    | 'scope3Ratio'
  > {
  const scope1Emissions = EmissionsYear.getEmissionsForScope(
    emissions,
    'scope1'
  );
  const scope2Emissions = EmissionsYear.getEmissionsForScope(
    emissions,
    'scope2'
  );
  const scope3Emissions = EmissionsYear.getEmissionsForScope(
    emissions,
    'scope3'
  );
  const scope3SubscopeEmissions = getScope3SubscopeEmissions(emissions);
  const calculateRatio = (kgco2e: number | null) => {
    // Ratio defaults to 0 if the input number is null
    const totalEmissions =
      (scope1Emissions ?? 0) + (scope2Emissions ?? 0) + (scope3Emissions ?? 0);
    return kgco2e && totalEmissions ? kgco2e / totalEmissions : 0;
  };

  return {
    scope1: scope1Emissions,
    scope2: scope2Emissions,
    scope3: scope3Emissions,
    ...scope3SubscopeEmissions,
    scope1Ratio: calculateRatio(scope1Emissions),
    scope2Ratio: calculateRatio(scope2Emissions),
    scope3Ratio: calculateRatio(scope3Emissions),
  };
}
