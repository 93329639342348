import gql from 'graphql-tag';
import { Trans, useLingui } from '@lingui/react/macro';
import Button, { ButtonProps } from '@watershed/ui-core/components/Button';
import ConversationIcon from '@watershed/icons/components/Conversation';
import {
  GQDiscussionAdditionalProperties,
  GQDiscussionAnchor,
} from '@watershed/shared-universal/generated/graphql';
import { useCommentDiscussionsForAnchorQuery } from '@watershed/shared-frontend/generated/urql';
import { isFetchingOrDataBang } from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { useDiscussionsDialog } from './DiscussionsDialog';
import { isComplete } from '@watershed/shared-universal/utils/discussionUtils';
import orderBy from 'lodash/orderBy';
import { getLatestTimestampForDataIssue } from '@watershed/shared-frontend/utils/dataIssueUtils';
import { OpenDiscussionCount } from './OpenDiscussionCount';
import { useRouter } from 'next/router';
import HelpIcon from '@watershed/icons/components/Help';
import PopoverMenu from '@watershed/ui-core/components/PopoverMenu';
import { ChipProps } from '@mui/material';

gql`
  fragment CommentDiscussionsForAnchorFields on DiscussionsPayload {
    id
    discussions {
      ...DiscussionFields
    }
  }
  query CommentDiscussionsForAnchor($input: DiscussionAnchor!) {
    commentDiscussionsForAnchor(input: $input) {
      ...CommentDiscussionsForAnchorFields
    }
  }
`;

/**
 * If you're using this component, you likely need to create a new anchor
 * (either a new object ID column or a new productSection enum value) and use
 * that anchor here. Reach out in #proj-generalized-support-component with questions.
 * Product documentation here: https://www.notion.so/watershedclimate/proj-generalized-support-component-summary-69e98516498e46d0936a249552f6764b?pvs=4
 */
export function SupportButton({
  anchor,
  routeForLearningHub,
  additionalProperties,
  buttonProps,
  chipProps,
}: {
  anchor: GQDiscussionAnchor;
  additionalProperties?: GQDiscussionAdditionalProperties;
  routeForLearningHub?: string;
  buttonProps?: Pick<ButtonProps, 'sx'>;
  chipProps?: Pick<ChipProps, 'sx'>;
}) {
  const { t } = useLingui();
  const router = useRouter();
  const { fetching, openDiscussionCount } = useDiscussionsQuery({ anchor });

  const openDiscussionsDialog = useDiscussionsDialog();
  const discussionsDialogProps = {
    anchor,
    additionalProperties,
  };

  return (
    <>
      {routeForLearningHub ? (
        <PopoverMenu
          trigger={
            <Button startIcon={<HelpIcon />}>
              <Trans>Help</Trans>
            </Button>
          }
          options={[
            {
              title: <Trans>Learning hub</Trans>,
              onClick: () => {
                void router.push(routeForLearningHub);
              },
            },
            {
              title: (
                <OpenDiscussionCount
                  count={openDiscussionCount}
                  label={t`Support`}
                />
              ),
              onClick: () => {
                openDiscussionsDialog(discussionsDialogProps);
              },
            },
          ]}
        />
      ) : (
        <Button
          onClick={() => openDiscussionsDialog(discussionsDialogProps)}
          disabled={fetching}
          startIcon={<ConversationIcon />}
          {...buttonProps}
        >
          <OpenDiscussionCount
            count={openDiscussionCount}
            label={t`Support`}
            chipProps={chipProps}
          />
        </Button>
      )}
    </>
  );
}

export function useDiscussionsQuery({
  anchor,
}: {
  anchor: GQDiscussionAnchor;
}) {
  const [result] = useCommentDiscussionsForAnchorQuery({
    variables: {
      input: anchor,
    },
  });
  const { fetching, data } = isFetchingOrDataBang(result);
  const discussions = data?.commentDiscussionsForAnchor.discussions ?? [];
  const openDiscussionCount = discussions.filter(
    (discussion) => !isComplete(discussion.state)
  ).length;

  const orderedDiscussions = orderBy(
    discussions,
    [
      (discussion) => {
        if (isComplete(discussion.state)) {
          return 2;
        }
        return 1;
      },
      getLatestTimestampForDataIssue,
    ],
    ['asc', 'desc']
  );
  return { fetching, openDiscussionCount, orderedDiscussions };
}
