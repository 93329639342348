import invariant from 'invariant';
import {
  getTargetScopeFromFilterGroupExpression,
  getTargetName,
  getSurveyStatusText,
} from '../companySurveys/utils';
import DateTimeUtils from '../utils/DateTimeUtils';
import {
  EMPTY_VALUE_DASH,
  getEmissionsYearNames,
  getInitiativeNames,
  getInitiativeTypeLabel,
} from '../utils/SuppliersUtils';
import { displayableAttributeValue } from '../utils/analytics';
import assertNever from '../utils/assertNever';
import { SBT_COMMITMENT_STAGE_LABELS } from '../utils/companyUtils';
import {
  formatNumber,
  formatPercentage,
  formatPercentageNonzero,
} from '../utils/helpers';
import isNotNullish from '../utils/isNotNullish';
import isNullish from '../utils/isNullish';
import { SupplierColumnName } from './SupplierColumnRegistry';
import { Supplier, SupplierTableRow } from './supplierTypes';
import { kgToTonnes } from '../fund/math';
import { getIndustryLabelFromIndustryCode } from '@watershed/shared-universal/industryCodes/industryCodeUtils';
import Company from '@watershed/shared-universal/companyData/Company';
import capitalize from 'lodash/capitalize';
import { getValueForField } from './SupplierColumnGetters';
import { getDimension } from './SupplierFilterUtils';
import { i18n, I18n } from '@watershed/intl';
import { SupportedLocale } from '@watershed/intl/constants';
import { formatList } from '@watershed/intl/formatters';
import { t } from '@lingui/core/macro';

export type ColumnValueFormatter<T extends Supplier> = (
  supplier: T,
  scopedI18n: I18n
) => string;
export type ColumnIndividualValueFormatter<T extends Supplier> = (
  supplier: T,
  scopedI18n: I18n,
  nestedDimensions?: Array<string>
) => Array<{ rawValue: any; formattedValue: string }>;

export const formatSupplierName: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  return getValueForField('supplierName')(supplier);
};

export const formatPriority: ColumnValueFormatter<Supplier> = (supplier) => {
  return getValueForField('priority')(supplier) ?? 'None';
};

export const formatEmissionsRank: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  const rank = getValueForField('emissionsRank')(supplier);
  return isNotNullish(rank)
    ? formatNumber(rank, {
        maximumFractionDigits: 0,
        locale: scopedI18n.locale as SupportedLocale,
      })
    : EMPTY_VALUE_DASH;
};

export const formatTotalEmissions: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  const totalKgco2e = getValueForField('totalEmissions')(supplier);
  return isNotNullish(totalKgco2e)
    ? formatNumber(kgToTonnes(totalKgco2e), {
        maximumFractionDigits: 2,
        locale: scopedI18n.locale as SupportedLocale,
      })
    : EMPTY_VALUE_DASH;
};

function formatMonetaryValue(
  value: number | null | undefined,
  scopedI18n: I18n
): string {
  return isNotNullish(value)
    ? formatNumber(value, {
        locale: scopedI18n.locale as SupportedLocale,
      })
    : EMPTY_VALUE_DASH;
}

export const formatSpend: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  return formatMonetaryValue(getValueForField('spend')(supplier), scopedI18n);
};

export const formatEmissiveSpend: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  return formatMonetaryValue(
    getValueForField('emissiveSpend')(supplier),
    scopedI18n
  );
};

export const formatPercentEmissions: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  return formatPercentageNonzero(
    getValueForField('percentEmissions')(supplier),
    {
      maximumFractionDigits: 2,
      includeTrailingZeros: true,
      locale: scopedI18n.locale as SupportedLocale,
    }
  );
};

export const formatTopCategorizedEmission: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  if (
    isNullish(supplier.topCategorizedEmissionBusinessCategory) ||
    isNullish(supplier.topCategorizedEmissionKgco2e) ||
    isNullish(supplier.topCategorizedEmissionProportion)
  ) {
    return EMPTY_VALUE_DASH;
  }
  return displayableAttributeValue(
    supplier.topCategorizedEmissionBusinessCategory ??
      supplier.topCategorizedEmissionBusinessSubcategory
  );
};

export const formatDisclosures: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  return formatList(getValueForField('disclosures')(supplier), {
    locale: scopedI18n.locale as SupportedLocale,
  });
};

export const formatNetZero: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  const netZeroCommitmentTargetYear = getValueForField('netZero')(supplier);

  if (isNotNullish(netZeroCommitmentTargetYear)) {
    const targetYear = netZeroCommitmentTargetYear.targetYear;
    return t(scopedI18n)`Net zero by ${targetYear}`;
  } else return t(scopedI18n)`None`;
};

export const formatCarbonNeutral: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  const carbonNeutral = getValueForField('carbonNeutral')(supplier);
  return carbonNeutral
    ? `${carbonNeutral.commitmentStatus} by ${carbonNeutral.targetYear}`
    : t(scopedI18n)`None`;
};

export const formatCleanEnergy: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  const {
    year,
    percentage: rawPercentage,
    companyId,
  } = getValueForField('cleanEnergy')(supplier);

  const percentage = rawPercentage
    ? formatPercentage(rawPercentage, {
        locale: scopedI18n.locale as SupportedLocale,
      })
    : null;

  if (year && percentage) {
    return t(scopedI18n)`${percentage} by ${year}`;
  } else if (percentage) {
    return t(scopedI18n)`Committed to ${percentage}`;
  } else if (year) {
    return t(scopedI18n)`Committed by ${year}`;
  }

  if (companyId) {
    return t(scopedI18n)`None`;
  } else {
    return t(scopedI18n)`Unknown`;
  }
};

export const formatSbtCommitmentStage: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  return SBT_COMMITMENT_STAGE_LABELS[
    getValueForField('sbtCommitmentStage')(supplier)
  ];
};

export const formatNotes: ColumnValueFormatter<Supplier> = (supplier) => {
  return getValueForField('notes')(supplier) ?? EMPTY_VALUE_DASH;
};

export const formatEngagementCohorts: ColumnValueFormatter<
  Supplier | SupplierTableRow
> = (supplier, scopedI18n) => {
  // hack to check if object is a SupplierTableRow
  // if it is not, then we're not in the suppliers table and this formatter should not be used
  if ('engagementCohortsById' in supplier) {
    const engagementCohorts = getValueForField('engagementCohorts')(
      supplier
    ).map((id) => supplier.engagementCohortsById[id]?.name ?? '');
    return formatList(engagementCohorts, {
      locale: scopedI18n.locale as SupportedLocale,
    });
  }

  // Choose to return our "empty" string value instead of error, since this is just a formatting thing.
  return EMPTY_VALUE_DASH;
};

export const formatContacts: ColumnValueFormatter<Supplier | SupplierTableRow> =
  (supplier, scopedI18n) => {
    // hack to check if object is a SupplierTableRow
    // if it is not, then we're not in the suppliers table and this formatter should not be used
    const contacts = getValueForField('contacts')(supplier);
    if ('contactIds' in supplier) {
      return formatList(
        contacts.map((_, idx) => {
          const email = supplier.contactEmails[idx];
          return `${email}`;
        }),
        {
          locale: scopedI18n.locale as SupportedLocale,
        }
      );
    }
    // Choose to return our "empty" string value instead of error, since this is just a formatting thing.
    return EMPTY_VALUE_DASH;
  };

export const formatLatestDisclosureDate: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  const latestDisclosureDate = getValueForField('latestDisclosureDate')(
    supplier
  );
  return isNotNullish(latestDisclosureDate)
    ? DateTimeUtils.formatDate(DateTimeUtils.fromJSDate(latestDisclosureDate), {
        locale: scopedI18n.locale as SupportedLocale,
      })
    : EMPTY_VALUE_DASH;
};

export const formatLatestCdpDisclosurePublishingYear: ColumnValueFormatter<
  Supplier
> = (supplier, scopedI18n) => {
  const latestCdpDisclosurePublishingYear = getValueForField(
    'latestCdpDisclosurePublishingYear'
  )(supplier);
  return isNotNullish(latestCdpDisclosurePublishingYear)
    ? latestCdpDisclosurePublishingYear.toString()
    : t(scopedI18n)`N/A`;
};

export const formatSurveyState: ColumnValueFormatter<
  Supplier | SupplierTableRow
> = (supplier) => {
  return getValueForField('surveyState')(supplier);
};

export const formatEngagementTasks: ColumnValueFormatter<
  Supplier | SupplierTableRow
> = (supplier, scopedI18n) => {
  return formatList(
    getValueForField('engagementTasks')(supplier).map(
      (task) => task.engagementTaskConfig.name
    ),
    {
      locale: scopedI18n.locale as SupportedLocale,
    }
  );
};

export const formatSurveyPortalUrl: ColumnValueFormatter<
  Supplier | SupplierTableRow
> = (supplier) => {
  invariant(
    // eslint-disable-next-line no-restricted-globals
    typeof window !== 'undefined',
    'window must be defined to format survey portal urls'
  );
  const portalUrl = getValueForField('surveyPortalUrl')(supplier);
  // Choose to return our "nullish" empty string instead of error, since this is just a formatting thing.
  return portalUrl ?? EMPTY_VALUE_DASH;
};

export const formatPercentSpend: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  const percentSpend = getValueForField('percentSpend')(supplier);
  return isNotNullish(percentSpend)
    ? formatPercentageNonzero(percentSpend, {
        maximumFractionDigits: 2,
        includeTrailingZeros: true,
        locale: scopedI18n.locale as SupportedLocale,
      })
    : EMPTY_VALUE_DASH;
};

export const formatGhgCategoryIds: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  // TODO: Figure out how ghg categories should be formatted
  // const ghgCategoryIdsNumbers = new Set(
  //   getValueForField('ghgCategoryIds')(supplier)
  //     .map((category) => category.split(' ').shift())
  //     .filter(isNotNullish)
  // );
  return formatList(
    Array.from(getValueForField('ghgCategoryIds')(supplier)).sort(),
    {
      locale: scopedI18n.locale as SupportedLocale,
    }
  );
};

export const formatTargets: ColumnValueFormatter<Supplier> = (supplier) => {
  return getValueForField('targets')(supplier) ?? EMPTY_VALUE_DASH;
};

export const formatEmissionsFactors: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  return getValueForField('emissionsFactors')(supplier) ? 'True' : 'False';
};

export const formatBeaCodes: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  // TODO: call formatIndividualBeaCodes instead?
  return formatList(
    getValueForField('beaCodes')(supplier).map((beaCode) =>
      getIndustryLabelFromIndustryCode(beaCode)
    ),
    {
      locale: scopedI18n.locale as SupportedLocale,
    }
  );
};

export const formatTargetsAndCommitments: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  const climateCommitmentNames = supplier.climateCommitmentDisclosures
    .flatMap((disclosure) => {
      return disclosure.climateCommitments?.map((commitment) =>
        Company.getCommitmentTitle(commitment)
      );
    })
    .filter(isNotNullish);
  const targetNames = supplier.targetDisclosures
    .flatMap((disclosure) =>
      disclosure.targets?.map((target) => {
        const scopeType = getTargetScopeFromFilterGroupExpression(
          target.filters
        );
        return getTargetName(target.name, target.baseYear, scopeType);
      })
    )
    .filter(isNotNullish);

  return formatList(targetNames.concat(climateCommitmentNames), {
    locale: scopedI18n.locale as SupportedLocale,
  });
};

export const formatInitiatives: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  return formatList(
    getInitiativeNames(getValueForField('initiatives')(supplier)),
    {
      locale: scopedI18n.locale as SupportedLocale,
    }
  );
};

export const formatFootprints: ColumnValueFormatter<Supplier> = (
  supplier,
  scopedI18n
) => {
  return formatList(getEmissionsYearNames(supplier), {
    locale: scopedI18n.locale as SupportedLocale,
  });
};

export const formatIndividualBeaCodes: ColumnIndividualValueFormatter<
  Supplier
> = (supplier) => {
  return getValueForField('beaCodes')(supplier).map((beaCode) => ({
    rawValue: beaCode,
    formattedValue: getIndustryLabelFromIndustryCode(beaCode),
  }));
};

export const formatIndividualEngagementCohorts: ColumnIndividualValueFormatter<
  Supplier | SupplierTableRow
> = (supplier) => {
  return getValueForField('engagementCohorts')(supplier).map((id) => {
    if ('engagementCohortsById' in supplier) {
      return {
        rawValue: id,
        formattedValue: supplier.engagementCohortsById[id]?.name ?? '',
      };
    } else {
      return { rawValue: id, formattedValue: EMPTY_VALUE_DASH };
    }
  });
};

export const formatIndividualDisclosures: ColumnIndividualValueFormatter<
  Supplier
> = (supplier) => {
  return getValueForField('disclosures')(supplier).map((climateProgress) => ({
    rawValue: climateProgress,
    formattedValue: capitalize(climateProgress),
  }));
};

export const formatIndividualTopCategorizedEmission: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, scopedI18n) => {
  if (
    isNullish(supplier.topCategorizedEmissionBusinessCategory) ||
    isNullish(supplier.topCategorizedEmissionKgco2e) ||
    isNullish(supplier.topCategorizedEmissionProportion)
  ) {
    return [];
  } else {
    return [
      {
        rawValue:
          supplier.topCategorizedEmissionBusinessCategory ??
          supplier.topCategorizedEmissionBusinessSubcategory,
        formattedValue: formatTopCategorizedEmission(supplier, scopedI18n),
      },
    ];
  }
};

export const formatIndividualFootprints: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, _scopedI18n, nestedDimensions = []) => {
  return getValueForField('footprints')(supplier).map((footprint) => ({
    rawValue: getDimension(footprint, nestedDimensions),
    formattedValue: getDimension(
      {
        ...footprint,
        expenseCategory: footprint.expenseCategory
          ? getIndustryLabelFromIndustryCode(footprint.expenseCategory)
          : 'None',
      },
      nestedDimensions
    ),
  }));
};

export const formatIndividualInitiatives: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, _scopedI18n, nestedDimensions = []) => {
  return getValueForField('initiatives')(supplier).map((initiative) => ({
    rawValue: getDimension(initiative, nestedDimensions),
    formattedValue: getDimension(
      {
        ...initiative,
        initiativeType: getInitiativeTypeLabel(initiative.initiativeType),
        scopes: initiative.scopes.map((s) => capitalize(s)),
      },
      nestedDimensions
    ),
  }));
};

export const formatIndividualEngagementTasks: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, _scopedI18n, nestedDimensions = []) => {
  return getValueForField('engagementTasks')(supplier).map((task) => ({
    rawValue: getDimension(task, nestedDimensions),
    formattedValue: getDimension(
      {
        ...task,
        status: getSurveyStatusText(task, 'rootCustomerReviewSubmission'),
      },
      nestedDimensions
    ),
  }));
};

export const formatIndividualTargetsAndCommitments: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, _scopedI18n, nestedDimensions = []) => {
  return getValueForField('targetsAndCommitments')(supplier).map(
    (targetOrCommitment) => {
      return {
        rawValue: getDimension(targetOrCommitment, nestedDimensions),
        formattedValue: getDimension(
          {
            ...targetOrCommitment,
            intensityType: targetOrCommitment.intensityType ?? 'None',
            scopes: targetOrCommitment.scopes.map((s) => capitalize(s)),
          },
          nestedDimensions
        ),
      };
    }
  );
};

export function getFormatterForField(field: SupplierColumnName) {
  return (
    supplier: Supplier | SupplierTableRow,
    scopedI18n: I18n = i18n
  ): string => {
    switch (field) {
      case 'supplierName':
        return formatSupplierName(supplier, scopedI18n);
      case 'priority':
        return formatPriority(supplier, scopedI18n);
      case 'emissionsRank':
        return formatEmissionsRank(supplier, scopedI18n);
      case 'totalEmissions':
        return formatTotalEmissions(supplier, scopedI18n);
      case 'spend':
        return formatSpend(supplier, scopedI18n);
      case 'percentEmissions':
        return formatPercentEmissions(supplier, scopedI18n);
      case 'topCategorizedEmission':
        return formatTopCategorizedEmission(supplier, scopedI18n);
      case 'disclosures':
        return formatDisclosures(supplier, scopedI18n);
      case 'netZero':
        return formatNetZero(supplier, scopedI18n);
      case 'carbonNeutral':
        return formatCarbonNeutral(supplier, scopedI18n);
      case 'cleanEnergy':
        return formatCleanEnergy(supplier, scopedI18n);
      case 'sbtCommitmentStage':
        return formatSbtCommitmentStage(supplier, scopedI18n);
      case 'notes':
        return formatNotes(supplier, scopedI18n);
      case 'engagementCohorts':
        return formatEngagementCohorts(supplier, scopedI18n);
      case 'contacts':
        return formatContacts(supplier, scopedI18n);
      case 'latestDisclosureDate':
        return formatLatestDisclosureDate(supplier, scopedI18n);
      case 'latestCdpDisclosurePublishingYear':
        return formatLatestCdpDisclosurePublishingYear(supplier, scopedI18n);
      case 'surveyState':
        return formatSurveyState(supplier, scopedI18n);
      case 'engagementTasks':
        return formatEngagementTasks(supplier, scopedI18n);
      case 'surveyPortalUrl':
        return formatSurveyPortalUrl(supplier, scopedI18n);
      case 'ghgCategoryIds':
        return formatGhgCategoryIds(supplier, scopedI18n);
      case 'targets':
        return formatTargets(supplier, scopedI18n);
      case 'emissionsFactors':
        return formatEmissionsFactors(supplier, scopedI18n);
      case 'percentSpend':
        return formatPercentSpend(supplier, scopedI18n);
      case 'supplierDetailsUrl':
        return '';
      case 'beaCodes':
        return formatBeaCodes(supplier, scopedI18n);
      case 'emissiveSpend':
        return formatEmissiveSpend(supplier, scopedI18n);
      case 'targetsAndCommitments':
        return formatTargetsAndCommitments(supplier, scopedI18n);
      case 'initiatives':
        return formatInitiatives(supplier, scopedI18n);
      case 'footprints':
        return formatFootprints(supplier, scopedI18n);
      default:
        assertNever(field);
    }
  };
}

export interface SupplierFilterOption {
  rawValue: any;
  formattedValue: string;
}

// Note - this is currently just used to map from the raw value to formatted
// value for our new filters and our old "array contains" or
// "commitmentStatusAndTargetYear" filters. Therefore, we are only implementing
// this for columns that are used in those filters.
export function getFilterOptionsGetterForDefaultFields(
  field: SupplierColumnName,
  nestedDimensions?: Array<string>
) {
  return (
    supplier: Supplier | SupplierTableRow,
    scopedI18n: I18n
  ): Array<SupplierFilterOption> => {
    switch (field) {
      case 'priority':
        return [
          {
            rawValue: getValueForField('priority')(supplier),
            formattedValue: formatPriority(supplier, scopedI18n),
          },
        ];
      case 'topCategorizedEmission':
        return formatIndividualTopCategorizedEmission(supplier, scopedI18n);
      case 'sbtCommitmentStage':
        return [
          {
            rawValue: supplier.sbtiStage,
            formattedValue: formatSbtCommitmentStage(supplier, scopedI18n),
          },
        ];
      case 'latestCdpDisclosurePublishingYear':
        return [
          {
            rawValue: getValueForField('latestCdpDisclosurePublishingYear')(
              supplier
            ),
            formattedValue: formatLatestCdpDisclosurePublishingYear(
              supplier,
              scopedI18n
            ),
          },
        ];
      case 'emissionsFactors':
        return [
          {
            rawValue: getValueForField('emissionsFactors')(supplier),
            formattedValue: formatEmissionsFactors(supplier, scopedI18n),
          },
        ];
      case 'beaCodes':
        return formatIndividualBeaCodes(supplier, scopedI18n);
      case 'engagementCohorts':
        return formatIndividualEngagementCohorts(supplier, scopedI18n);
      case 'disclosures':
        return formatIndividualDisclosures(supplier, scopedI18n);
      case 'footprints':
        return formatIndividualFootprints(
          supplier,
          scopedI18n,
          nestedDimensions
        );
      case 'initiatives':
        return formatIndividualInitiatives(
          supplier,
          scopedI18n,
          nestedDimensions
        );
      case 'engagementTasks':
        return formatIndividualEngagementTasks(
          supplier,
          scopedI18n,
          nestedDimensions
        );
      case 'targetsAndCommitments':
        return formatIndividualTargetsAndCommitments(
          supplier,
          scopedI18n,
          nestedDimensions
        );
      case 'supplierName':
      case 'emissionsRank':
      case 'totalEmissions':
      case 'spend':
      case 'percentEmissions':
      case 'netZero':
      case 'carbonNeutral':
      case 'cleanEnergy':
      case 'notes':
      case 'contacts':
      case 'latestDisclosureDate':
      case 'surveyState':
      case 'surveyPortalUrl':
      case 'ghgCategoryIds':
      case 'targets':
      case 'percentSpend':
      case 'supplierDetailsUrl':
      case 'emissiveSpend':
        return [];
      default:
        assertNever(field);
    }
  };
}
