import { Box, Stack, Typography } from '@mui/material';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import { useCreateDiscussionMutation } from '@watershed/shared-frontend/generated/urql';
import { getGqlResultDataBang } from '@watershed/shared-frontend/utils/errorUtils';
import {
  GQCreateDiscussionInput,
  GQDiscussionAdditionalProperties,
  GQDiscussionAnchor,
} from '@watershed/shared-universal/generated/graphql';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import TextField, {
  TextFieldMultiline,
} from '@watershed/ui-core/components/Form/TextField';
import { FormikProvider, useFormik } from 'formik';
import gql from 'graphql-tag';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import { DiscussionFields } from '@watershed/shared-universal/utils/discussionUtils';
import ProgressButton from '@watershed/ui-core/components/ProgressButton';
import { SUPPORT_RESPONSE_SLA_COPY } from './DiscussionsDialog';
import { useLingui } from '@lingui/react/macro';

gql`
  mutation CreateDiscussion($input: CreateDiscussionInput!) {
    createDiscussion(input: $input) {
      discussion {
        ...DiscussionFields
      }
    }
  }
`;

/**
 * An interopable form for creating a discussion.
 */
export default function CreateDiscussionForm({
  anchor,
  additionalProperties,
  onSubmitComplete,
}: {
  anchor: GQDiscussionAnchor;
  additionalProperties?: GQDiscussionAdditionalProperties;
  onSubmitComplete?: (discussion: DiscussionFields) => void;
}) {
  const { t } = useLingui();
  const snackbar = useSnackbar();
  const [, executeCreateDiscussion] = useCreateDiscussionMutation();

  const form = useFormik<GQCreateDiscussionInput>({
    initialValues: {
      anchor,
      fileMetadataIds: [],
      memberIds: [],
      title: '',
      message: '',
    },
    onSubmit: async (values) => {
      {
        Analytics.action('createDiscussion', values);

        const result = await executeCreateDiscussion({
          input: {
            anchor,
            additionalProperties,
            title: values.title,
            message: values.message,
            shouldNotifyWatershed: true,
          },
        });
        const discussion =
          getGqlResultDataBang(result).createDiscussion?.discussion;

        if (result.error || !discussion) {
          Analytics.error('createDiscussion', {
            error: result.error,
          });
          snackbar.enqueueSnackbar(
            'An error occurred, please try again later',
            {
              variant: 'error',
            }
          );
        } else {
          Analytics.modal('discussionDialog', {
            source: 'createDiscussion',
            issueId: discussion.id,
          });

          onSubmitComplete?.(discussion);
        }
      }
    },
  });

  return (
    <FormikProvider value={form}>
      <Stack sx={{ flex: 1, minHeight: 0 }} justifyContent="space-between">
        <Stack minHeight={0} gap={2}>
          <TextField
            id="title"
            name="title"
            dataTest={TestIds.NewDataIssueTitleField}
            placeholder={t({
              message: 'Add subject',
              context: 'Add subject when starting a discussion thread',
            })}
          />
          <Box>
            <TextFieldMultiline
              id="message"
              rows={4}
              dataTest={TestIds.NewDataIssueMessageField}
              placeholder={t({ message: 'Write your message here' })}
            />
            <Typography variant="caption">
              {SUPPORT_RESPONSE_SLA_COPY}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <ProgressButton
            isInProgress={form.isSubmitting}
            label={t({ message: 'Send', context: 'Send message' })}
            progressLabel="Submitting&hellip;"
            type="submit"
            disabled={
              // form.isValid ||
              form.isSubmitting || form.values.message === ''
            }
            onClick={form.submitForm}
          />
        </Stack>
      </Stack>
    </FormikProvider>
  );
}
